import { useEffect, useState } from "react";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import Swal from "sweetalert2";
import { FetchData } from "../../../../hooks/FetchData";

const ShowModalinvoice = ({ invoice_data = {} }) => {
  const [isShowModale, setIsShowModale] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [sendPost, setSendPost] = useState(false);

  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };
  const [selected, setSelected] = useState([]);
  const [infosInvoice, setinfosInvoice] = useState([]);
  useEffect(() => {
    setinfosInvoice(invoice_data);
  }, [invoice_data]);

  const sendFacture = async () => {
    setSendPost(true);
    try {
      let url = "";
      let option = {};
      let success_message = "";
      url = "/api/client/invoice";
      option = {
        method: "POST",
        body: JSON.stringify({
          order_id: selected,
          type: ["btnradio1", "btnradio2", "btnradio3"].indexOf(selectedValue),
        }),
      };
      success_message = "Facturation effectuée avec succès!";
      const { loading, data, errors } = await FetchData(url, option);
      setSendPost(false);
      if (data.status === 200) {
        setinfosInvoice(data?.invoice);
        if (data?.invoice?.options?.length === 0) {
          setIsShowModale((e) => !e);
        }
        Swal.fire({
          title: "Succès",
          text: success_message,
          icon: "success",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        Swal.fire({
          title: "Erreur",
          text: data.error,
          icon: "error",
          confirmButtonColor: "#0e3feb",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {}
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary input-label-size ms-2"
        onClick={() => {
          setIsShowModale((e) => !e);
        }}
        disabled={infosInvoice?.count_order_invoice === 0}
      >
        <i className="fa fa-briefcase me-2"></i>
        Facturation
        {infosInvoice?.count_order_invoice != 0 && (
          <span className="badge text-bg-secondary ms-2">
            {infosInvoice?.count_order_invoice}
          </span>
        )}
      </button>

      <div
        className={
          "modal fade pt-5" + (isShowModale && " show bsl-show-modal ")
        }
        tabIndex="-1"
        aria-hidden={isShowModale ? null : "true"}
        aria-modal={isShowModale ? "true" : null}
        role={isShowModale ? "dialog" : null}
        style={
          isShowModale === true
            ? { display: "block", background: "#6b6b6b96" }
            : { display: "none" }
        }
        id="invoiceModalClient"
        aria-labelledby="invoiceModalClientLabel"
      >
        <div className="modal-dialog text-body-secondary ">
          <div className="modal-content facturation-modal">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="invoiceModalClientLabel">
                Facturation <b>{infosInvoice?.client?.client_company_name}</b>{" "}
                <i>
                  ({infosInvoice?.count_order_invoice} commandes disponible)
                </i>
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setIsShowModale((e) => !e);
                }}
              ></button>
            </div>
            <div className="modal-body text-center">
              <div
                className="btn-group"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <label className="btn border border-0 disabled text-black">
                  Type de facturation <span className="text-danger">*</span>
                </label>
                <input
                  type="radio"
                  className="btn-check"
                  value="btnradio1"
                  checked={selectedValue === "btnradio1"}
                  onChange={() => handleRadioChange("btnradio1")}
                  name="btnradio"
                  id="btnradio1"
                  autoComplete="off"
                />
                <label className="btn btn-outline-primary" htmlFor="btnradio1">
                  A la commande
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  value="btnradio2"
                  checked={selectedValue === "btnradio2"}
                  onChange={() => handleRadioChange("btnradio2")}
                  name="btnradio"
                  id="btnradio2"
                  autoComplete="off"
                />
                <label className="btn btn-outline-primary" htmlFor="btnradio2">
                  Au mois
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  value="btnradio3"
                  checked={selectedValue === "btnradio3"}
                  onChange={() => handleRadioChange("btnradio3")}
                  name="btnradio"
                  id="btnradio3"
                  autoComplete="off"
                />
                <label className="btn btn-outline-primary" htmlFor="btnradio3">
                  A la semaine
                </label>
              </div>
              {infosInvoice?.options?.length > 0 && (
                <div className="mt-2">
                  <p className="mb-0 text-left">
                    Branche <span className="text-danger fs-5">*</span>
                  </p>
                  <DualListBox
                    options={infosInvoice?.options}
                    selected={selected}
                    onChange={(newValue) => setSelected(newValue)}
                  />
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setIsShowModale((e) => !e);
                }}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={sendFacture}
                disabled={selected.length === 0 || !selectedValue || sendPost}
              >
                <i className="fa fa-briefcase me-2"></i>Envoyer en facturation
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ShowModalinvoice;
