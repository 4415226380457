import React from "react";
import { useToggle } from "react-use";

function FilterRound({ is_active = false, text, number, onClick }) {
  const click = () => {
    onClick();
  };
  return (
    <>
      <div
        className={
          "d-flex cursor-pointer fr-tabs-item" + (is_active ? "" : "-2")
        }
        onClick={click}
      >
        <div className="fr-label-wrapper">
          <div className={"fr-label-" + (is_active ? "5" : "6")}>{text}</div>
        </div>
        {typeof number === "number" && (
          <div className={is_active ? "fr-notif-number" : "fr-element-wrapper"}>
            <div className={"fr-element" + (is_active ? "" : "-2")}>
              {number}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default FilterRound;
