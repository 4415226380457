import React, { useCallback, useEffect, useState } from "react";
import { useToggle } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { initDropdown, updateDropdown } from "../../store/searchDropdownSlice";

function SearchDropdown({
  data_dropdown_list = [],
  ref_id = "id",
  ref_value = "label",
  ref_name_page = "cle",
  ref_identifier = "ref_identifier_dropdown",
}) {
  const dispatch = useDispatch();

  const [selectedAll, setSelectedAll] = useToggle(false);

  const [listOfSelected, setListOfSelected] = useState([]);

  const actual_key = useSelector((state) => state.filterTable.actual_key);

  const [data_dropdown_list_, setData_dropdown_list_] =
    useState(data_dropdown_list);

  const [temp_dropdown_list_, setTemp_dropdown_list_] =
    useState(data_dropdown_list);

  const [value, setValue] = useState("");
  const [list_client, setList_client] = useState([]);

  const ref_identifiers = useSelector((state) => state.dropdown.ref_identifier);

  ref_identifier = ref_identifiers.includes(ref_identifier)
    ? ref_identifier
    : ref_identifiers[0];

  const search_value = useSelector((state) => state.dropdown.data_label);
  const data_label = search_value.filter((d) => d.name === ref_identifier)[0]
    ?.data;

  // Construct data of dropdown
  useEffect(() => {
    // if (ref_name_page !== "AdminPage") {
    setData_dropdown_list_(data_dropdown_list);
    setTemp_dropdown_list_(data_dropdown_list);
    // }
  }, [data_dropdown_list]);

  // For MAJ data list of item dropdown
  useEffect(() => {
    setList_client(data_dropdown_list_);
    setSelectedAll(false);
    setListOfSelected([]);
  }, [data_dropdown_list_]);

  const handleChange = useCallback(
    (e) => {
      const value_search = e.target.value;
      setValue(value_search);
      if (value_search.length > 0) {
        const temp_client = temp_dropdown_list_
          .filter((v) => {
            return v[ref_value].toLowerCase().indexOf(value_search) != -1;
          })
          .map((d, i) => ({
            ...d,
            selected:
              listOfSelected.indexOf("All") != -1
                ? true
                : listOfSelected.indexOf(d[ref_id]) != -1,
          }));
        setList_client(temp_client);
      } else {
        setList_client((v) =>
          temp_dropdown_list_.map((d, i) => ({
            ...d,
            selected:
              listOfSelected.indexOf("All") != -1
                ? true
                : listOfSelected.indexOf(temp_dropdown_list_[i][ref_id]) != -1,
          }))
        );
      }
    },
    [data_dropdown_list_, listOfSelected]
  );

  const selectAll = () => {
    setSelectedAll();
    setList_client((v) =>
      v.map((d, index) => ({
        ...d,
        selected: !selectedAll,
      }))
    );
    setTemp_dropdown_list_((v) =>
      v.map((d, index) => ({
        ...d,
        selected: !selectedAll,
      }))
    );
  };

  useEffect(() => {
    const old_label = search_value.filter((d) => d.name === ref_identifier)[0]
      ?.data;
    const numberOfSelected = listOfSelected.length;
    const new_label = createLabel(ref_identifier, numberOfSelected, old_label);

    dispatch(
      updateDropdown({
        value_search: [
          {
            name: ref_identifier,
            data: listOfSelected?.length ? listOfSelected : [],
          },
        ],
        data_label: [
          {
            name: ref_identifier,
            data: new_label,
          },
        ],
        ref_identifier: [ref_identifier],
      })
    );
  }, [listOfSelected]);

  const createLabel = (
    ref_identifier = "commande_client_dropdown",
    numberOfSelected = 0,
    old_label = "Tous les clients"
  ) => {
    let new_label = "";
    switch (ref_identifier) {
      case "commande_client_dropdown":
        new_label = {
          title:
            numberOfSelected > 0 && list_client.length !== numberOfSelected
              ? `Clients sélectionnés(${numberOfSelected})`
              : actual_key === "client"
              ? `Tous les clients`
              : `Tous les sous-traitants`,
          select_all:
            actual_key === "client"
              ? `Tous les clients`
              : `Tous les sous-traitants`,
        };
        break;
      case "branche":
        const branch_selected = list_client.filter(
          (data) => data.selected === true
        );
        let selection_label = branch_selected
          .map((branch) => branch?.label)
          .join(",");
        if (branch_selected.length > 2) {
          const indexOfThirdSelection = selection_label.indexOf(
            branch_selected[2]?.label
          );
          selection_label = selection_label
            .substring(0, indexOfThirdSelection - 1)
            .concat("...");
        }

        new_label = {
          title:
            numberOfSelected > 0 && list_client.length !== numberOfSelected
              ? `${selection_label}`
              : `Toutes les branches`,
          select_all: "Toutes les branches",
        };
        break;
      default:
        new_label = old_label;
        break;
    }
    return new_label;
  };
  const clickMenuItem = (index) => {
    setList_client((v) =>
      v.map((d, i) => {
        return {
          ...d,
          selected:
            list_client[index][ref_id] == d[ref_id] ? !d.selected : d.selected,
        };
      })
    );
    setTemp_dropdown_list_((v) =>
      v.map((d, i) => {
        return {
          ...d,
          selected:
            temp_dropdown_list_[i][ref_id] == list_client[index][ref_id]
              ? !d.selected
              : d.selected,
        };
      })
    );
  };

  useEffect(() => {
    let data = temp_dropdown_list_
      .filter((d) => d.selected)
      .map((d) => d[ref_id]);

    setListOfSelected(data);
  }, [list_client]);

  return (
    <>
      <div className="dropdown bsl-search-drop">
        <button
          type="button"
          className="alert alert-secondary dropdown-toggle p-1 mb-0"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-bs-auto-close="outside"
        >
          {data_label?.title?.indexOf("client") != -1 ? (
            <img src="img/icons8-skyscrapers-24.png" className="me-2" />
          ) : (
            <i
              className={
                " me-2 " +
                (data_label?.icon && data_label?.icon.length > 0
                  ? data_label?.icon
                  : " fa-solid fa-users")
              }
            ></i>
          )}
          {data_label?.title}
        </button>
        <ul className="dropdown-menu px-2 bsl-dropdown-serach-content">
          <li>
            <div className="input-group mb-2 ps-2 w-100">
              <span className="input-group-text" id="basic-addon1">
                <i className="fas fa-search"></i>
              </span>
              <input
                type="text"
                className="form-control normal-font-size me-2 bsl-dropdown-serach-input"
                placeholder={"Recherche"}
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={value}
                onChange={handleChange}
              />
            </div>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          {data_label?.select_all &&
            list_client.length === data_dropdown_list_.length && (
              <AllSelect
                click={selectAll}
                selected={selectedAll}
                select_all={data_label?.select_all}
              />
            )}
          <ul className="item-bsl-dropdown ps-0">
            {list_client.map((data, index) => (
              <li
                key={index}
                className={data.selected === true ? "active" : ""}
                onClick={() => {
                  clickMenuItem(index);
                }}
              >
                <a className="cursor-pointer">{data[ref_value]}</a>
              </li>
            ))}
          </ul>
        </ul>
      </div>
    </>
  );
}

export default SearchDropdown;

function AllSelect({ click, selected, select_all }) {
  return (
    <>
      <li
        className={"item-bsl-dropdown-all " + (selected ? "active" : "")}
        onClick={click}
      >
        <a className=" cursor-pointer">{select_all}</a>
      </li>
      <li>
        <hr className="dropdown-divider" />
      </li>
    </>
  );
}
