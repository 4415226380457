import React from "react";
import { ItmeLog, ItemStResponse } from "../demande/ShowLogDemande";
const CommandeHistory = ({ data_log = [] }) => {
  return (
    <>
      <div
        id="vertical-timeline"
        className="vertical-container dark-timeline center-orientation p-2"
      >
        {data_log.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <ShowLogItem key={index} item={item} />
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

const ShowLogItem = ({ item = [] }) => {
  const getDataJson = (data, ref) => {
    let list = [];
    data[ref].forEach((element) => {
      let d = element.replace('{"', "[");
      d = d.substring(0, d.length - 2);
      d += "]";
      d = d.replaceAll('\\"', '"').split('","').join(",");
      if (d !== "[{}]") {
        d = `${d}`;
        let res = JSON.parse(d);
        list = [...list, ...res];
      }
    });
    return list;
  };
  let order_st_cost = getDataJson(item, "order_st_cost");
  let st_company_name = getDataJson(item, "st_company_name");
  let order_st_ref = getDataJson(item, "order_st_ref");
  let ref_map = [
    order_st_cost.length,
    st_company_name.length,
    order_st_ref.length,
  ].sort()[2];
  ref_map = "t".repeat(ref_map).split("");

  const verifSt = () => {
    return (
      [...order_st_cost, ...st_company_name, ...order_st_ref].filter(
        (d) => d.is_diff
      ).length > 0
    );
  };
  const title = {
    Crée: "Création",
    Expédié: "Expédition",
    "En transit": "En transit",
    Dédouanement: "Dédouanement",
    "En cours de livraison last mile": "En cours de livraison",
    Livré: "Livraison",
    "Envoyé en facturation": "Facturation",
    "Sous traitant crée": "Création des sous traitants",
    Modifié: "Modification",
  };

  const ref = [
    "order_num_title",
    "client_name",
    "branch_name",
    "contact_name",
    "order_validation_date",
    "order_origin_company",
    "order_origin_country_name",
    "order_origin_zip_code",
    "order_origin_city",
    "order_origin_adress",
    "order_dest_company",
    "order_dest_countryname",
    "order_dest_zip_code",
    "order_dest_city",
    "order_dest_adress",
    "order_goods_nb_colis",
    "order_goods_weight",
    "order_goods_num_track_expedition",
    "order_delivery_date",
    "order_delivery_time",
    "order_delivery_signer_name",
    "order_delivery_packaging",
    "order_delivery_D_and_T",
    "order_delivery_customs",
    "order_misc_fees_name",
    "order_misc_fees_amount",
    "order_misc_fees_comment",
    "order_purchase_price",
    "order_selling_price",
    "order_tva",
    "order_statut_name",
    "order_is_direct",
    "order_client_ref",
    "order_goods_description",
    // "st_company_name",
    "order_st_cost",
    // "order_st_ref",
  ];

  return (
    <>
      <div className="vertical-timeline-block">
        <div className={"vertical-timeline-icon " + item.action_name.bg}>
          <i className={`fa ${item.action_name.icon} text-white`}></i>
        </div>

        <div className="vertical-timeline-content">
          <h2 className="fs-5 text-center mb-2 text-light-emphasis">
            <b>{title[item.action_name.value]}</b>
          </h2>
          <table className="table table-bordered log-table">
            <tbody>
              {ref.map((d, i) => (
                <React.Fragment key={i}>
                  {item[d].is_diff ? (
                    <>
                      <ItmeLog d={d} item={item} />
                    </>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))}
              {verifSt() && (
                <>
                  {ref_map.map((c, ic) => (
                    <React.Fragment key={ic}>
                      <tr>
                        <td
                          colSpan={2}
                          className="text-center text-light-emphasis"
                        >
                          <b>Sous traitant </b>
                        </td>
                      </tr>
                      {st_company_name.length > ic && (
                        <ItemStResponse item={st_company_name[ic]} />
                      )}
                      {order_st_cost.length > ic && (
                        <ItemStResponse item={order_st_cost[ic]} />
                      )}
                      {order_st_ref.length > ic && (
                        <ItemStResponse item={order_st_ref[ic]} />
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <span className="vertical-date">
            {item.log_date} <br />
            <small>
              {item.action_name.value} par <b>{item.user_update}</b>
            </small>
          </span>
        </div>
      </div>
    </>
  );
};

export default CommandeHistory;
