import React, { useCallback, useEffect, useState } from "react";
import Admin from "../root/Admin";
import Table from "../Tables/Table";
import TableTitle from "../Tables/TableTitle";
import AddSousTraitant from "../Modals/Insert/AddSousTraitant.js";
import { FetchData } from "../../hooks/FetchData.js";
import { useDispatch, useSelector } from "react-redux";
import { initUpdate } from "../../store/table/btnIconSlice";
import Swal from "sweetalert2";
import { RedirectUnallowed } from "../../hooks/RedirectUnallowed.js";
import { useAccess } from "../../hooks/useAccess.js";

const StPage = () => {
  //Permissions
  const can_access_sous_traitants_page = useAccess(13);
  const can_search_subcontractors = useAccess(14);
  const can_see_all_subcontractors = useAccess(15);
  const can_see_unallowed_subcontractors = useAccess(16);
  const can_see_allowed_subcontractors = useAccess(17);
  const can_create_new_subcontractors = useAccess(18);
  const can_exports_subcontractors = useAccess(19);
  const can_change_selected_subcontractors_status = useAccess(20);
  const can_see_subcontractor_infos = useAccess(22);

  //all sous traitant inside table
  const [sous_traitants, setSous_traitants] = useState([]);

  //all countries in country selection
  const [country_list, setCountryList] = useState([]);

  //one sous traitant inside table
  const [sous_traitant, setSous_traitant] = useState(null);

  //all status possible of sous traitant
  const [st_status, setSt_status] = useState([]);

  //all table runtime data
  const data_store = useSelector((state) => state.table.data);

  // headers, contents, key;
  const table_key = [
    "check",
    "st_company_name",
    "numero",
    "statut",
    "st_email",
    "phone",
    "date",
  ];
  const table_headers = {
    check: { value: "checkbox", width: 20 },
    st_company_name: { value: "Sous traitant", width: 150 },
    statut: { value: "Statut", width: 90 },
    numero: { value: "N°", width: 80 },
    st_email: { value: "Email", width: 150 },
    phone: { value: "Téléphone", width: 100 },
    date: { value: "Date d'ajout", width: 100 },
  };

  const search_key = [
    "st_company_name",
    "numero",
    "statut",
    "st_email",
    "phone",
    "date",
  ];

  //For table's filter bar
  const all_subcontractors_data = can_see_all_subcontractors
    ? [
        {
          label: "Tous",
          key: "All",
          selected: true,
          number: 0,
        },
      ]
    : [];

  const unallowed_subcontractors_data = can_see_unallowed_subcontractors
    ? [
        {
          label: "Suspendu",
          key: "Suspendu",
          selected: false,
          number: 0,
        },
      ]
    : [];

  const allowed_subcontractors_data = can_see_allowed_subcontractors
    ? [
        {
          label: "Autorisé",
          key: "Autorisé",
          selected: false,
          number: 0,
        },
      ]
    : [];
  const filter = [
    ...all_subcontractors_data,
    ...unallowed_subcontractors_data,
    ...allowed_subcontractors_data,
  ].map((d, i) => ({ ...d, selected: i === 0 }));

  const active_index_filter = 0;

  //for table's buttons and search bar
  const st_data_btn_control = {
    show_export_btn: can_exports_subcontractors,
    show_delete_btn: false,
    show_change_status_btn: can_change_selected_subcontractors_status,
  };

  const data_ref = "st_id";

  // check if the modal is showed or not
  const [isShowModal, setIsShowModal] = useState(false);

  // check why the modal is showed(0:not show, 1: create, 2: update)
  const [whyShown, setWhyShown] = useState(0);

  //used for redux requests
  const dispatch = useDispatch();

  const data_action_td = useSelector((state) => state.btnIconSlice.data);

  // handle if the form modal is shown or not

  const closeModal = useCallback(() => {
    setIsShowModal(false);
    setWhyShown(0);
  }, []);

  const showModal = useCallback((reason = 1) => {
    setIsShowModal(true);
    setWhyShown(reason);
  }, []);

  //get all sous traitants from database
  const getAllSousTraitants = async () => {
    try {
      const { loading, data, error } = await FetchData("/api/sous-traitant", {
        method: "GET",
      });
      if (data.status === 200) {
        setSous_traitants(data.data);
        setSt_status(data.status_sous_traitant);
        setCountryList(data.country);
      } else {
      }
    } catch (error) {}
  };

  //get one sous traitant for uptade
  const getSous_traitant = async (st_id) => {
    const { loading, data, errors } = await FetchData(
      `/api/sous-traitant/${st_id}`,
      {
        method: "GET",
      }
    );
    setSous_traitant(data.data);
    return { loading, data, errors };
  };

  // handle delete selected sous traitant in the table
  const handleDeletes = () => {
    const stIds = data_store
      .filter((sous_traitant) => sous_traitant.check === true)
      .map((sous_traitant) => sous_traitant.st_id);
    const pluriel = stIds.length == 1 ? "" : "s";
    Swal.fire({
      title: "Confirmation?",
      text: `Êtes-vous certain de vouloir supprimer ce${pluriel} sous traitant${pluriel}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, supprimer!",
      confirmButtonColor: "#0e3feb",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed && stIds) {
        const { data } = await FetchData(`/api/sous-traitant/${stIds}`, {
          method: "DELETE",
        });
        let message =
          stIds.length == 1
            ? "Le sous traitant a été supprimé avec succès."
            : "Les sous traitants ont été supprimés avec succès.";
        if (data.status != 200) {
          message =
            stIds.length == 1 ? "du sous traitant" : "des sous traitants";
          Swal.fire({
            title: "Erreur",
            text: `Une erreur s'est produite lors de la suppression ${message}.\n${data.error}`,
            icon: "error",
            confirmButtonColor: "#0e3feb",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Supprimé!",
            text: message,
            icon: "success",
            position: "center",
            showConfirmButton: false,
            timer: 3000,
          });
          getAllSousTraitants();
        }
      }
    });
  };

  const handleChangeStatus = () => {
    const stIds = data_store
      .filter((sous_traitant) => sous_traitant.check === true)
      .map((sous_traitant) => sous_traitant.st_id);
    const pluriel = stIds.length == 1 ? "" : "s";
    Swal.fire({
      title: "Confirmation?",
      text: `Êtes-vous certain de vouloir changer le statut de ce${pluriel} sous  traitant${pluriel}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, changer les statuts!",
      confirmButtonColor: "#0e3feb",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed && stIds) {
        const { data } = await FetchData(`/api/sous-traitant/status/${stIds}`, {
          method: "PUT",
        });
        let message = "";
        if (data.status != 200) {
          message =
            stIds.length == 1 ? "du soustraitant" : "des sous traitants";
          Swal.fire({
            title: "Erreur",
            text: `Une erreur s'est produite lors du changement de statut  ${message}.\n${data.error}`,
            icon: "error",
            confirmButtonColor: "#0e3feb",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Succès",
            text: "Le statut des sous traitants a été modifié avec succès.",
            icon: "success",
            position: "center",
            showConfirmButton: false,
            timer: 3000,
          });
          getAllSousTraitants();
        }
      }
    });
  };

  useEffect(() => {
    getAllSousTraitants();
  }, []);

  useEffect(() => {
    // Action update here
    if (
      data_action_td.action_name === "update_st" &&
      data_action_td.action_value > 0 &&
      can_see_subcontractor_infos
    ) {
      getSous_traitant(data_action_td.action_value).then(() => {
        showModal(2);
      });
      dispatch(
        initUpdate({
          action_value: -1,
        })
      );
    }
  }, [data_action_td]);

  return can_access_sous_traitants_page ? (
    <Admin>
      <TableTitle
        title="Sous Traitants"
        button_name="Nouveau Sous traitant"
        show_button={can_create_new_subcontractors}
        modalId={"addSousTraitant"}
        onClick={() => showModal(1)}
      />
      {(can_see_all_subcontractors ||
        can_see_unallowed_subcontractors ||
        can_see_allowed_subcontractors) && (
        <Table
          data_table={sous_traitants}
          table_headers={table_headers}
          table_key={table_key}
          filter={filter}
          active_index_filter={active_index_filter}
          search_key={search_key}
          data_btn_control={st_data_btn_control}
          onDelete={handleDeletes}
          onChangeStatus={handleChangeStatus}
          data_ref={data_ref}
          show_search_input={can_search_subcontractors}
        />
      )}
      <AddSousTraitant
        modalData={{
          type: "modal",
          modalId: "addSousTraitant",
          modalClass: "modal-xl",
        }}
        isShowModal={isShowModal}
        whyShown={whyShown}
        currentSt={sous_traitant}
        stStatus={st_status}
        data_country={country_list}
        handleCloseModal={closeModal}
        handleRefreshSt={getAllSousTraitants}
      />
      {isShowModal && <div className="modal-backdrop fade show"></div>}
    </Admin>
  ) : (
    RedirectUnallowed("/Accueil")
  );
};

export default StPage;
