import React from "react";

const DropdownButton = ({ children }) => {
  return (
    <>
      <div className="">
        <button
          className="btn btn-light"
          type="button"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v"></i>
        </button>
        <ul className="dropdown-menu p-0">{children}</ul>
      </div>
    </>
  );
};

export default DropdownButton;
