import React from "react";
import Widget from "./Widget";
import "./../../styles/stats/widget.css";
import BarChart from "./BarChart";
import { useToggle } from "react-use";

function CommandeStats({
  data = [],
  btn_data = null,
  data_bar,
  setAnnee,
  annee,
}) {
  let number_col = Math.ceil(12 / data.length);
  number_col = [4, 6, 3].indexOf(number_col) != -1 ? number_col : 4;
  const [isShowBar, setIsShowBar] = useToggle(false);
  const handleClick = () => {
    setIsShowBar();
  };
  return (
    <>
      {data.length > 0 && (
        <>
          <div className="w-100 ps-2">
            <div className={"row px-0 " + (btn_data && "bsl-widget-mr-btn")}>
              <div
                className={
                  "row ps-0 col-sm-12 " + (btn_data ? " col-md-11  " : " pe-0")
                }
              >
                {data.map((d, index) => (
                  <div
                    className={
                      `col-md-${number_col} col-sm-12 pt-1 ` +
                      ((index == data.length - 1) & !btn_data ? " pe-0 " : "") +
                      ((index == 0) & !btn_data ? " pe-0 " : "")
                    }
                    key={index}
                  >
                    <Widget data={d} />
                  </div>
                ))}
              </div>
              {btn_data && (
                <div className="col-md-1 pt-1 col-sm-12 px-0">
                  <Widget data={btn_data} clickFun={handleClick} />
                </div>
              )}
            </div>
          </div>
          {isShowBar && btn_data && (
            <BarChart data_bar={data_bar} setAnnee={setAnnee} annee={annee} />
          )}
        </>
      )}
    </>
  );
}

export default CommandeStats;
