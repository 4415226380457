const monthTranslations = {
  January: "Janvier",
  February: "Février",
  March: "Mars",
  April: "Avril",
  May: "Mai",
  June: "Juin",
  July: "Juillet",
  August: "Août",
  September: "Septembre",
  October: "Octobre",
  November: "Novembre",
  December: "Décembre",
};

const monthColors = {
  January: "#774C3D",
  February: "#6B8ABC",
  March: "#D568FB",
  April: "#2EE0CA",
  May: "#FA4B42",
  June: "#FEB56A",
  July: "#91E8E1",
  August: "#FF7C2B",
  September: "##2BFF7C",
  October: "#2CAFFE",
  November: "#544FC5",
  December: "#00E272",
};

export { monthTranslations, monthColors };
