import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  ref_identifier: [],
  value_search: [],
  data_label: [],
};
const searchDropdownSlice = createSlice({
  name: "dropdown",
  initialState,
  reducers: {
    initDropdown: (state, action) => {
      let ref_identifiers = ["ref_identifier_dropdown"];

      state.ref_identifier = action.payload.ref_identifier
        ? action.payload.ref_identifier
        : ref_identifiers;
      state.value_search = action.payload.value_search;
      state.data_label = action.payload.data_label;
    },
    updateDropdown(state, action) {
      state.data_label = state.data_label.map((label) =>
        label.name == action.payload?.ref_identifier[0]
          ? { ...action.payload.data_label[0] }
          : { ...label }
      );
      state.value_search = state.value_search.map((value) =>
        value.name == action.payload.ref_identifier[0]
          ? { ...action.payload.value_search[0] }
          : { ...value }
      );
    },
  },
});

export const { initDropdown, updateDropdown } = searchDropdownSlice.actions;
export default searchDropdownSlice.reducer;
