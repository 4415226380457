import React, { useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import useValidateDate from "../../hooks/form/useValidateDate";
import { useDispatch, useSelector } from "react-redux";
import { initDateRange } from "../../store/dateRangeSlice";
import { formatDateToYYYYMMDD } from "../../hooks/form/dateBSL";

function DateRangeFilter() {
  const dispatch = useDispatch();
  const date_init = useSelector((state) => state.dateRange.date_init);

  const {
    label,
    state,
    toggle,
    setToggle,
    handleValid,
    setState,
    clickShowDropdown,
    setdateRange,
    setLabel,
    nameOfFilterDate,
  } = useValidateDate(
    new Date(date_init.startDate),
    new Date(date_init.endDate)
  );

  useEffect(() => {
    setTimeout(() => {
      setState({
        startDate: new Date(date_init.startDate),
        endDate: new Date(date_init.endDate),
        key: "selection",
      });
      setdateRange({
        startDate: new Date(date_init.startDate),
        endDate: new Date(date_init.endDate),
        key: "selection",
      });
    }, 1000);
    setLabel(
      nameOfFilterDate(
        new Date(date_init.startDate),
        new Date(date_init.endDate)
      )
    );
  }, [date_init]);

  const vaidated = () => {
    const data = {
      startDate: formatDateToYYYYMMDD(state.startDate),
      endDate: formatDateToYYYYMMDD(state.endDate),
    };
    dispatch(initDateRange(data));
    handleValid();
  };

  const reinitialize = () => {
    const data = {
      startDate: date_init.startDate,
      endDate: date_init.endDate,
    };

    dispatch(initDateRange(data));
    setToggle(false);
    setLabel(
      nameOfFilterDate(
        new Date(date_init.startDate),
        new Date(date_init.endDate)
      )
    );
  };

  useEffect(() => {
    reinitialize();
  }, []);

  return (
    <>
      <div className="dropdown bsl-search-drop">
        <button
          type="button"
          className="alert alert-secondary dropdown-toggle p-1 mb-0"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-bs-auto-close="outside"
          onClick={clickShowDropdown}
        >
          <i className="fa-regular fa-calendar me-2 fs-5"></i>
          {label}
        </button>
        <ul
          className={`dropdown-menu px-2 bsl-dropdown-serach-content" ${
            toggle ? "show" : ""
          }`}
        >
          {/* <li>
            <div className="input-group mb-2 ps-2 w-100 cursor-pointer">
              Mois complet
            </div>
          </li> */}
          <li>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setState(item.selection)}
              moveRangeOnFirstSelection={false}
              ranges={[state]}
              showDateDisplay={false}
            />
          </li>
          <li className="text-sm-end">
            <p className="d-inline-flex gap-1 mb-0">
              <button
                type="button"
                className="btn  btn-sm bsl-btn-annuler-date bg-white border-1 border-primary text-primary"
                onClick={() => {
                  reinitialize();
                }}
              >
                Réinitialiser
              </button>
              <button
                type="button"
                className="btn  btn-sm bsl-btn-annuler-date"
                onClick={() => {
                  setToggle(false);
                }}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-sm bsl-btn-valider-date"
                onClick={vaidated}
              >
                Valider
              </button>
            </p>
          </li>
        </ul>
      </div>
    </>
  );
}

export default DateRangeFilter;
