import React, { useEffect, useState } from "react";
import ModalForAddNew from "../../ModalForAddNew";
import { FetchData } from "../../../../hooks/FetchData";
import Swal from "sweetalert2";
import ShowInfosCommande from "./ShowInfosCommande";
import UpdateCommandeForm from "./UpdateCommandeForm.js";
import CommandeHistory from "./CommandeHistory.js";
import NavsAndTabs from "../../../Form/NavsAndTabs.js";
import { useAccess } from "../../../../hooks/useAccess.js";

const UpdateCommande = ({
  modalData = {},
  isShowModal = false,
  handleCloseModal = function () {},
  data_clients = [],
  data_subcontractors = [],
  order = null,
  idUser = 0,
  getAllOrders = () => {},
  getAllNeededData = () => {},
  isUpdate = false,
  changeIsUpdate = () => {},
  order_statuts = [],
  data_country_list = [],
  data_log = [],
}) => {
  //Permissions
  const can_update_order = useAccess(44);
  const can_change_one_order_status = useAccess(43);
  const can_see_orders_log = useAccess(82);

  // components datas
  const [order_statut, setOrderStatut] = useState(
    order?.statut || {
      id: 0,
      label: "En cours",
      color: 2,
    }
  );

  const [error_message, setError_message] = useState({
    nombre_sous_traitants: "",
    date_validation: "",
    pays_societe_origine: "",
    code_postal_societe_origine: "",
    ville_societe_origine: "",
    adresse_origine: "",
    pays_destination: "",
    code_postal_destination: "",
    ville_destination: "",
    adresse_destination: "",
    nbs_colis: 0,
    poids: 0,
    prix_achat: 0,
    prix_vente: 0,
    tva: 0,
    branche: "",
    client: "",
    misc_fees_amount: 0,
    d_and_t: 0,
    delivery_packaging: 0,
  });

  //lists of labels of input/selectBox fields
  const [error_list, setError_list] = useState([]);
  const [errorSub, setErrorSub] = useState([]);

  //components functions

  function colorDropdown(orderStatutId) {
    const colors = [
      "",
      "alert tbs-alert alert-warning ",
      "alert tbs-alert alert-primary ",
      "alert tbs-alert alert-success ",
      "alert tbs-alert alert-danger ",
      "alert tbs-alert alert-secondary ",
      "alert tbs-alert alert-info ",
      "alert tbs-alert alert-light ",
    ];
    return colors[orderStatutId] || "";
  }

  // handle order's status change in form and database
  async function handleOrderStatus(e) {
    const status_index = order_statuts.findIndex(
      (statut) => statut.order_statut_id === parseInt(e.target.value, 10)
    );

    if (status_index !== -1) {
      const selectedStatut = order_statuts[status_index];
      setOrderStatut({
        id: selectedStatut.order_statut_id,
        label: selectedStatut.order_statut_name,
        color: selectedStatut.order_color,
      });

      let new_order = {
        ...order,
        order_statut_id: selectedStatut.order_statut_id,
        order_user_id_update: idUser,
      };

      try {
        const url = `/api/order/${order.order_id}`;
        const options = {
          method: "PUT",
          body: JSON.stringify(new_order),
        };

        const { data } = await FetchData(url, options);

        if (data.status === 200) {
          Swal.fire({
            title: "Succès",
            text: "Modification de la commande effectuée.",
            icon: "success",
            position: "center",
            showConfirmButton: false,
            timer: 3000,
          });
          getAllOrders();
          getAllNeededData();
          resetError();
        } else {
          Swal.fire({
            title: "Erreur!",
            text: data.error,
            icon: "error",
            position: "center",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      } catch (error) {
        console.error("Erreur lors de la connexion:", error);
      }
    }
  }

  // handle form errors reset
  function resetError() {
    handleCloseModal();
    setError_message({
      contactSelected: "",
      sous_traitants_1: "",
      nombre_sous_traitants: "",
      heure_livraison: "",
      date_livraison: "",
      refClient: "",
      date_validation: "",
      pays_societe_origine: "",
      code_postal_societe_origine: "",
      ville_societe_origine: "",
      adresse_origine: "",
      pays_destination: "",
      code_postal_destination: "",
      ville_destination: "",
      adresse_destination: "",
      nbs_colis: 0,
      poids: 0,
      prix_achat: 0,
      prix_vente: 0,
      tva: 0,
      a_facturer: "",
      branche: "",
      client: "",
      misc_fees_amount: 0,
      d_and_t: 0,
      delivery_packaging: 0,
    });
    setErrorSub((prevErrorSub) =>
      prevErrorSub.map((err) => ({
        ...err,
        order_st_st_id: "",
        order_st_ref: "",
        order_st_cost: "",
      }))
    );
  }

  useEffect(() => {
    setOrderStatut(order?.statut || order_statut);
  }, [order]);

  const show_logs = can_see_orders_log
    ? [
        {
          id: 2,
          name: "Historique",
          content: <CommandeHistory data_log={data_log} />,
        },
      ]
    : [];
  const data_tab = [
    {
      id: 1,
      name: "Commande",
      content: (
        <>
          {JSON.stringify(order) !== "{}" && !isUpdate ? (
            <ShowInfosCommande order={order} changeIsUpdate={changeIsUpdate} />
          ) : (
            <UpdateCommandeForm
              handleCloseModal={handleCloseModal}
              data_clients={data_clients}
              order={order}
              idUser={idUser}
              getAllOrders={getAllOrders}
              getAllNeededData={getAllNeededData}
              isUpdate={isUpdate}
              data_country_list={data_country_list}
              data_subcontractors={data_subcontractors}
              error_list={error_list}
              error_message={error_message}
              resetError={resetError}
              errorSub={errorSub}
              setErrorSub={setErrorSub}
              setError_message={setError_message}
              setError_list={setError_list}
            />
          )}
        </>
      ),
    },
    ...show_logs,
  ];

  return (
    <ModalForAddNew modalData={modalData} isShowModale={isShowModal}>
      <div className="modal-header container-fluid">
        <h1
          className="modal-title fs-5 ms-2 text-nowrap"
          id={modalData?.modalId + "Label"}
        >
          {JSON.stringify(order) !== "{}"
            ? order?.order_num_title
            : "Commande mis à jour"}
          <span className="badge input-label-size bg-primary-subtle text-primary-emphasis fw-light ms-2">
            {JSON.stringify(order) !== "{}"
              ? order?.order_is_direct == 1
                ? "Direct"
                : "Demande"
              : "Direct"}
          </span>
          {order?.statut?.id !== 7 &&
          isUpdate &&
          can_update_order &&
          can_change_one_order_status ? (
            <select
              disabled={!isUpdate}
              className={
                "input-label-size fw-light border-0 ms-2 " +
                colorDropdown(order_statut?.color)
              }
              onChange={handleOrderStatus}
              value={order_statut?.id}
            >
              {order_statuts.map((os_data, os_index) => (
                <option
                  className={
                    "ps-2 pe-2 " +
                    colorDropdown(7) +
                    (order_statut.id === os_data.order_statut_id
                      ? " fw-bold "
                      : "")
                  }
                  key={"status-" + os_index}
                  value={os_data.order_statut_id}
                >
                  {os_data.order_statut_name}
                </option>
              ))}
            </select>
          ) : (
            <span
              className={
                "input-label-size fw-light border-0 ms-2 " +
                colorDropdown(order_statut?.color)
              }
            >
              {order_statut?.label}
            </span>
          )}
        </h1>

        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={resetError}
        ></button>
      </div>
      <NavsAndTabs data_tab={data_tab} />
    </ModalForAddNew>
  );
};

export default UpdateCommande;