import React, { useEffect, useState } from "react";
import "../../styles/Form/input.css";
import "../../styles/Pages/login.css";

const Input = ({
  type = "text",
  blockClass = "",
  id = "text",
  labelClass = "",
  label = "label",
  inputClass = "",
  placeholder = "placeholder",
  isRequired = false,
  isDisabled = false,
  value = "",
  suffix = "€",
  errorMessage = "",
  maxLength = 524288,
  onChange = (e) => {},
}) => {
  const [inputError, setInputError] = useState("");

  useEffect(() => {
    setInputError(errorMessage);
  }, [errorMessage]);

  const [v, setV] = useState(value);

  useEffect(() => {
    setV(value);
  }, [value]);

  const checkNumber = (e) => {
    const validKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Enter",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ".",
    ];
    let number = e.target.value;
    if (validKeys.includes(e.key)) {
      setV(number);
      onChange(number);
      if (
        ["Backspace", "ArrowLeft", "ArrowRight", "Delete"].includes(e.key) &&
        number.length < 1
      ) {
        setInputError(errorMessage);
      } else if (/^0$/.test(e.key) && number.length == 1 && isRequired) {
        setInputError(errorMessage);
      } else {
        setInputError("");
      }
    } else {
      setInputError("Entrer un nombre");
    }
  };

  const handleChange = (e) => {
    const value_ = e.target.value;

    if (isRequired && value_.length > 0) {
      setInputError("");
    } else {
      setInputError(errorMessage);
    }

    if (type === "number") {
      if (/^\d*\.?\d*$/.test(value_)) {
        setV(value_);
        onChange(value_);
        setInputError("");
      } else {
        let newValue = value_.slice(0, -1);
        setV(newValue);
        setInputError("Entrer un nombre");
      }
    } else if (type === "time") {
      if (value_ !== "" && value_ !== null) {
        setV(value_);
        onChange(value_);
        setInputError("");
      }
    } else if (type === "date") {
      if (value_ !== "" && value_ !== null) {
        setV(value_);
        onChange(value_);
        setInputError("");
      }
    } else {
      setV(value_);
      onChange(value_);
    }
  };

  return (
    <>
      <div className={"mb-3 " + blockClass}>
        <>
          <style>
            {`
          input[type="number"]::-webkit-outer-spin-button,
          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"] {
            -moz-appearance: textfield;
          }
        `}
          </style>
          <label
            htmlFor={id}
            className={
              "form-label input-label-size position-relative " + labelClass
            }
          >
            {label}
            {isRequired === true ? (
              <span className="position-absolute top-0 start-100 translate-middle m-2 bg-transparent text-danger rounded-circle">
                *
              </span>
            ) : null}
          </label>

          <div className="input-group">
            <input
              value={v}
              onChange={handleChange}
              type={type === "text" ? "text" : type}
              className={
                "form-control form-control-lg input-text-size " +
                inputClass +
                " bg-light" +
                (inputError?.length > 5 ? " invalid-input-colors " : " ")
              }
              id={id}
              name={id}
              placeholder={placeholder}
              onKeyUp={type === "number" ? (e) => checkNumber(e) : () => {}}
              maxLength={maxLength}
              disabled={isDisabled}
              autoComplete="off"
            />

            {type === "number" && suffix !== "" && (
              <span
                className={
                  "input-group-text input-label-size" +
                  (inputError?.length > 5 ? " invalid-input-colors " : " ")
                }
              >
                {suffix}
              </span>
            )}
          </div>
          {inputError?.length > 5 && (
            <span className="input-error-message">
              <i className="fa-solid fa-circle-exclamation text-danger me-2"></i>
              {inputError}
            </span>
          )}
          {maxLength < 524288 && (
            <span className="d-flex input-text-size text-end justify-content-end">
              {value == undefined
                ? 0 + "/" + maxLength
                : value?.length + "/" + maxLength}
            </span>
          )}
        </>
      </div>
    </>
  );
};
// required={isRequired}
export default Input;
