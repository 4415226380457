import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  search,
  updateNumberFilter,
  updateFilter,
} from "../../store/table/tableSlice";
/**
 * Action on the search input on the controle header table
 *
 * @param {String} value
 * @returns object
 * @author Johns David
 */
export default function useSearchTable(value = "") {
  const index_filter = useSelector((state) => state.table.index_filter);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState(value);
  const searchChange = (value, dataId) => {
    setSearchValue(value);
    dispatch(search({ value: value, dataId: dataId }));
    dispatch(updateNumberFilter());
  };
  return {
    searchChange,
    searchValue,
  };
}
