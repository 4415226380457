import React from "react";
import Admin from "../root/Admin.js";
import TableTitle from "../Tables/TableTitle.js";

const NoAccessPage = () => {
  return (
    <Admin>
      <TableTitle title="Aucun Accès" />
    </Admin>
  );
};

export default NoAccessPage;
