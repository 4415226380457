import React from "react";
import FormCard from "../../../Form/FormCard";
import ListGroup from "../../../Form/ListGroup";
import ListItemContents from "../../../Form/ListItemContents";
import ListItems from "../../../Form/ListItems";

const DetailResponseDemande = ({ answer, responsable, _data_list }) => {
  return (
    <div className="container-fluid container-sm">
      <FormCard title="Général">
        <ListGroup>
          <ListItems>
            <ListItemContents label="Responsable" value={responsable} />
          </ListItems>
          <ListItems>
            <div className="d-flex p-0 m-0">
              <ListItemContents
                label="Date de réponse"
                value={answer?.req_as_created_at?.split("T")[0]}
                contentClass="col "
              />
              <ListItemContents
                label="Heure"
                value={answer?.req_as_created_at?.split("T")[1].split(".")[0]}
                contentClass="col "
              />
            </div>
          </ListItems>
        </ListGroup>
      </FormCard>
      <FormCard title="Sous traitants">
        <ListGroup>
          <ListItems>
            <ListItemContents
              label="Nombre de sous traitants"
              value={
                answer?.sous_traitant?.length
                  ? answer?.sous_traitant?.length
                  : 0
              }
            />
          </ListItems>
          {answer?.sous_traitant?.map((item_s, i) => (
            <ListItems key={i}>
              <div className="d-flex p-0 m-0">
                <ListItemContents
                  label={`Sous Traitant ${i + 1}`}
                  value={
                    _data_list?.sous_traitants?.find(
                      (s) => s.st_id === item_s.req_ast_subcontractor_id
                    )?.st_company_name
                  }
                  contentClass="col "
                />
                <ListItemContents
                  label="Coût"
                  value={item_s?.req_ast_cost}
                  contentClass="col "
                />
                <ListItemContents
                  label="Réference"
                  value={item_s?.req_ast_ref}
                  contentClass="col "
                />
              </div>
            </ListItems>
          ))}
        </ListGroup>
      </FormCard>
      <FormCard title="Proposition commerciale">
        <ListGroup>
          <ListItems>
            <div className="d-flex p-0 m-0">
              <ListItemContents
                label="Prix de vente"
                value={answer?.req_as_sell_price}
                contentClass="col "
              />
              <ListItemContents
                label="Prix d'achat"
                value={answer?.req_as_buying_price}
                contentClass="col "
              />
            </div>
          </ListItems>
          <ListItems>
            <ListItemContents
              label="TVA"
              value={
                !answer?.req_as_partial ? "0 %" : answer?.req_as_partial + " %"
              }
              contentClass="col"
            />
          </ListItems>
          <ListItems>
            <div className="d-flex p-0 m-0">
              <ListItemContents
                label="Marge brute"
                value={answer?.req_as_gross_margin}
                contentClass="col "
              />
              <ListItemContents
                label="Taux marge"
                value={answer?.req_as_margin_rate + " %" || "0 %"}
                contentClass="col "
              />
              <ListItemContents
                label="Taux marque"
                value={answer?.req_as_rate_mark + " %" || "0 %"}
                contentClass="col "
              />
            </div>
          </ListItems>
        </ListGroup>
      </FormCard>
      <FormCard title="Frais divers">
        <ListGroup>
          <ListItems>
            <div className="d-flex p-0 m-0">
              <ListItemContents
                label="Nom frais divers"
                value={answer?.req_as_fees_miscellaneous_name}
                contentClass="col "
              />
              <ListItemContents
                label="Montant frais divers"
                value={answer?.req_as_fees_miscellaneous_cost}
                contentClass="col "
              />
            </div>
          </ListItems>
        </ListGroup>
      </FormCard>
    </div>
  );
};

export default DetailResponseDemande;
