import { useEffect, useState } from "react";
import { useToggle } from "react-use";
import {
  firstLastDay,
  getMonthYearByDate,
  formatDateToYYYYMMDD,
  getShortDateMonthYearByDate,
} from "./dateBSL";
import { useSelector } from "react-redux";
/**
 * Usefull state for daterange with personal label
 *
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns object
 * @author Johns David
 */
export default function useValidateDate(startDate, endDate) {
  const nameOfFilterDate = (dateStart, dateEnd) => {
    const strDateStart = formatDateToYYYYMMDD(dateStart);
    const strDateEnd = formatDateToYYYYMMDD(dateEnd);

    const today = new Date();
    let result =
      getShortDateMonthYearByDate(dateStart) +
      " - " +
      getShortDateMonthYearByDate(dateEnd);
    if (
      formatDateToYYYYMMDD(today) === strDateStart &&
      strDateStart === formatDateToYYYYMMDD(dateEnd)
    ) {
      return "Aujourd'hui";
    }

    if (
      formatDateToYYYYMMDD(today) === formatDateToYYYYMMDD(dateEnd) &&
      strDateStart === "2024-01-01"
    ) {
      return "Jusqu’aujourd’hui";
    }

    if (
      dateStart.getFullYear() === dateEnd.getFullYear() &&
      dateStart.getMonth() === dateEnd.getMonth()
    ) {
      const verif = firstLastDay(strDateStart);

      if (verif.endDate === strDateEnd && verif.startDate === strDateStart) {
        return getMonthYearByDate(dateEnd);
      }
    }
    return result;
  };

  // var label = nameOfFilterDate(startDate, endDate);
  // const setLabel = (data) => {
  //   label = data;
  // };

  const [label, setLabel] = useState(nameOfFilterDate(startDate, endDate));

  const [toggle, setToggle] = useToggle(false);

  const valueDataDate = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  const [dateRange, setdateRange] = useState(valueDataDate);

  // console.log(`start: ${startDate} - end: ${endDate}`);
  const [state, setState] = useState(valueDataDate);

  const handleValid = () => {
    setToggle(false);
    setdateRange({ ...state });
    const data = nameOfFilterDate(state.startDate, state.endDate);
    setLabel(data);
  };
  const clickShowDropdown = () => {
    setToggle(true);
    setState(dateRange);
  };

  return {
    label,
    dateRange,
    state,
    toggle,
    setToggle,
    handleValid,
    setState,
    clickShowDropdown,
    setdateRange,
    setLabel,
    nameOfFilterDate,
  };
}
