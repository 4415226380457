import React, { useEffect } from "react";
import "../../../../styles/log/log-demande.css";

export default function ShowLogDemande({ data_log = [] }) {
  return (
    <>
      <div
        id="vertical-timeline"
        className="vertical-container dark-timeline center-orientation p-2"
      >
        {data_log.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <ShowLogItem key={index} item={item} />
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
}

const ShowLogItem = ({ item = [] }) => {
  const verifSt = (data) => {
    return (
      [
        ...data["req_ast_cost"],
        ...data["st_company_name"],
        ...data["req_ast_ref"],
      ].filter((d) => d.is_diff).length > 0
    );
  };
  const title = {
    Crée: "Création",
    Affecté: "Affectation",
    Répondue: "Réponse",
    Refusée: "Refus",
    Annulée: "Annulation",
    Validée: "Validation",
    Supprimée: "Suppression",
    "Commande envoyée": "Envoie commande",
    Relancé: "Relance",
    Modifié: "Modification",
    "Action crée": "Création d'une action",
    "Action supprimé": "Suppresion d'une action",
    "Action modifié": "Modification d'une action",
    "Réponse crée": "Création d'une réponse",
    "Réponse supprimé": "Suppresion d'une réponse",
    "Réponse modifié": "Modification d'une réponse",
    // req_ast_cost: "Coût",
    // st_company_name: "Sous traitants",
    // req_ast_ref: "Référence",
  };

  const ref = [
    "req_num_title",
    "client_name",
    "req_client_ref",
    "contact_name",
    "branch_name",
    "req_origin_company",
    "origin_country_name",
    "req_origin_zip_code",
    "req_origin_city",
    "req_origin_adress",
    "req_dest_company",
    "dest_country_name",
    "req_dest_zip_code",
    "req_dest_city",
    "req_dest_adress",
    "req_goods_nb_colis",
    "req_goods_gross_weight",
    // "req_goods_volumetric_weight",
    "req_goods_length",
    "req_goods_width",
    "req_goods_height",
    "req_fret_taxable_weight",
    "req_weight_taxable_integrator",
    "req_goods_ref_dbl",
    "req_comment",
    "req_goods_description",
    "req_file_email",
    "req_file_image",
    "request_status_name",
    "responsable",
    "rr_comment",
    "rrc_name",
    "ri_comment",
    "rla_done",
    "order_num_title",
    "req_as_sell_price",
    "req_as_buying_price",
    "req_as_tva",
    "req_as_partial",
    "req_as_gross_margin",
    "req_as_margin_rate",
    "req_as_rate_mark",
    "req_as_fees_miscellaneous_name",
    "req_as_fees_miscellaneous_cost",
    "req_date_request",
    "req_time_request",
    "req_as_pdf_name",
  ];
  return (
    <>
      <div className="vertical-timeline-block">
        <div className={"vertical-timeline-icon " + item.action_name.bg}>
          <i className={`fa ${item.action_name.icon} text-white`}></i>
        </div>

        <div className="vertical-timeline-content">
          <h2 className="fs-5 text-center mb-2 text-light-emphasis">
            <b>{title[item.action_name.value]}</b>
          </h2>
          <table className="table table-bordered log-table">
            <tbody>
              {ref.map((d, i) => (
                <React.Fragment key={i}>
                  {item[d].is_diff ? (
                    <>
                      <ItmeLog d={d} item={item} />
                    </>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))}
              {verifSt(item) && (
                <>
                  {item["st_company_name"].map((c, ic) => (
                    <React.Fragment key={ic}>
                      <tr>
                        <td
                          colSpan={2}
                          className="text-center text-light-emphasis"
                        >
                          <b>Sous traitant </b>
                        </td>
                      </tr>
                      <ItemStResponse item={item["st_company_name"][ic]} />
                      <ItemStResponse item={item["req_ast_cost"][ic]} />
                      <ItemStResponse item={item["req_ast_ref"][ic]} />
                    </React.Fragment>
                  ))}
                </>
              )}
            </tbody>
          </table>
          <span className="vertical-date">
            {item.log_request_date} <br />
            <small>
              {item.action_name.value} par <b>{item.user_update}</b>
            </small>
          </span>
        </div>
      </div>
    </>
  );
};

export function ItemStResponse({ item }) {
  return (
    <>
      {item.is_diff === true ? (
        <tr>
          <td className="py-1">{item.label} </td>
          <td className="py-1">
            <span className="text-decoration-line-through text-danger">
              {item.old_value}
            </span>
            {item.old_value != null && (
              <i className="fa fa-arrow-right mx-2"></i>
            )}
            <span>{item.value}</span>
          </td>
        </tr>
      ) : (
        <></>
      )}
    </>
  );
}

export function ItmeLog({ d, item }) {
  return (
    <>
      <tr key={d}>
        <td className="py-1">{item[d].label} </td>
        <td className="py-1">
          <span className="text-decoration-line-through text-danger">
            {item[d].old_value}
          </span>
          {item[d].old_value != null && (
            <i className="fa fa-arrow-right mx-2"></i>
          )}
          <span>{item[d].value}</span>
        </td>
      </tr>
      {item[d].progress && (
        <tr>
          <td colSpan={2} className="">
            <span className="text-start d-inline-block align-middle badge input-label-size text-secondary-emphasis bg-light fw-light ms-2">
              {item[d].progress?.value === 100 ? "Validé" : "En cours"}
            </span>
            <div
              className="progress ms-3 text-center d-inline-block align-middle"
              style={{ width: "65%", height: "7px" }}
            >
              <div
                className={`progress-bar bg-${item[d].progress?.color}`}
                role="progressbar"
                style={{
                  width: `${item[d].progress?.value}%`,
                  height: "7px",
                }}
              ></div>
            </div>
            <span
              className="text-muted ms-3 text-end d-inline-block align-middle"
              style={{ fontSize: "15px" }}
            >
              {item[d].progress?.text}
            </span>
          </td>
        </tr>
      )}
    </>
  );
}
