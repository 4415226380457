import React from "react";
/**
 * Item element for Nav
 *
 * @param {{image: String, title: String, end: boolean, selected:boolean, onClick: ()=>{}, collapse: boolean}} param0
 * @returns component
 * @author Johns David
 */
function ItemNav({
  image,
  title,
  end = false,
  selected = false,
  onClick,
  collapse = true,
}) {
  return (
    <>
      <div
        className={selected ? "itemnav-black" : "item-nav"}
        onClick={onClick}
      >
        <div className={selected ? "div-3" : "div-2"}>
          <img className="img-3" src={image} alt="" />
          {!collapse && (
            <div className={selected ? "text-wrapper" : "label"}>{title}</div>
          )}
        </div>
      </div>

      {!end && (
        <div className={"divider " + (collapse && "divider-collapse ")}></div>
      )}
    </>
  );
}

export default ItemNav;
