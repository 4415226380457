/**
 * Get n days in pas after today
 *
 * @param {int} days
 * @returns Date
 * @author Johns David
 */
const getEndDate = (days = 7) => {
  // Get the current date
  var currentDate = new Date();
  // Subtract 7 days from the current date
  var nDaysAgo = new Date(currentDate);
  nDaysAgo.setDate(currentDate.getDate() - days);
  return nDaysAgo;
};
const firstLastDay = (day) => {
  // Get the current date
  var currentDate = new Date(day);
  // Set the date to the first day of the current month
  var firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  // Set the date to the last day of the current month
  var lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  // Format the dates to display in a readable format
  var formattedFirstDay = firstDayOfMonth.toLocaleDateString().split("/");
  var formattedLastDay = lastDayOfMonth.toLocaleDateString().split("/");
  return {
    endDate: `${formattedLastDay[2]}-${formattedLastDay[1]}-${formattedLastDay[0]}`,
    startDate: `${formattedFirstDay[2]}-${formattedFirstDay[1]}-${formattedFirstDay[0]}`,
  };
};

/**
 * Name of month
 *
 * @param {string} dateString
 * @returns string
 * @author Johns David
 */
const getMonthYearByDate = (dateString) => {
  // Create a new Date object from the input string
  var date = new Date(dateString);
  const months = [
    "Janvier", // 0
    "Février", // 1
    "Mars", // 2
    "Avril", // 3
    "Mai", // 4
    "Juin", // 5
    "Juillet", // 6
    "Août", // 7
    "Septembre", // 8
    "Octobre", // 9
    "Novembre", // 10
    "Décembre", // 11
  ];
  return months[date.getMonth()] + " " + date.getFullYear();
};

/**
 * Short of Name month
 *
 * @param {string} dateString
 * @returns string
 * @author Johns David
 */
const getShortDateMonthYearByDate = (dateString) => {
  var date = new Date(dateString);
  const shortMonths = [
    "Jan", // Janvier
    "Fév", // Février
    "Mar", // Mars
    "Avr", // Avril
    "Mai", // Mai
    "Juin", // Juin
    "Juil", // Juillet
    "Aoû", // Août
    "Sep", // Septembre
    "Oct", // Octobre
    "Nov", // Novembre
    "Déc", // Décembre
  ];
  return (
    date.getDate() +
    " " +
    shortMonths[date.getMonth()] +
    " " +
    date.getFullYear()
  );
};
function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois commencent à 0, donc ajouter +1
  const day = String(date.getDate()).padStart(2, "0"); // Ajoute un zéro pour avoir toujours deux chiffres
  return `${year}-${month}-${day}`;
}

export {
  getEndDate,
  firstLastDay,
  getMonthYearByDate,
  formatDateToYYYYMMDD,
  getShortDateMonthYearByDate,
};
