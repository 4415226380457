import React, { useEffect } from "react";
import Admin from "../root/Admin";
import TableTitle from "../Tables/TableTitle";
import DashBoard from "../stats/Dashboard";
import { useDispatch } from "react-redux";
import { formatDateToYYYYMMDD } from "../../hooks/form/dateBSL.js";
import { resetDateRange } from "../../store/dateRangeSlice.js";

const HomePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      resetDateRange({
        startDate: "2024-01-01",
        endDate: formatDateToYYYYMMDD(new Date()),
      })
    );
  }, [dispatch]);
  return (
    <Admin>
      <TableTitle title="Accueil" />
      <DashBoard />
    </Admin>
  );
};

export default HomePage;
