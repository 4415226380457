import React, { useEffect } from "react";
import FormCard from "../../../Form/FormCard";
import ListGroup from "../../../Form/ListGroup";
import ListItemContents from "../../../Form/ListItemContents";
import ListItems from "../../../Form/ListItems";

const Invalide = ({ _demande }) => {
  return (
    <div className="container-fluid container-sm p-4">
      <FormCard title="Commentaire">
        <ListGroup>
          <ListItems>
            <ListItemContents
              label="Commentaire"
              value={_demande?.invalide?.ri_comment}
            />
          </ListItems>
        </ListGroup>
      </FormCard>
    </div>
  );
};

export default Invalide;
