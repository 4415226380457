import React, { useEffect, useMemo, useRef, useState } from "react";
import FormButton from "../../../Form/FormButton";
import FormSection from "../../../Form/FormSection";
import Input from "../../../Form/Input";
import SelectBox from "../../../Form/SelectBox";
import SelectBoxSearch from "../../../Form/SelectBoxSearch";
import TextArea from "../../../Form/TextArea";
import { FetchData } from "../../../../hooks/FetchData";
import Form from "../../../Form/Form";
import Swal from "sweetalert2";
import { SearchCity } from "../../../../hooks/SearchCity";

const UpdateCommandeForm = ({
  handleCloseModal = function () {},
  data_clients = [],
  order = {},
  idUser = 0,
  getAllOrders = () => {},
  getAllNeededData = () => {},
  isUpdate = false,
  data_country_list = [],
  data_subcontractors = [],
  error_list = [],
  error_message = {},
  resetError = () => {},
  errorSub = [],
  setErrorSub = () => {},
  setError_message = () => {},
  setError_list = () => {},
}) => {
  //Référence au formulaire
  const formRef = useRef(null);

  //data for form
  const [contact, setContact] = useState("");
  const [client_email_contact, set_client_email_contact] = useState("");
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState("0");
  const [ref_client, setRef_client] = useState("");
  const [nombre_sous_traitants, setNombre_sous_traitants] = useState("0");
  const [branche, setBranche] = useState("0");
  const [order_validation_date, setValidation_date] = useState("");
  const [contactSelected, setContactSelected] = useState("0");
  const [origin_company, setOrigin_company] = useState("");
  const [origin_country, setOrigin_country] = useState("");
  const [origin_zip_code, setOrigin_zip_code] = useState("");
  const [origin_city, setOrigin_city] = useState("");
  const [origin_adress, setOrigin_adress] = useState("");
  const [dest_company, setDest_company] = useState("");
  const [dest_country, setDest_country] = useState("");
  const [dest_zip_code, setDest_zip_code] = useState("");
  const [dest_city, setDest_city] = useState("");
  const [dest_adress, setDest_adress] = useState("");
  const [goods_nb_colis, setGoods_nb_colis] = useState(0);
  const [goods_weight, setGoods_weight] = useState(0);
  const [goods_num_track_expedition, setGoods_num_track_expedition] =
    useState("");
  const [goods_description, setGoods_description] = useState("");
  const [delivery_date, setDelivery_date] = useState("");
  const [delivery_time, setDelivery_time] = useState("");
  const [delivery_signer_name, setDelivery_signer_name] = useState("");
  const [delivery_packaging, setDelivery_packaging] = useState(0);
  const [delivery_D_and_T, setDelivery_D_and_T] = useState(0);
  const [delivery_customs, setDelivery_customs] = useState("");
  const [misc_fees_name, setMisc_fees_name] = useState("");
  const [misc_fees_amount, setMisc_fees_amount] = useState(0);
  const [order_misc_fees_comment, setOrder_misc_fees_comment] = useState("");
  const [selling_price, setSelling_price] = useState(0);
  const [purchase_price, setPurchase_price] = useState(0);
  const [tva, setTva] = useState(0);

  //handles new subcontractors from the order form
  const [subcontractor, setSubcontractor] = useState([]);

  //datas from the database
  const sous_traitants = data_subcontractors;
  const [branches, setBranches] = useState([]);
  const [contacts, setContacts] = useState([]);
  const nb_sous_traitants = [
    { id: 1, nombre: 1 },
    { id: 2, nombre: 2 },
    { id: 3, nombre: 3 },
    { id: 4, nombre: 4 },
    { id: 5, nombre: 5 },
  ];

  //form checking variables
  //required selectBox
  const selectBoxFields = ["client", "nombre_sous_traitants", "branche"];

  //required inputs
  const required_input =
    "nombre_sous_traitants" +
    " date_validation" +
    " pays_societe_origine " +
    "code_postal_societe_origine " +
    "ville_societe_origine " +
    " pays_destination" +
    " code_postal_destination" +
    " ville_destination" +
    " nbs_colis" +
    " poids" +
    // " cout" +
    " prix_vente" +
    " prix_achat" +
    " tva" +
    // " client" +
    " branche";

  //reference to search city api
  const origin_city_controller = useRef();
  const dest_city_controller = useRef();

  //handle update command form submitting
  const [isSubmittingUpdateCommand, setIsSubmittingUpdateCommand] =
    useState(false);

  //useEffects and methods

  //handle subcontractors'number changes
  useEffect(() => {
    let add = {
      order_st_st_id: "",
      order_st_cost: 0,
      order_st_ref: "",
      order_st_user_id_create: idUser,
      order_st_user_id_update: idUser,
    };
    var all_sub = [];
    if (nombre_sous_traitants > subcontractor.length) {
      // add at list
      for (let i = 0; i < nombre_sous_traitants - subcontractor.length; i++) {
        all_sub.push(add);
      }
      setSubcontractor((e) => [...e, ...all_sub]);
      setErrorSub((e) => [
        ...e,
        ...all_sub.map(() => ({
          order_st_cost: "",
          order_st_ref: "",
          order_st_st_id: "",
        })),
      ]);
    } else if (nombre_sous_traitants < subcontractor.length) {
      // remove at list
      setSubcontractor((e) => e.filter((d, i) => i < nombre_sous_traitants));
      setErrorSub((e) => e.filter((d, i) => i < nombre_sous_traitants));
    }
  }, [nombre_sous_traitants]);

  //handles subcontractors'selected changes
  const handleChangeSubstractor = (data, index) => {
    setErrorSub((e) =>
      e.map((d, i) => ({
        ...d,
        order_st_st_id:
          i == index
            ? parseInt(data) > 0
              ? ""
              : "Champ non-vide"
            : d.order_st_st_id,
      }))
    );
    setSubcontractor((e) =>
      e.map((d, i) => ({
        ...d,
        order_st_st_id: i == index ? parseInt(data) : d?.order_st_st_id,
      }))
    );
  };

  //handles subcontractors'cost changes
  const handleChangeSubCost = (data, index) => {
    setSubcontractor((e) =>
      e.map((d, i) => ({
        ...d,
        order_st_cost:
          i == index
            ? isNaN(data)
              ? d?.order_st_cost
              : data
            : d?.order_st_cost,
      }))
    );
  };

  //handles subcontractors'reference changes
  const handleChangeSubRef = (data, index) => {
    setSubcontractor((e) =>
      e.map((d, i) => ({
        ...d,
        order_st_ref: i == index ? data : d?.order_st_ref,
      }))
    );
  };

  //get all branch from client id
  const getAllClientBranches = async (client) => {
    try {
      const { loading, data, error } = await FetchData(
        `/api/client/branch/client/${client}`,
        {
          method: "GET",
        }
      );
      setBranches(data.data);
      return { loading, data, error };
    } catch (error) {
      return [];
    }
  };

  //get all contact from branch id
  const getAllBranchContacts = async (client) => {
    try {
      const { loading, data, error } = await FetchData(
        `/api/client/contact/branch/${client}`,
        {
          method: "GET",
        }
      );
      setContacts(data.data);
      return { loading, data, error };
    } catch (error) {
      return [];
    }
  };

  //handle when client selected changes
  const handleClientSelection = (e) => {
    setClient(e);
    if (parseInt(e) > 0) {
      setContacts([]);
      getAllClientBranches(e);
      setBranche("0");
      setContactSelected("");
      setContact(null);
    }
  };

  //handle when branch selected changes
  const handleBranchSelection = (e) => {
    setBranche(e);
    if (parseInt(e) > 0) {
      setContacts([]);
      setContact(null);
      getAllBranchContacts(e);
      setContactSelected("");
    }
  };

  const getContact = async (id) => {
    try {
      const { loading, data, error } = await FetchData(
        `/api/client/contact/${id}`,
        {
          method: "GET",
        }
      );
      setContact(data.data[0]);
      return { loading, data, error };
    } catch (error) {
      return null;
    }
  };

  //handles when contact selected changes
  const handleContactSelection = (e) => {
    setContactSelected(e);
    if (parseInt(e) > 0) {
      getContact(e);
    }
  };

  function inputIsEmptyError(key) {
    const form = new FormData(formRef.current);
    let isEmpty = false;
    if (required_input.includes(key)) {
      if (form.get(key) == "" || !form.get(key)) {
        isEmpty = true;
      }
    }
    return isEmpty;
  }
  //components functions
  const testInputValueOnSubmit = () => {
    let validForm = true;
    const form = new FormData(formRef.current);
    let errors = error_message;
    const key_list = form.keys();
    for (const key of key_list) {
      if (inputIsEmptyError(key)) {
        errors[key] = "Champ non-vide";
        errors = { ...errors, key: "Champ non-vide" };
        validForm = false;
      } else {
        errors[key] = "";
      }
    }

    if (goods_nb_colis <= 0) {
      errors = { ...errors, nbs_colis: "Valeur 0 non-acceptée" };
      validForm = false;
    } else if (!goods_nb_colis) {
      errors = { ...errors, nbs_colis: "Champ non-vide" };
      validForm = false;
    } else {
      errors = { ...errors, nbs_colis: "" };
    }

    if (goods_weight <= 0) {
      errors = { ...errors, poids: "Valeur 0 non-acceptée" };
      validForm = false;
    } else if (!goods_weight) {
      errors = { ...errors, poids: "Champ non-vide" };
      validForm = false;
    } else {
      errors = { ...errors, poids: "" };
    }

    if (selling_price <= 0) {
      errors = { ...errors, prix_vente: "Valeur 0 non-acceptée" };
      validForm = false;
    } else if (!selling_price) {
      errors = { ...errors, prix_vente: "Champ non-vide" };
      validForm = false;
    } else {
      errors = { ...errors, prix_vente: "" };
    }

    selectBoxFields.forEach((field) => {
      if (!form.get(field) && form.get(field) === null) {
        errors[field] = "Champ non-vide";
        errors = { ...errors, key: "Champ non-vide" };
        validForm = false;
      } else {
        errors[field] = "";
      }
    });

    if (parseInt(nombre_sous_traitants) < 1) {
      errors = { ...errors, nombre_sous_traitants: "Au moins 1 sous-traitant" };
      validForm = false;
    } else {
      errors = { ...errors, nombre_sous_traitants: "" };
    }

    let newErrorSub = errorSub;
    for (let i = 0; i < subcontractor.length; i++) {
      if (!subcontractor[i]["order_st_st_id"]) {
        newErrorSub[i]["order_st_st_id"] = "Champ obligatoire";
        validForm = false;
      } else {
        newErrorSub[i]["order_st_st_id"] = "";
      }

      if (!subcontractor[i]["order_st_cost"]) {
        newErrorSub[i]["order_st_cost"] = "Champ obligatoire";
        validForm = false;
      } else {
        newErrorSub[i]["order_st_cost"] = "";
      }
    }

    setErrorSub(newErrorSub);
    setError_message(errors);

    return validForm;
  };

  //

  const submitOrderForm = async (e) => {
    e.preventDefault();
    setIsSubmittingUpdateCommand(true);

    if (testInputValueOnSubmit()) {
      let new_order = order;
      new_order = {
        ...new_order,
        order_client_id: parseInt(e.target.client.value),
        order_client_ref: ref_client,
        order_contact_id: contactSelected == "" ? null : contactSelected,
        order_branch_id: parseInt(e.target.branche.value),
        order_origin_company:
          e.target.nom_societe_origine.value == ""
            ? null
            : e.target.nom_societe_origine.value,
        order_origin_country: e.target.pays_societe_origine.value,
        order_origin_zip_code: e.target.code_postal_societe_origine.value,
        order_origin_city: e.target.ville_societe_origine.value,
        order_origin_adress: e.target.adresse_origine.value,
        order_dest_company:
          e.target.nom_societe_destination.value == ""
            ? null
            : e.target.nom_societe_destination.value,
        order_dest_country: e.target.pays_destination.value,
        order_dest_zip_code: e.target.code_postal_destination.value,
        order_dest_city: e.target.ville_destination.value,
        order_dest_adress: e.target.adresse_destination.value,
        order_goods_nb_colis: parseInt(e.target.nbs_colis.value),
        order_goods_weight: parseFloat(e.target.poids.value),
        order_goods_num_track_expedition:
          e.target.num_tracking.value == ""
            ? null
            : e.target.num_tracking.value,
        order_goods_description: e.target.order_goods_description.value,
        order_delivery_date:
          e.target.date_livraison.value == ""
            ? null
            : e.target.date_livraison.value,
        order_delivery_time:
          e.target.heure_livraison.value == ""
            ? null
            : e.target.heure_livraison.value,
        order_delivery_signer_name:
          e.target.nom_signataire.value == ""
            ? null
            : e.target.nom_signataire.value,
        order_delivery_packaging: parseFloat(e.target.emballage.value),
        order_delivery_D_and_T: parseFloat(e.target.dt.value),
        order_delivery_customs: parseFloat(e.target.douane.value),
        order_misc_fees_name:
          e.target.nom_frais_divers.value == ""
            ? null
            : e.target.nom_frais_divers.value,
        order_misc_fees_amount: parseFloat(e.target.montant_frais_divers.value),
        order_misc_fees_comment:
          e.target.order_misc_fees_comment.value == ""
            ? null
            : e.target.order_misc_fees_comment.value,
        order_purchase_price: parseFloat(purchase_price),
        order_selling_price: parseFloat(e.target.prix_vente.value),
        order_tva: parseFloat(e.target.tva.value),
        order_user_id_create: idUser,
        order_user_id_update: idUser,
        sous_traitant: subcontractor,
        order_validation_date: order_validation_date,

        req_client_contact_id: contactSelected == "" ? null : contactSelected,
        req_client_email_contact: client_email_contact,
      };

      try {
        const url = `/api/order/${order.order_id}`;
        const options = {
          method: "PUT",
          body: JSON.stringify(new_order),
        };
        const message = {
          title: "Succès",
          text: "Modification de la commande effectuée.",
        };
        const { data } = await FetchData(url, options);
        if (data.status === 200) {
          Swal.fire({
            ...message,
            icon: "success",
            position: "center",
            showConfirmButton: false,
            timer: 3000,
          });
          getAllOrders();
          getAllNeededData();
          resetError();
        } else {
          Swal.fire({
            title: "Erreur!",
            text: data.error,
            icon: "error",
            position: "center",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      } catch (error) {
        console.error("Erreur lors de la connexion:", error);
      }
    } else {
      console.error("Erreur lors de la vérification du formulaire");
    }
    setIsSubmittingUpdateCommand(false);
  };

  function handlePurchasePriceChange() {
    let sum = 0;
    sum += isNaN(parseFloat(misc_fees_amount))
      ? 0
      : parseFloat(misc_fees_amount);
    subcontractor.forEach((p) => {
      sum += isNaN(sum + parseFloat(p.order_st_cost))
        ? 0
        : parseFloat(p.order_st_cost);
    });
    setPurchase_price(sum);
  }

  useEffect(() => {
    handlePurchasePriceChange();
  }, [subcontractor, misc_fees_amount]);

  useEffect(() => {
    setClients(data_clients);
  }, [data_clients]);

  useEffect(() => {
    if (isUpdate && JSON.stringify(order) !== "{}") {
      setRef_client(order?.order_client_ref);
      if (order?.order_client_id) {
        handleClientSelection(order?.order_client_id);
        setBranche(`${order?.order_branch_id}`);
      }
      if (order?.order_branch_id) {
        handleBranchSelection(order?.order_branch_id);
      }
      if (order?.order_contact_id) {
        handleContactSelection(order?.order_contact_id);
      }
      setValidation_date(order?.order_validation_date);

      setOrigin_company(
        `${
          order?.order_origin_company != null ? order?.order_origin_company : ""
        }`
      );
      setOrigin_country(order?.order_origin_country);
      setOrigin_zip_code(`${order?.order_origin_zip_code}`);
      setOrigin_city(`${order?.order_origin_city}`);
      setOrigin_adress(`${order?.order_origin_adress}`);
      setDest_company(
        `${order?.order_dest_company != null ? order?.order_dest_company : ""}`
      );
      setDest_country(order?.order_dest_country);
      setDest_zip_code(`${order?.order_dest_zip_code}`);
      setDest_city(`${order?.order_dest_city}`);
      setDest_adress(`${order?.order_dest_adress}`);
      setGoods_nb_colis(`${order?.order_goods_nb_colis}`);
      setGoods_weight(`${order?.order_goods_weight}`);
      setGoods_num_track_expedition(
        `${
          order?.order_goods_num_track_expedition != null
            ? order?.order_goods_num_track_expedition
            : ""
        }`
      );
      setGoods_description(
        order?.order_goods_description == null
          ? ""
          : order?.order_goods_description
      );
      setDelivery_date(
        order?.order_delivery_date != null ? order?.order_delivery_date : ""
      );
      setDelivery_time(
        `${
          order?.order_delivery_time == null ? "" : order?.order_delivery_time
        }`
      );
      setDelivery_signer_name(
        `${
          order?.order_delivery_signer_name != null
            ? order?.order_delivery_signer_name
            : ""
        }`
      );
      setDelivery_packaging(
        `${
          order?.order_delivery_packaging != null
            ? order?.order_delivery_packaging
            : ""
        }`
      );
      setDelivery_D_and_T(
        `${
          order?.order_delivery_D_and_T != null
            ? order?.order_delivery_D_and_T
            : 0
        }`
      );
      setDelivery_customs(
        `${
          order?.order_delivery_customs != null
            ? order?.order_delivery_customs
            : 0
        }`
      );
      setMisc_fees_name(
        `${
          order?.order_misc_fees_name != null ? order?.order_misc_fees_name : ""
        }`
      );
      setMisc_fees_amount(
        `${
          order?.order_misc_fees_amount != null
            ? order?.order_misc_fees_amount
            : 0
        }`
      );
      setOrder_misc_fees_comment(
        `${
          order?.order_misc_fees_comment != null
            ? order?.order_misc_fees_comment
            : ""
        }`
      );
      setSelling_price(`${order?.order_selling_price}`);
      setPurchase_price(`${order?.order_purchase_price}`);
      setTva(`${order?.order_tva}`);

      if (order?.sous_traitant.length > 0) {
        setSubcontractor((subcontractor) =>
          order?.sous_traitant.map((d) => ({
            ...d,
            order_st_ref:
              order?.order_st_ref !== null ? order?.order_st_ref : "",
          }))
        );
        setErrorSub((e) => [
          ...e,
          ...order?.sous_traitant.map(() => ({
            error_cost: "",
            error_ref: "",
            error_sub: "",
          })),
        ]);
        setNombre_sous_traitants(order?.sous_traitant.length);
      }
    } else {
      setClient(`0`);
      setRef_client("");
      getAllClientBranches(`0`);
      setBranche(`0`);
      getAllBranchContacts(`0`);
      setValidation_date(``);
      setContactSelected(``);
      setContacts([]);
      setContact(null);
      setOrigin_company(``);
      setOrigin_country(``);
      setOrigin_zip_code(``);
      setOrigin_city(``);
      setOrigin_adress(``);
      setDest_company(``);
      setDest_country(``);
      setDest_zip_code(``);
      setDest_city(``);
      setDest_adress(``);
      setGoods_nb_colis(0);
      setGoods_weight(0);
      setGoods_num_track_expedition(``);
      setGoods_description(``);
      setDelivery_date(``);
      setDelivery_time(``);
      setDelivery_signer_name(``);
      setDelivery_packaging(0);
      setDelivery_customs(``);
      setMisc_fees_name(``);
      setMisc_fees_amount(0);
      setOrder_misc_fees_comment(``);
      setSelling_price(0);
      setPurchase_price(0);
      setTva(0);
    }
  }, [isUpdate]);

  const search_city = (
    value,
    setNewValue = () => {},
    target_country = 0,
    target_zipcode = "",
    city_owner = "order_origin"
  ) => {
    switch (city_owner) {
      case "order_origin":
        origin_city_controller.current = SearchCity(
          target_country,
          target_zipcode,
          setOrigin_city,
          origin_city_controller.current,
          data_country_list
        );
        break;
      case "order_dest":
        dest_city_controller.current = SearchCity(
          target_country,
          target_zipcode,
          setDest_city,
          dest_city_controller.current,
          data_country_list
        );
        break;
    }

    setNewValue(value);
  };

  //what is really visible
  return (
    <Form
      formData={{
        type: "form",
        id: "updateCommand",
        formMethod: "post",
        formAction: "#",
        formClass: "",
      }}
      submitFunction={submitOrderForm}
      formRef={formRef}
    >
      {error_list.length > 0 ? (
        <div className="p-3 ps-4">
          <div className=" danger bg-danger-subtle border-start border-danger border-3 rounded rounded-1 p-2">
            <h2 className="fs-6">
              <i className="fa-solid fa-circle-exclamation text-danger me-1 "></i>
              Erreurs
            </h2>
            <ul>
              {error_list.map((d, index) => (
                <li className="input-label-size " key={"error_" + index}>
                  {d}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
      <FormSection
        formSectionData={{
          type: "section",
          title: "Général",
          sectionClass: "border-bottom",
        }}
      >
        <div className="d-flex flex-row">
          <SelectBoxSearch
            type="select"
            blockClass="mb-3 me-2 col"
            id="client"
            labelClass=""
            label="Client"
            selectClass="input-label-size "
            isRequired={true}
            defaultValue={client}
            errorMessage={error_message["client"]}
            selectOptions={clients}
            onChange={(e) => handleClientSelection(e)}
            cle="client_id"
            value="client_company_name"
            placeholder="Client"
          />
          <Input
            type="text"
            blockClass="mb-3 me-2 col"
            id="refClient"
            labelClass=""
            label="Référence client"
            placeholder="Référence client"
            inputClass=""
            value={ref_client}
            isRequired={false}
            isDisabled={false}
            onChange={setRef_client}
            errorMessage=""
          />
          <SelectBox
            type="select"
            blockClass="mb-3 me-2 col"
            id="branche"
            labelClass=""
            label="Branche"
            selectClass="input-label-size "
            isRequired={true}
            selectOptions={branches}
            cle="cb_id"
            value="cb_name"
            placeholder="Branche"
            value_selected={branche}
            errorMessage={error_message["branche"]}
            onChange={(e) => handleBranchSelection(e)}
          />
        </div>
        <Input
          type="date"
          blockClass="mb-3 me-2 col-6 col-sm-4"
          id="date_validation"
          labelClass=""
          label="Date de validation"
          inputClass="custom-input-colors"
          isRequired={true}
          isDisabled={false}
          value={order_validation_date}
          onChange={setValidation_date}
          errorMessage={error_message["date_validation"]}
        />
      </FormSection>
      <FormSection
        formSectionData={{
          type: "section",
          title: "Contact",
          sectionClass: "border-bottom",
        }}
      >
        <div className="d-flex flex-column">
          <SelectBox
            type="select"
            blockClass="col-6 col-sm-4 mb-3"
            id="contact"
            labelClass=""
            label="Choix du contact"
            selectClass="input-label-size "
            isRequired={false}
            selectOptions={contacts}
            cle="cc_id"
            value="cc_name"
            placeholder="Choix du contact"
            value_selected={contactSelected}
            errorMessage={error_message["contactSelected"]}
            onChange={(e) => handleContactSelection(e)}
          />
          {contact !== null && order?.order_contact_id !== null ? (
            <div className="d-flex flex-row">
              <Input
                type="text"
                blockClass="mb-3 me-2 col"
                id="contact_poste"
                labelClass="text-muted text-secondary"
                label="Poste"
                inputClass="custom-input-colors text-secondary"
                placeholder="Poste du contact"
                isRequired={false}
                isDisabled={true}
                errorMessage={""}
                value={contact !== null ? contact?.cc_post : ""}
              />
              <Input
                type="text"
                blockClass="mb-3 me-2 col"
                id="contact_mail"
                labelClass="text-muted text-secondary"
                label="E-mail"
                inputClass="custom-input-colors"
                placeholder="E-mail du contact"
                isRequired={false}
                isDisabled={false}
                errorMessage={""}
                value={
                  client_email_contact?.length > 0
                    ? client_email_contact
                    : contact !== null
                    ? contact?.cc_email
                    : ""
                }
                onChange={set_client_email_contact}
              />
              <Input
                type="text"
                blockClass="mb-3 me-2 col"
                id="contact_phone"
                labelClass="text-muted text-secondary"
                label="Téléphone"
                inputClass="custom-input-colors text-secondary"
                placeholder="Téléphone du contact"
                isRequired={false}
                isDisabled={true}
                errorMessage={""}
                value={
                  contact !== null
                    ? contact?.cc_phone_1 != ""
                      ? contact?.cc_phone_1
                      : contact?.cc_phone_2
                    : ""
                }
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </FormSection>

      <FormSection
        formSectionData={{
          type: "section",
          title: "Origine",
          sectionClass: "border-bottom",
        }}
      >
        <div className="d-flex flex-row">
          <Input
            type="text"
            blockClass="mb-3 me-2 col"
            id="nom_societe_origine"
            labelClass=""
            label="Nom de la société"
            placeholder="Nom de la société"
            inputClass="custom-input-colors"
            isRequired={false}
            isDisabled={false}
            value={origin_company}
            onChange={setOrigin_company}
            errorMessage=""
          />
        </div>
        <div className="d-flex flex-row">
          <SelectBoxSearch
            type="select"
            blockClass="mb-3 me-2 col"
            id="pays_societe_origine"
            labelClass=""
            label="Pays"
            selectClass="input-label-size "
            isRequired={true}
            defaultValue={origin_country}
            errorMessage={error_message["pays_societe_origine"]}
            selectOptions={data_country_list}
            onChange={(e) =>
              search_city(
                e,
                setOrigin_country,
                e,
                origin_zip_code,
                "order_origin"
              )
            }
            cle="country_id"
            value="country_name"
            placeholder="Pays"
          />
          <Input
            type="text"
            blockClass="mb-3 me-2 col"
            id="code_postal_societe_origine"
            labelClass=""
            label="Code postal"
            placeholder="Code postal"
            inputClass="custom-input-colors"
            isRequired={true}
            isDisabled={false}
            value={origin_zip_code}
            onChange={(e) =>
              search_city(
                e,
                setOrigin_zip_code,
                origin_country,
                e,
                "order_origin"
              )
            }
            errorMessage={error_message["code_postal_societe_origine"]}
            suffix=""
          />
          <Input
            type="text"
            blockClass="mb-3 me-2 col"
            id="ville_societe_origine"
            labelClass=""
            label="Ville"
            placeholder="Ville"
            inputClass="custom-input-colors"
            isRequired={true}
            isDisabled={false}
            value={origin_city}
            onChange={setOrigin_city}
            errorMessage={error_message["ville_societe_origine"]}
          />
        </div>
        <div className="d-flex flex-row">
          <Input
            type="text"
            blockClass="mb-3 me-2 col"
            id="adresse_origine"
            labelClass=""
            label="Adresse"
            placeholder="Adresse"
            inputClass="custom-input-colors"
            isRequired={false}
            isDisabled={false}
            value={origin_adress}
            onChange={setOrigin_adress}
            errorMessage={error_message["adresse_origine"]}
          />
        </div>
      </FormSection>

      <FormSection
        formSectionData={{
          type: "section",
          title: "Destination",
          sectionClass: "border-bottom",
        }}
      >
        <Input
          type="text"
          blockClass="mb-3 me-2 col"
          id="nom_societe_destination"
          labelClass=""
          label="Nom de la société"
          placeholder="Nom de la société"
          inputClass="custom-input-colors"
          isRequired={false}
          isDisabled={false}
          value={dest_company}
          onChange={setDest_company}
          errorMessage={error_message["nom_societe_destination"]}
        />
        <div className="d-flex flex-row">
          <SelectBoxSearch
            type="select"
            blockClass="mb-3 me-2 col"
            id="pays_destination"
            labelClass=""
            label="Pays"
            defaultValue={dest_country}
            selectClass="input-label-size "
            isRequired={true}
            errorMessage={error_message["pays_destination"]}
            selectOptions={data_country_list}
            onChange={(e) =>
              search_city(e, setDest_country, e, dest_zip_code, "order_dest")
            }
            cle="country_id"
            value="country_name"
            placeholder="Pays"
          />

          <Input
            type="text"
            blockClass="mb-3 me-2 col"
            id="code_postal_destination"
            labelClass=""
            label="Code postal"
            placeholder="Code postal"
            inputClass="custom-input-colors"
            isRequired={true}
            isDisabled={false}
            value={dest_zip_code}
            onChange={(e) =>
              search_city(e, setDest_zip_code, dest_country, e, "order_dest")
            }
            errorMessage={error_message["code_postal_destination"]}
            suffix=""
          />
          <Input
            type="text"
            blockClass="mb-3 me-2 col"
            id="ville_destination"
            labelClass=""
            label="Ville"
            placeholder="Ville"
            inputClass="custom-input-colors"
            isRequired={true}
            isDisabled={false}
            value={dest_city}
            onChange={setDest_city}
            errorMessage={error_message["ville_destination"]}
          />
        </div>
        <Input
          type="text"
          blockClass="mb-3 me-2 col"
          id="adresse_destination"
          labelClass=""
          label="Adresse"
          placeholder="Adresse"
          inputClass="custom-input-colors"
          isRequired={false}
          isDisabled={false}
          value={dest_adress}
          onChange={setDest_adress}
          errorMessage={error_message["adresse_destination"]}
        />
      </FormSection>
      <FormSection
        formSectionData={{
          type: "section",
          title: "Marchandises",
          sectionClass: "border-bottom",
        }}
      >
        <div className="d-flex flex-row ">
          <Input
            type="number"
            blockClass="mb-3 me-2 col"
            id="nbs_colis"
            labelClass=""
            label="Nombre de colis"
            placeholder="Nombre de colis"
            inputClass="custom-input-colors"
            isRequired={true}
            isDisabled={false}
            value={goods_nb_colis}
            onChange={setGoods_nb_colis}
            errorMessage={error_message["nbs_colis"]}
          />
          <Input
            type="number"
            blockClass="mb-3 me-2 col"
            id="poids"
            labelClass=""
            label="Poids"
            placeholder="Poids"
            inputClass="custom-input-colors"
            isRequired={true}
            isDisabled={false}
            errorMessage={error_message["poids"]}
            value={goods_weight}
            onChange={setGoods_weight}
            suffix="Kg"
          />
          <Input
            type="text"
            blockClass="mb-3 me-2 col"
            id="num_tracking"
            labelClass=""
            label="Numero de tracking/Expedition"
            placeholder="Numero de tracking/Expedition"
            inputClass="custom-input-colors"
            isRequired={false}
            isDisabled={false}
            value={goods_num_track_expedition}
            onChange={setGoods_num_track_expedition}
            errorMessage=""
          />
        </div>
        <TextArea
          blockClass={"mb-3 me-2 col"}
          id={"order_goods_description"}
          labelClass={""}
          label={"Descriptif"}
          inputClass=""
          placeholder="Descriptif"
          isRequired={false}
          value={goods_description}
          onChange={setGoods_description}
        />
      </FormSection>
      <FormSection
        formSectionData={{
          type: "section",
          title: "Livraison",
          sectionClass: "border-bottom",
        }}
      >
        <div className="d-flex flex-row">
          <Input
            type="date"
            blockClass="mb-3 me-2 col-4"
            id="date_livraison"
            labelClass=""
            label="Date de livraison"
            placeholder="Date de livraison"
            inputClass="custom-input-colors"
            isRequired={false}
            isDisabled={false}
            value={delivery_date}
            onChange={setDelivery_date}
            errorMessage={error_message["date_livraison"]}
          />
          <Input
            type="time"
            blockClass="mb-3 me-2 col-4"
            id="heure_livraison"
            labelClass=""
            label="Heure de livraison"
            placeholder="Heure de livraison"
            inputClass="custom-input-colors"
            isRequired={false}
            isDisabled={false}
            value={delivery_time}
            onChange={setDelivery_time}
            errorMessage={error_message["heure_livraison"]}
          />
        </div>
        <Input
          type="text"
          blockClass="mb-3 me-2 col"
          id="nom_signataire"
          labelClass=""
          label="Nom de signataire"
          placeholder="Nom de signataire"
          inputClass="custom-input-colors"
          isRequired={false}
          isDisabled={false}
          value={delivery_signer_name}
          onChange={setDelivery_signer_name}
          errorMessage=""
        />
        <div className="d-flex flex-row">
          <Input
            type="number"
            blockClass="mb-3 me-2 col"
            id="emballage"
            labelClass=""
            label="Emballage"
            placeholder="Emballage"
            inputClass="custom-input-colors"
            isRequired={false}
            isDisabled={false}
            value={delivery_packaging}
            onChange={setDelivery_packaging}
            errorMessage={error_message["delivery_packaging"]}
          />
          <Input
            type="number"
            blockClass="mb-3 me-2 col"
            id="dt"
            labelClass=""
            label="D&T"
            placeholder="D&T"
            inputClass="custom-input-colors"
            isRequired={false}
            isDisabled={false}
            value={delivery_D_and_T}
            onChange={setDelivery_D_and_T}
            errorMessage={error_message["d_and_t"]}
          />
          <Input
            type="text"
            blockClass="mb-3 me-2 col"
            id="douane"
            labelClass=""
            label="Douane"
            placeholder="Douane"
            inputClass="custom-input-colors"
            isRequired={false}
            isDisabled={false}
            value={delivery_customs}
            onChange={setDelivery_customs}
            errorMessage=""
          />
        </div>
      </FormSection>

      <FormSection
        formSectionData={{
          type: "section",
          title: "Sous traitants",
          sectionClass: "border-bottom",
        }}
      >
        <SelectBox
          type="select"
          blockClass="mb-3 me-2 col-4"
          id="nombre_sous_traitants"
          labelClass=""
          label="Nombre de sous traitants"
          selectClass="input-label-size "
          isRequired={true}
          selectOptions={nb_sous_traitants}
          value_selected={nombre_sous_traitants}
          placeholder="Nombre de sous traitants"
          cle="id"
          value="nombre"
          errorMessage={error_message["nombre_sous_traitants"]}
          onChange={setNombre_sous_traitants}
        />
        {subcontractor.map((item_s, index) => (
          <div className="d-flex flex-row" key={"sub-" + index}>
            <SelectBox
              type="select"
              blockClass="mb-3 me-2 col"
              id={`subcontractor_id_${index + 1}`}
              labelClass=""
              label={`Sous traitants ${index + 1}`}
              selectClass="input-label-size "
              isRequired={true}
              selectOptions={sous_traitants}
              cle="st_id"
              value="st_company_name"
              placeholder={`Sous traitants ${index + 1}`}
              value_selected={item_s?.order_st_st_id}
              errorMessage={errorSub[index][`order_st_st_id`]}
              onChange={(e) => {
                handleChangeSubstractor(e, index);
              }}
            />
            <Input
              type="number"
              blockClass="mb-3 me-2 col"
              id={`order_st_cost_id_${index}`}
              labelClass=""
              label="Coût"
              inputClass="custom-input-colors"
              placeholder="Coût"
              isRequired={true}
              isDisabled={false}
              value={item_s?.order_st_cost}
              errorMessage={errorSub[index][`order_st_cost`]}
              onChange={(e) => {
                handleChangeSubCost(e, index);
              }}
            />
            <Input
              type="text"
              blockClass="mb-3 me-2 col"
              id={`order_st_ref_id_${index}`}
              labelClass=""
              label="Référence"
              inputClass="custom-input-colors"
              placeholder="Référence"
              isRequired={false}
              isDisabled={false}
              value={item_s?.order_st_ref}
              errorMessage={errorSub[index][`order_st_ref`]}
              onChange={(e) => {
                handleChangeSubRef(e, index);
              }}
              suffix=""
            />
          </div>
        ))}
      </FormSection>

      <FormSection
        formSectionData={{
          type: "section",
          title: "Frais divers",
          sectionClass: "border-bottom",
        }}
      >
        <div className="d-flex flex-row">
          <Input
            type="text"
            blockClass="mb-3 me-2 col-4"
            id="nom_frais_divers"
            labelClass=""
            label="Nom frais divers"
            placeholder="Nom frais divers"
            inputClass="custom-input-colors"
            isRequired={false}
            isDisabled={false}
            value={misc_fees_name}
            onChange={setMisc_fees_name}
            errorMessage=""
          />
          <Input
            type="number"
            blockClass="mb-3 me-2 col-4"
            id="montant_frais_divers"
            labelClass=""
            label="Montant"
            placeholder="Montant"
            inputClass="custom-input-colors"
            isRequired={false}
            isDisabled={false}
            value={misc_fees_amount}
            onChange={setMisc_fees_amount}
            errorMessage={error_message["misc_fees_amount"]}
          />
        </div>
        <TextArea
          blockClass={"mb-3 me-2 col"}
          id={"order_misc_fees_comment"}
          labelClass={""}
          label={"Commentaire"}
          inputClass=""
          placeholder="Commentaire"
          isRequired={false}
          value={order_misc_fees_comment}
          onChange={setOrder_misc_fees_comment}
        />
      </FormSection>
      <FormSection formSectionData={{ type: "section", title: "Prix" }}>
        <div className="d-flex flex-row">
          <Input
            type="number"
            blockClass="mb-3 me-2 col-4"
            id="prix_vente"
            labelClass=""
            label="Prix de vente"
            placeholder="Prix de vente"
            inputClass="custom-input-colors"
            isRequired={true}
            isDisabled={false}
            value={selling_price}
            onChange={setSelling_price}
            errorMessage={error_message["prix_vente"]}
          />
          <Input
            type="number"
            blockClass="mb-3 me-2 col-4"
            id="prix_achat"
            labelClass=""
            label="Prix d'achat"
            placeholder="Prix d'achat"
            inputClass="custom-input-colors"
            isRequired={true}
            isDisabled={true}
            value={purchase_price}
            errorMessage={error_message["prix_achat"]}
          />
        </div>
        <Input
          type="number"
          blockClass="mb-3 me-2 col"
          id="tva"
          labelClass=""
          label="TVA"
          placeholder="TVA"
          inputClass="custom-input-colors"
          isRequired={true}
          isDisabled={false}
          value={tva}
          onChange={setTva}
          errorMessage={error_message["tva"]}
          suffix="%"
        />
      </FormSection>

      <div className={"d-flex justify-content-end mt-2 mb-- modal-footer"}>
        <FormButton
          buttonType="button"
          buttonValue="Annuler"
          buttonClass="btn text-secondary input-label-size me-2"
          hasIcon={true}
          iconClass="far fa-times-circle me-2 bg-light"
          closeModal={true}
          buttonOnClick={resetError}
        />

        <FormButton
          buttonType="submit"
          buttonValue="Valider les modifications"
          buttonClass="btn green-button-bsl input-label-size me-2"
          hasIcon={true}
          iconClass="fas fa-check me-2"
          closeModal={false}
          disable={isSubmittingUpdateCommand}
        />
      </div>
    </Form>
  );
};

export default UpdateCommandeForm;
