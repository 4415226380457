import { FetchData } from "./FetchData";

export const SearchCity = (
  selected_country = 0,
  input_zipcode = "",
  setCity = () => {},
  controller,
  country_list = []
) => {
  const country =
    country_list?.length > 0 && selected_country && selected_country > 0
      ? country_list?.filter((data) => data?.country_id === selected_country)[0]
          ?.country_name ?? ""
      : "";
  if (country.length > 0 && input_zipcode.length > 0) {
    if (controller) {
      controller.abort();
    }
    controller = new AbortController();
    const signal = controller.signal;
    FetchData("/api/external/city", {
      method: "POST",
      body: JSON.stringify({
        country: country,
        postalcode: input_zipcode,
      }),
      signal,
    }).then((result) => {
      if (result.errors === null) {
        setCity(result.data.data);
      }
    });
  }
  return controller;
};
