import React from "react";

const FormButton = ({
  buttonType = "buttonGroups",
  buttonValue = "Envoyer",
  buttonClass = "",
  hasIcon = false,
  iconClass = "",
  closeModal = false,
  iconPlace = "",
  buttonOnClick = function () {
    return null;
  },
  disable = false,
}) => {
  return (
    /* <div className="d-flex flex-row justify-content-end w-100"> */
    <button
      type={buttonType}
      className={buttonClass}
      data-bs-dismiss={closeModal == true ? "modal" : ""}
      onClick={buttonOnClick}
      disabled={disable}
      style={disable ? { cursor: "not-allowed" } : {}}
    >
      {hasIcon == true && iconPlace !== "right" ? (
        <i className={iconClass}></i>
      ) : null}
      {buttonValue}
      {hasIcon == true && iconPlace === "right" ? (
        <i className={iconClass}></i>
      ) : null}
    </button>
  );
};

export default FormButton;
