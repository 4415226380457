import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import Cookies from "js-cookie";
import { FetchData } from "./hooks/FetchData";
const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const userDataString = Cookies.get("userData");
    return userDataString ? JSON.parse(userDataString) : null;
  });

  const logout = useCallback(() => {
    Cookies.remove("userData");
    Cookies.remove("access");
    Cookies.remove("token");
    localStorage.clear();
    setUser(null);
  }, []);

  // const getUser = () => {
  //   const { loading, data, errors } = useFetch("/api/user/user-data", {
  //     method: "GET",
  //   });
  //   return { loading, data, errors };
  // };

  const fetchUserData = useCallback(async () => {
    const token = Cookies.get("token");
    if (!token) {
      logout();
      return;
    }
    try {
      // const { loading, data, errors } = getUser();
      // console.log(data);

      const response = await fetch("/api/user/user-data", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const { data } = await response.json();
        setUser((currentUser) => {
          if (JSON.stringify(currentUser) !== JSON.stringify(data)) {
            return data;
          }
          return currentUser;
        });
        Cookies.set("userData", JSON.stringify(data));
      } else {
        logout();
      }
    } catch (error) {
      logout();
    }
  }, [logout]); // Ajoutez logout comme dépendance puisqu'elle est utilisée dans fetchUserData

  const value = { user, logout, fetchUserData };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
