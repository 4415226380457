import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  date_range: {
    endDate: new Date().toISOString().split("T")[0],
    startDate: new Date().toISOString().split("T")[0],
  },
  date_init: {
    endDate: new Date().toISOString().split("T")[0],
    startDate: new Date().toISOString().split("T")[0],
  },
};
const dateRangeSlice = createSlice({
  name: "dateRange",
  initialState,
  reducers: {
    initDateRange: (state, action) => {
      state.date_range = {
        endDate: action.payload.endDate,
        startDate: action.payload.startDate,
      };
    },
    resetDateRange: (state, action) => {
      state.date_range = {
        endDate: action.payload.endDate,
        startDate: action.payload.startDate,
      };
      state.date_init = {
        endDate: action.payload.endDate,
        startDate: action.payload.startDate,
      };
    },
  },
});

export const { initDateRange, resetDateRange } = dateRangeSlice.actions;
export default dateRangeSlice.reducer;
