import React from "react";
import "../../styles/Form/input.css";

const ListItemContents = ({ label = "", value = "", contentClass = "" }) => {
  return (
    <div className={` ${contentClass}`}>
      <p className="p-0 m-0 ps-2 text-muted" style={{ fontSize: "0.7rem" }}>
        {label}
      </p>
      <p className=" input-label-size p-0 ps-2 m-0 text-wrap ">
        {value != "" && value != null ? value : "-"}
      </p>
    </div>
  );
};

export default ListItemContents;
