import React, { useCallback, useEffect, useState, useRef } from "react";
import Form from "../../../Form/Form";
import ModalForAddNew from "../../ModalForAddNew";
import FormSection from "../../../Form/FormSection";
import FormButton from "../../../Form/FormButton";
import SelectBox from "../../../Form/SelectBox";
import SelectBoxSearch from "../../../Form/SelectBoxSearch";
import Input from "../../../Form/Input";
import Swal from "sweetalert2";
import ErrorList from "../../../Form/ErrorList";

//a change dans un autre composant
import "../../../../styles/Form/form.css";
import InputFile from "../../../Form/InputFile";
import { FetchData } from "../../../../hooks/FetchData";
import TextArea from "../../../Form/TextArea";
import FormCard from "../../../Form/FormCard";
import ListGroup from "../../../Form/ListGroup";
import ListItemContents from "../../../Form/ListItemContents";
import ListItems from "../../../Form/ListItems";
import HeaderModal from "./HeaderModal";
import Cookies from "js-cookie";
import ShowLogDemande from "./ShowLogDemande";
import { useAccess } from "../../../../hooks/useAccess";
import { SearchCity } from "../../../../hooks/SearchCity";

const AddDevis = ({
  demande = {},
  modalData = {},
  isShowModal = false,
  handleCloseModal = function () {},
  data_list = {},
  idUser = 0,
  getData = () => {},
  data_country_list = [],
  data_log = [],
}) => {
  //Permissions
  const can_update_open_request_infos = useAccess(37);
  const can_see_request_log = useAccess(74);

  const form_data = {
    type: "form",
    id: "addDevis",
    formClass: "",
  };
  const [logData, setLogData] = useState([]);
  useEffect(() => {
    setLogData(data_log);
    return;
  }, [data_log]);

  const formRef = useRef(null);

  //handle scroll to top if errors
  const modalBodyAddDevis = useRef(null);

  const [activeItem, setActiveItem] = useState(0);

  const [isUpdate, setIsUpdate] = useState(false);
  const [_demande, set_demande] = useState({});

  const [req_client_email_contact, set_req_client_email_contact] = useState("");

  //handle submit request form
  const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);

  const carousel_log = can_see_request_log
    ? [{ number: 4, isActive: false, indicatorPosition: "100" }]
    : [];

  const [carouselItems, setCarouselItems] = useState([
    { number: 1, isActive: true, indicatorPosition: "25" },
    { number: 2, isActive: false, indicatorPosition: "50" },
    { number: 3, isActive: false, indicatorPosition: "79" },
    ...carousel_log,
  ]);
  const [client_list, set_client_list] = useState([]);
  const [agent_list, set_agent_list] = useState([]);

  const handleChangeBranch = async (id) => {
    const { loading, data, errors } = await FetchData(
      "/api/client/contact/branch/" + id,
      {
        method: "GET",
      }
    );

    set_req_client_banch_id(id);
    set_contact(data.data);
    set_req_client_contact_id(demande?.req_client_contact_id);
  };

  const handleChangeClient = async (id) => {
    const { loading, data, errors } = await FetchData(
      "/api/client/branch/client/" + id,
      {
        method: "GET",
      }
    );

    set_req_client_id(id);

    set_branch(data.data);

    set_req_client_banch_id(0);
    set_req_client_contact_id(0);
    set_contact([]);

    getDate();
    setError_message({
      req_date_request: "",
      heureDevis: "",
    });
  };

  const handleChangeAgentResponsable = async (id) => {
    const { loading, data, errors } = await FetchData("/api/request/assign", {
      method: "POST",
      body: JSON.stringify({
        rau_agent_id: id,
        rau_user_id_create: idUser,
        rau_user_id_update: idUser,
        rau_req_id: _demande?.req_id,
      }),
    });
    if (data.status === 200) {
      set_agent_responsable_id(id);
      set_agent_responsable(data.data);
      Swal.fire({
        title: "Succès",
        text: "Assignation du responsable reussie.",
        icon: "success",
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
      getData();
      resetError();
    } else {
      Swal.fire({
        title: "Erreur!",
        text: data?.error,
        icon: "error",
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    set_client_list(data_list?.client_list);
    set_agent_list(data_list?.user_agent);
  }, [data_list]);

  useEffect(() => {
    set_demande(demande);
    set_req_client_email_contact("");
    if (demande && JSON.stringify(demande) !== "{}") {
      handleChangeClient(demande?.req_client_id)
        .then((responseChangeClient) =>
          handleChangeBranch(demande?.req_client_banch_id)
        )
        .then((responseChangeBranch) => {
          set_req_title(demande?.numero);

          set_req_date_request(
            demande?.req_date_request?.split("/").reverse().join("-")
          );
          set_req_time_request(demande?.req_time_request);
          set_req_origin_company(demande?.req_origin_company);
          set_req_origin_country(demande?.req_origin_country);
          set_req_origin_zip_code(demande?.req_origin_zip_code);
          set_req_origin_city(demande?.req_origin_city);
          set_req_origin_adress(demande?.req_origin_adress);
          set_req_dest_company(demande?.req_dest_company);
          set_req_dest_country(demande?.req_dest_country);
          set_req_dest_zip_code(demande?.req_dest_zip_code);
          set_req_dest_city(demande?.req_dest_city);
          set_req_dest_adress(demande?.req_dest_adress);
          set_req_goods_nb_colis(demande?.req_goods_nb_colis);
          set_req_goods_gross_weight(demande?.req_goods_gross_weight);
          set_req_goods_volumetric_weight(demande?.req_goods_volumetric_weight);
          //les nouvelles colonnes
          set_req_goods_height(demande?.req_goods_height);
          set_req_goods_length(demande?.req_goods_length);
          set_req_goods_width(demande?.req_goods_width);
          set_req_fret_taxable_weight(demande?.req_fret_taxable_weight);
          set_req_weight_taxable_integrator(
            demande?.req_weight_taxable_integrator
          );
          set_req_goods_description(demande?.req_goods_description);
          set_req_goods_ref_dbl(demande?.req_goods_ref_dbl);
          set_req_comment(demande?.req_comment);
          set_reference_client(demande?.req_client_ref);

          let date_url = new Date(demande.req_created_at);
          date_url =
            date_url.getFullYear() +
            "-" +
            (date_url.getMonth() + 1).toString().padStart(2, "0");

          const url_email = `/api/email/${date_url}/`;
          const url_doc = `/api/document/${date_url}/`;

          set_req_file_email(
            demande?.req_file_email?.map((d) => ({
              filename: d,
              url: url_email + d,
            }))
          );

          set_req_file_image(
            demande?.req_file_image?.map((d) => ({
              filename: d,
              url: url_doc + d,
            }))
          );

          set_agent_responsable(demande?.rau_agent_id);
        })
        .catch((error) => console.error("Une erreur s'est produite : ", error));
    } else {
      resetError();
    }
  }, [demande]);

  const [contact, set_contact] = useState([]);
  const [branch, set_branch] = useState([]);
  const [nombre_colis, set_nombre_colis] = useState([
    { id: 1, value: " 1" },
    { id: 2, value: " 2" },
    { id: 3, value: " 3" },
    { id: 4, value: " 4" },
    { id: 5, value: " 5" },
    { id: 6, value: " 6" },
    { id: 7, value: " 7" },
    { id: 8, value: " 8" },
    { id: 9, value: " 9" },
    { id: 10, value: " 10" },
  ]);

  const [error_list, setError_list] = useState([]);

  const [req_client_id, set_req_client_id] = useState("");
  const [req_client_contact_id, set_req_client_contact_id] = useState("");
  const [req_client_banch_id, set_req_client_banch_id] = useState("");

  const [req_goods_nb_colis, set_req_goods_nb_colis] = useState("");
  const [agent_responsable_id, set_agent_responsable_id] = useState("");
  const [agent_responsable, set_agent_responsable] = useState("");

  const [req_title, set_req_title] = useState("");
  const [req_date_request, set_req_date_request] = useState("");
  const [req_time_request, set_req_time_request] = useState("");
  const [req_origin_company, set_req_origin_company] = useState("");
  const [req_origin_country, set_req_origin_country] = useState("");
  const [req_origin_zip_code, set_req_origin_zip_code] = useState("");
  const [req_origin_city, set_req_origin_city] = useState("");
  const [req_origin_adress, set_req_origin_adress] = useState("");
  const [req_dest_company, set_req_dest_company] = useState("");
  const [req_dest_country, set_req_dest_country] = useState("");
  const [req_dest_zip_code, set_req_dest_zip_code] = useState("");
  const [req_dest_city, set_req_dest_city] = useState("");
  const [req_dest_adress, set_req_dest_adress] = useState("");
  const [req_goods_gross_weight, set_req_goods_gross_weight] = useState(0);
  const [req_goods_description, set_req_goods_description] = useState("");
  const [req_goods_volumetric_weight, set_req_goods_volumetric_weight] =
    useState(0);
  const [req_goods_length, set_req_goods_length] = useState(0);
  const [req_goods_width, set_req_goods_width] = useState(0);
  const [req_goods_height, set_req_goods_height] = useState(0);
  const [req_fret_taxable_weight, set_req_fret_taxable_weight] = useState(0);
  const [req_weight_taxable_integrator, set_req_weight_taxable_integrator] =
    useState(0);

  const [req_goods_ref_dbl, set_req_goods_ref_dbl] = useState("");
  const [req_comment, set_req_comment] = useState("");

  const [req_file_email, set_req_file_email] = useState("");

  const [req_file_image, set_req_file_image] = useState("");

  const [reference_client, set_reference_client] = useState("");

  //Stocker les erreur
  const [error_message, setError_message] = useState({
    req_client_id: "",
    reference_client: "",
    req_date_request: "",
    heureDevis: "",
    req_origin_country: "",
    req_origin_zip_code: "",
    req_origin_city: "",
    req_origin_adress: "",
    req_dest_country: "",
    req_dest_zip_code: "",
    req_dest_city: "",
    req_dest_adress: "",
    req_goods_nb_colis: "",
    req_goods_gross_weight: "",
    req_goods_volumetric_weight: "",
    req_client_contact_id: "",
    req_client_banch_id: "",
  });

  //reference to search city controller
  const req_origin_city_controller = useRef();
  const req_dest_city_controller = useRef();

  const handleChangeEmail = async (d_file) => {
    const formData = new FormData();
    formData.append("file", d_file);
    const { loading, data, errors } = await FetchData(
      `/api/request/upload/email/${idUser}`,
      {
        method: "PUT",
        body: formData,
      },
      true
    );
    if (data.status === 200) {
      set_req_file_email((v) => [...v, data.data].filter((d) => d));
    } else {
      Swal.fire({
        title: "Erreur!",
        text: data.error,
        icon: "error",
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleChangeDocument = async (d_file) => {
    const formData = new FormData();
    formData.append("file", d_file);
    const { loading, data, errors } = await FetchData(
      `/api/request/upload/doc/${idUser}`,
      {
        method: "PUT",
        body: formData,
      },
      true
    );
    if (errors) {
      Swal.fire({
        title: "Erreur!",
        text: errors,
        icon: "error",
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      if (data.status === 200) {
        set_req_file_image((v) => [...v, data.data].filter((d) => d));
      } else {
        Swal.fire({
          title: "Erreur!",
          text: data.error,
          icon: "error",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };

  const onDeleteEmail = (name) => {
    set_req_file_email(req_file_email.filter((d) => d.filename != name));
  };

  const onDeleteDoc = (name) => {
    set_req_file_image(req_file_image.filter((d) => d.filename != name));
  };

  //les fields requierd
  const required_input =
    "  req_date_request req_time_request req_origin_zip_code req_origin_city " +
    " req_dest_zip_code req_dest_city req_goods_gross_weight";

  const required_select = ["req_goods_nb_colis"];

  const required_selectBoxSearch = [
    "req_client_id",
    "req_client_banch_id",
    "req_client_contact_id",
    "req_origin_country",
    "req_dest_country",
  ];

  const errorListLabel = {
    req_client_id: "Client",
    req_client_banch_id: "Branche",
    req_client_contact_id: "Contact",
    req_date_request: "Date de demande",
    req_time_request: "Heure",
    req_origin_country: "Origine: Pays",
    req_origin_zip_code: "Origine: Code postal",
    req_origin_city: "Origine: Ville",
    req_dest_country: "Destination: Pays",
    req_dest_zip_code: "Destination: Code postal",
    req_dest_city: "Destination: Ville",
    req_goods_nb_colis: "Nombre de colis",
    req_goods_gross_weight: "Poids brut",
  };

  const submitAddDevis = async (e) => {
    e.preventDefault();
    setIsSubmittingRequest(true);
    const newrequest = {
      req_title,
      req_client_id,
      req_client_ref: reference_client,
      req_client_contact_id,
      req_client_banch_id,
      req_date_request,
      req_time_request,
      req_origin_company,
      req_origin_country,
      req_origin_zip_code,
      req_origin_city,
      req_origin_adress,
      req_dest_company,
      req_dest_country,
      req_dest_zip_code,
      req_dest_city,
      req_dest_adress,
      req_goods_nb_colis,
      req_goods_gross_weight,
      req_goods_description,
      // req_goods_volumetric_weight,
      req_goods_height,
      req_goods_length,
      req_goods_width,
      //faut les parser
      req_fret_taxable_weight,
      req_weight_taxable_integrator,
      req_goods_ref_dbl,
      req_comment,
      req_file_email: req_file_email?.map((d) => d.filename),
      req_file_image: req_file_image?.map((d) => d.filename),
      req_user_id_create: idUser,
      req_user_id_update: idUser,
      req_client_email_contact: req_client_email_contact,
    };
    try {
      let url = "",
        options = {},
        message = {};

      if (demande?.req_id > 0) {
        url = `/api/request/${demande?.req_id}`;
        options = {
          method: "PUT",
          body: JSON.stringify(newrequest),
        };
        message = {
          title: "Succès",
          text: "Modification de la demande effectuée.",
        };
      } else {
        url = "/api/request";
        options = {
          method: "POST",
          body: JSON.stringify(newrequest),
        };
        message = { title: "Succès", text: "Création du demande effectuée." };
      }
      const { loading, data, errors } = await FetchData(url, options);
      if (data.status === 200) {
        Swal.fire({
          ...message,
          icon: "success",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
        resetError();
        getData();
      } else {
        Swal.fire({
          title: "Erreur!",
          text: data.error,
          icon: "error",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
    setIsSubmittingRequest(false);
  };

  const handleClickLog = () => {
    setActiveItem(3);
    setCarouselItems((v) => v.map((d, i) => ({ ...d, isActive: i == 3 })));
  };

  function resetError() {
    handleCloseModal();
    setError_list([]);
    setError_message({
      req_client_id: "",
      reference_client: "",
      req_date_request: "",
      heureDevis: "",
      req_origin_country: "",
      req_origin_zip_code: "",
      req_origin_city: "",
      req_origin_adress: "",
      req_dest_country: "",
      req_dest_zip_code: "",
      req_dest_city: "",
      req_dest_adress: "",
      req_goods_nb_colis: "",
      req_goods_gross_weight: "",
      req_goods_volumetric_weight: "",
      req_client_contact_id: "",
      req_client_banch_id: "",
    });
    set_req_file_email([]);
    set_req_file_image([]);
    setActiveItem(0);
    setCarouselItems([
      { number: 1, isActive: true, indicatorPosition: "25" },
      { number: 2, isActive: false, indicatorPosition: "50" },
      { number: 3, isActive: false, indicatorPosition: "79" },
      { number: 4, isActive: false, indicatorPosition: "100" },
    ]);

    set_req_client_id("");
    set_req_client_contact_id("");
    set_req_client_banch_id("");
    set_req_date_request("");
    set_req_origin_country("");
    set_req_origin_zip_code("");
    set_req_origin_city("");
    set_req_origin_adress("");
    set_req_dest_country("");
    set_req_dest_zip_code("");
    set_req_dest_city("");
    set_req_dest_adress("");
    set_req_title("");
    set_req_origin_company("");
    set_req_dest_company("");
    //nouvelle colonnes
    set_req_goods_height(0);
    set_req_goods_length(0);
    set_req_goods_width(0);
    set_req_goods_description("");
    set_req_fret_taxable_weight(0);
    set_req_weight_taxable_integrator(0);
    set_req_goods_ref_dbl("");
    set_req_comment("");
    set_req_goods_gross_weight(0);

    set_req_goods_volumetric_weight(0);
    set_req_client_contact_id("");
    set_req_client_banch_id("");
    set_reference_client("");
    set_req_time_request("");
    set_req_goods_nb_colis(0);

    set_demande({});
    setIsUpdate(false);
    set_agent_responsable_id("");
    set_agent_responsable("");

    set_contact([]);
    set_branch([]);
  }

  function inputIsEmptyError(key) {
    const form = new FormData(formRef.current);
    let isEmpty = false;
    if (required_input.includes(key)) {
      if (form.get(key) == "" || !form.get(key)) {
        isEmpty = true;
      }
    }

    return isEmpty;
  }

  const nextCarouselItem = (e) => {
    e.preventDefault();
    const inputs = new FormData(formRef.current);
    let oldActiveIndex = activeItem;
    let errors = error_message;
    let newErrorList = [];
    let can_next = true;
    if (!(_demande && JSON.stringify(_demande) !== "{}" && !isUpdate)) {
      //show error
      for (const key of inputs.keys()) {
        // client_bsl_num
        if (inputIsEmptyError(key)) {
          errors[key] = "Champ non-vide";
          errors = { ...errors, key: "Champ non-vide" };
          newErrorList.push(errorListLabel[key]);
          can_next = false;
        } else {
          errors[key] = "";
          errors = { ...errors, key: "" };
        }
      }
      if (!parseFloat(req_goods_gross_weight)) {
        errors = { ...errors, req_goods_gross_weight: "Valeur 0 non-acceptée" };
        can_next = false;
        newErrorList.push(errorListLabel["req_goods_gross_weight"]);
      } else {
        errors = { ...errors, req_goods_gross_weight: "" };
      }
      required_select.forEach((key) => {
        if (!inputs.get(key) && inputs.get(key) === null) {
          errors[key] = "Champ non-vide";
          errors = { ...errors, key: "Champ non-vide" };
          newErrorList.push(errorListLabel[key]);
          can_next = false;
        } else {
          errors[key] = "";
          errors = { ...errors, key: "" };
        }
      });

      required_selectBoxSearch.forEach((key) => {
        if (inputs.get(key) === "0") {
          errors[key] = "Champ non-vide";
          errors = { ...errors, key: "Champ non-vide" };
          newErrorList.push(errorListLabel[key]);
          can_next = false;
        } else {
          errors[key] = "";
          errors = { ...errors, key: "" };
        }
      });
    }

    setError_list(newErrorList);

    setError_message(errors);
    // if (_demande && JSON.stringify(_demande) !== "{}" && !isUpdate) {
    //   can_next = true;
    // }
    if (can_next) {
      // if (true) {
      let nextActiveIndex =
        oldActiveIndex + 1 < carouselItems.length ? oldActiveIndex + 1 : 0;

      setActiveItem(nextActiveIndex);
      const nextCarouselItems = carouselItems.map((item, index) => {
        switch (index) {
          case oldActiveIndex:
            item.isActive = false;
            break;
          case nextActiveIndex:
            item.isActive = true;
            break;
          default:
        }
        return item;
      });
      setCarouselItems(nextCarouselItems);
    } else {
      modalBodyAddDevis.current.scrollTop = 0;
    }
  };

  const previousCarouselItem = () => {
    let oldActiveIndex = activeItem;
    let previousActiveIndex =
      oldActiveIndex - 1 > -1 ? oldActiveIndex - 1 : carouselItems.length - 1;
    setActiveItem(previousActiveIndex);

    const nextCarouselItems = carouselItems.map((item, index) => {
      switch (index) {
        case oldActiveIndex:
          item.isActive = false;
          break;
        case previousActiveIndex:
          item.isActive = true;
          break;
        default:
      }
      return item;
    });

    setCarouselItems(nextCarouselItems);
  };

  const switchNextSubmitButtons = () => {
    switch (activeItem) {
      case 0:
        return (
          <>
            <FormButton
              buttonType="button"
              buttonValue="Suivant"
              buttonClass="btn btn-primary input-label-size me-2 rounded rounded-1 text-white "
              hasIcon={true}
              iconPlace="right"
              iconClass="fas fa-angle-right ms-2 position-relative z-3 justify-self-end "
              closeModal={false}
              buttonOnClick={nextCarouselItem}
            />
          </>
        );
      case carouselItems.length - 2:
        return (
          <>
            <FormButton
              buttonType="button"
              buttonValue="Précédent"
              buttonClass="btn text-primary fw-semibold btn-outline-primary bg-light input-label-size me-2 rounded rounded-1"
              hasIcon={true}
              iconClass="fas fa-angle-left me-2 bg-light"
              closeModal={true}
              buttonOnClick={previousCarouselItem}
            />
            {_demande &&
              JSON.stringify(_demande) !== "{}" &&
              !isUpdate &&
              activeItem != carouselItems.length && (
                <FormButton
                  buttonType="button"
                  buttonValue="Suivant"
                  buttonClass="btn btn-primary input-label-size me-2 rounded rounded-1 text-white "
                  hasIcon={true}
                  iconPlace="right"
                  iconClass="fas fa-angle-right ms-2 position-relative z-3 justify-self-end "
                  closeModal={false}
                  buttonOnClick={nextCarouselItem}
                />
              )}
            {isSubmittingRequest ? (
              <FormButton
                buttonType="button"
                buttonValue={
                  !(_demande && JSON.stringify(_demande) !== "{}") && !isUpdate
                    ? "Ajouter une demande"
                    : "Modifier une demande"
                }
                buttonClass="btn green-button-bsl input-label-size me-2 rounded rounded-1"
                hasIcon={true}
                iconClass="fas fa-check me-2 "
                closeModal={false}
                disable={true}
              />
            ) : !(_demande && JSON.stringify(_demande) !== "{}") &&
              !isUpdate ? (
              <FormButton
                buttonType="submit"
                buttonValue={"Ajouter une demande"}
                buttonClass="btn green-button-bsl input-label-size me-2 rounded rounded-1"
                hasIcon={true}
                iconClass="fas fa-check me-2 "
                closeModal={false}
              />
            ) : _demande && JSON.stringify(_demande) !== "{}" && isUpdate ? (
              <FormButton
                buttonType="submit"
                buttonValue={"Modifier une demande"}
                buttonClass="btn green-button-bsl input-label-size me-2 rounded rounded-1"
                hasIcon={true}
                iconClass="fas fa-check me-2 "
                closeModal={false}
              />
            ) : null}
          </>
        );
      default:
        return (
          <>
            <FormButton
              buttonType="button"
              buttonValue="Précédent"
              buttonClass="btn text-primary fw-semibold btn-outline-primary bg-light input-label-size me-2 rounded rounded-1"
              hasIcon={true}
              iconClass="fas fa-angle-left me-2 bg-light"
              closeModal={true}
              buttonOnClick={previousCarouselItem}
            />
            {activeItem != carouselItems.length && (
              <FormButton
                buttonType="button"
                buttonValue="Suivant"
                buttonClass="btn btn-primary input-label-size me-2 rounded rounded-1 text-white "
                hasIcon={true}
                iconPlace="right"
                iconClass="fas fa-angle-right ms-2 position-relative z-3 justify-self-end "
                closeModal={false}
                buttonOnClick={nextCarouselItem}
              />
            )}
          </>
        );
    }
  };

  useEffect(() => {
    const volume =
      parseFloat(req_goods_length) *
      parseFloat(req_goods_width) *
      parseFloat(req_goods_height);
    set_req_fret_taxable_weight(
      isNaN(volume / 6000) ? 0 : (volume / 6000).toFixed(2)
    );
    set_req_weight_taxable_integrator(
      isNaN(volume / 5000) ? 0 : (volume / 5000).toFixed(2)
    );
  }, [req_goods_length, req_goods_width, req_goods_height]);

  const getDate = async () => {
    const { loading, data, errors } = await FetchData(
      "/api/request/date-creation",
      {
        method: "GET",
      }
    );
    if (data.status === 200) {
      set_req_date_request(data.data.date?.split("/").reverse().join("-"));
      set_req_time_request(data.data.time);
    } else {
      Swal.fire({
        title: "Erreur!",
        text: data?.error,
        icon: "error",
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const search_city = (
    value,
    setNewValue = () => {},
    target_country = 0,
    target_zipcode = "",
    city_owner = "req_origin"
  ) => {
    switch (city_owner) {
      case "req_origin":
        req_origin_city_controller.current = SearchCity(
          target_country,
          target_zipcode,
          set_req_origin_city,
          req_origin_city_controller.current,
          data_country_list
        );
        break;
      case "req_dest":
        req_dest_city_controller.current = SearchCity(
          target_country,
          target_zipcode,
          set_req_dest_city,
          req_dest_city_controller.current,
          data_country_list
        );
        break;
    }

    setNewValue(value);
  };

  return (
    <Form
      formData={form_data}
      submitFunction={submitAddDevis}
      formRef={formRef}
    >
      <ModalForAddNew modalData={modalData} isShowModale={isShowModal}>
        <div
          className="modal-header container-fluid d-flex flex-column"
          style={{ minHeight: "17%" }}
        >
          <div
            className="d-flex flex-row w-100 align-items-center"
            style={{ height: "50%" }}
          >
            {!(_demande && JSON.stringify(_demande) !== "{}") ? (
              <>
                <h1
                  className="modal-title fs-5 "
                  id={modalData?.modalId + "Label"}
                >
                  <span className="badge input-label-size bg-primary-subtle text-primary-emphasis fw-light ms-2">
                    Nouvelle
                  </span>
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={resetError}
                ></button>
              </>
            ) : (
              <HeaderModal
                agent_change={handleChangeAgentResponsable}
                data_list={agent_list}
                modalData={modalData}
                demande={_demande}
                handleCloseModal={resetError}
              />
            )}
          </div>
          <div className="w-100 mt-1" style={{ height: "50%" }}>
            <div className="d-flex justify-content-evenly w-100 z-2 position-absolute">
              <div className="d-flex flex-column align-items-center">
                <div
                  className={`active rounded rounded-circle bg-primary input-text-size text-white pt-1 ps-2 pe-2 ${
                    carouselItems[0].isActive == true
                      ? "border border-light border-5 border-opacity-75"
                      : "border border-light border-5 border-opacity-100"
                  }`}
                  style={{ width: "40px", height: "40px", textAlign: "center" }}
                >
                  1
                </div>
                <span className="text-muted" style={{ fontSize: "10px" }}>
                  Formulaire
                </span>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div
                  className={`active rounded rounded-circle bg-primary input-text-size text-white pt-1 ps-2 pe-2 bg-opacity-0 ${
                    carouselItems[1].isActive == true
                      ? "border border-light border-5 border-opacity-75"
                      : "border border-light border-5 border-opacity-100"
                  }`}
                  style={{ width: "40px", height: "40px", textAlign: "center" }}
                >
                  2
                </div>
                <span
                  className="text-muted"
                  style={{
                    fontSize: "10px",
                  }}
                >
                  Mails
                </span>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div
                  className={`active rounded rounded-circle bg-primary input-text-size text-white pt-1 pb-1 ps-2 pe-2 bg-opacity-0 ${
                    carouselItems[2].isActive == true
                      ? "border border-light border-5 border-opacity-75"
                      : "border border-light border-5 border-opacity-100"
                  }`}
                  style={{ width: "40px", height: "40px", textAlign: "center" }}
                >
                  3
                </div>
                <span className="text-muted" style={{ fontSize: "10px" }}>
                  Documents
                </span>
              </div>

              {demande && can_see_request_log && (
                <div
                  className="d-flex flex-column align-items-center cursor-pointer"
                  onClick={handleClickLog}
                >
                  <div
                    className={`active rounded rounded-circle bg-primary input-text-size text-white pt-1 pb-1 ps-2 pe-2 bg-opacity-0  ${
                      carouselItems[3]?.isActive == true
                        ? "border border-light border-5 border-opacity-85"
                        : "border border-light border-5 border-opacity-100"
                    }`}
                    style={{
                      width: "40px",
                      height: "40px",
                      textAlign: "center",
                    }}
                  >
                    4
                  </div>
                  <span className="text-muted" style={{ fontSize: "10px" }}>
                    Historiques
                  </span>
                </div>
              )}
            </div>
            <div
              className="progress mb-0"
              role="progressbar"
              aria-label="carouselProgress"
              style={{ height: "2px", marginTop: "17px" }}
              //   aria-valuenow={25}
              //   aria-valuemin={45}
              //   aria-valuemax={75}
            >
              <div
                style={{ width: "79%" }}
                className={`progress-bar w-${carouselItems[activeItem].indicatorPosition}`}
              ></div>
            </div>
          </div>
        </div>
        {/* ===================Modal body start here 0  œ s====================*/}
        <div
          ref={modalBodyAddDevis}
          className="modal-body p-0"
          style={{ minHeight: "75%" }}
        >
          {/* =========== Beginning of carousel =================== */}
          <div id="carouselAddClient" className="carousel slide">
            <div className="carousel-inner" style={{ overflowY: "auto" }}>
              {/* =============== error list =============================== */}
              {error_list.length > 0 ? (
                <ErrorList errorList={error_list} />
              ) : null}
              {/* ========================  first carousel ============================= */}
              <div
                className={
                  "carousel-item overflow-y-auto " +
                  (carouselItems[0].isActive === true ? " active " : "")
                }
                style={{ overflowY: "auto" }}
              >
                {/* premier formulaire */}
                {_demande && JSON.stringify(_demande) !== "{}" && !isUpdate ? (
                  <>
                    <div className=" container-fluid d-flex mt-2 mb-2  ps-4 pt-3 pb-3 justify-content-end ">
                      {can_update_open_request_infos && (
                        <FormButton
                          buttonType="button"
                          buttonValue="Modifier"
                          buttonClass="btn btn-outline-primary input-label-size me-2"
                          hasIcon={true}
                          iconClass="fas fa-pen me-2"
                          loseModal={false}
                          buttonOnClick={() => setIsUpdate(true)}
                        />
                      )}
                    </div>
                    <div className="container-fluid container-sm">
                      {/* pour les agents responsable */}
                      {_demande?.req_id_status !== 7 && (
                        <FormCard title="Responsable">
                          <ListGroup>
                            <ListItems>
                              <ListItemContents
                                label="Agent responsable"
                                value={demande?.rau_username}
                              />
                            </ListItems>
                          </ListGroup>
                        </FormCard>
                      )}
                      <FormCard title="Général">
                        <ListGroup>
                          <ListItems>
                            <ListItemContents label="Titre" value={req_title} />
                          </ListItems>
                          <ListItems>
                            <div className="d-flex p-0 m-0">
                              <ListItemContents
                                label="Client"
                                value={_demande?.client_company_name}
                                contentClass="col "
                              />
                              <ListItemContents
                                label="Réference client"
                                value={_demande?.req_client_ref}
                                contentClass="col "
                              />
                            </div>
                          </ListItems>
                          <ListItems>
                            <div className="d-flex p-0 m-0">
                              <ListItemContents
                                label="Date de demande"
                                value={req_date_request}
                                contentClass="col "
                              />
                              <ListItemContents
                                label="Heure"
                                value={req_time_request}
                                contentClass="col "
                              />
                            </div>
                          </ListItems>
                        </ListGroup>
                      </FormCard>
                      <FormCard title="contact">
                        <ListGroup>
                          <ListItems>
                            <ListItemContents
                              label="Choix de contact"
                              value={_demande?.cc_name}
                            />
                          </ListItems>
                        </ListGroup>
                      </FormCard>
                      <FormCard title="Origine">
                        <ListGroup>
                          <ListItems>
                            <ListItemContents
                              label="Nom de sa société"
                              value={req_origin_company}
                            />
                          </ListItems>
                          <ListItems>
                            <div className="d-flex p-0 m-0">
                              <ListItemContents
                                label="Pays"
                                value={demande?.origin_country_name}
                                contentClass="col "
                              />
                              <ListItemContents
                                label="Code postal"
                                value={req_origin_zip_code}
                                contentClass="col "
                              />
                              <ListItemContents
                                label="ville"
                                value={req_origin_city}
                                contentClass="col "
                              />
                            </div>
                          </ListItems>
                          <ListItems>
                            <ListItemContents
                              label="Adresse"
                              value={req_origin_adress}
                              contentClass="col "
                            />
                          </ListItems>
                        </ListGroup>
                      </FormCard>
                      <FormCard title="Destination">
                        <ListGroup>
                          <ListItems>
                            <ListItemContents
                              label="Nom de sa société"
                              value={req_dest_company}
                            />
                          </ListItems>
                          <ListItems>
                            <div className="d-flex p-0 m-0">
                              <ListItemContents
                                label="Pays"
                                value={demande?.dest_country_name}
                                contentClass="col "
                              />
                              <ListItemContents
                                label="Code postal"
                                value={req_dest_zip_code}
                                contentClass="col "
                              />
                              <ListItemContents
                                label="ville"
                                value={req_dest_city}
                                contentClass="col "
                              />
                            </div>
                          </ListItems>
                          <ListItems>
                            <ListItemContents
                              label="Adresse"
                              value={req_dest_adress}
                              contentClass="col "
                            />
                          </ListItems>
                        </ListGroup>
                      </FormCard>
                      <FormCard title="Marchandises">
                        <ListGroup>
                          <ListItems>
                            <div className="d-flex p-0 m-0">
                              <ListItemContents
                                label="Nombre de colis"
                                value={_demande?.req_goods_nb_colis}
                                contentClass="col "
                              />
                              <ListItemContents
                                label="Poids brut"
                                value={req_goods_gross_weight}
                                contentClass="col "
                              />
                            </div>
                          </ListItems>
                          <ListItems>
                            <div className="d-flex p-0 m-0">
                              <ListItemContents
                                label="Longueur"
                                value={`${req_goods_length} ${
                                  !req_goods_length || req_goods_length <= 0
                                    ? ""
                                    : "cm"
                                }`}
                                contentClass="col-4"
                              />
                              <ListItemContents
                                label="Largeur"
                                value={`${req_goods_width} ${
                                  !req_goods_width || req_goods_width <= 0
                                    ? ""
                                    : "cm"
                                }`}
                                contentClass="col-4"
                              />
                              <ListItemContents
                                label="Hauteur"
                                value={`${req_goods_height} ${
                                  !req_goods_height || req_goods_height <= 0
                                    ? ""
                                    : "cm"
                                }`}
                                contentClass="col-4"
                              />
                            </div>
                          </ListItems>
                          <ListItems>
                            <div className="d-flex p-0 m-0">
                              <ListItemContents
                                label="Poids Taxable Fret "
                                value={req_weight_taxable_integrator}
                                contentClass="col-4"
                              />
                              <ListItemContents
                                label="Poids Taxable Intégrateur"
                                value={req_fret_taxable_weight}
                                contentClass="col-4"
                              />
                            </div>
                          </ListItems>
                          <ListItems>
                            <div className="d-flex p-0 m-0">
                              <ListItemContents
                                label="INCOTERM"
                                value={req_goods_ref_dbl}
                                contentClass="col-8"
                              />
                            </div>
                          </ListItems>
                          <ListItems>
                            <ListItemContents
                              label="Descriptif"
                              value={req_goods_description}
                              contentClass="col "
                            />
                          </ListItems>
                        </ListGroup>
                      </FormCard>
                      <FormCard title="Commentaire">
                        <ListGroup>
                          <ListItems>
                            <ListItemContents
                              label="Commentaire"
                              value={req_comment}
                              contentClass="col "
                            />
                          </ListItems>
                        </ListGroup>
                      </FormCard>
                    </div>
                  </>
                ) : (
                  <>
                    {/* pour les agents responsable */}
                    {_demande &&
                      JSON.stringify(_demande) !== "{}" &&
                      _demande?.req_id_status !== 7 && (
                        <FormSection
                          formSectionData={{
                            type: "section",
                            title: "Responsable",
                            sectionClass: "border-bottom",
                          }}
                        >
                          <SelectBoxSearch
                            type="select"
                            blockClass="col-6 col-sm-4"
                            id="agent_responsable"
                            labelClass=""
                            label="Agent responsable"
                            selectClass="input-label-size "
                            isRequired={false}
                            selectOptions={agent_list}
                            onChange={handleChangeAgentResponsable}
                            defaultValue={agent_responsable_id}
                            value="username"
                            placeholder={
                              _demande?.rau_agent_id !== null
                                ? demande?.rau_username
                                : "Assigné un responsable"
                            }
                          />
                          {_demande?.rau_agent_id !== null ? (
                            <div className="d-flex flex-row">
                              <Input
                                type="text"
                                blockClass="mb-3 me-2 col"
                                id="agent_poste"
                                labelClass="text-muted"
                                label="Poste"
                                inputClass="custom-input-colors"
                                placeholder="Poste"
                                isRequired={false}
                                isDisabled={true}
                                errorMessage={""}
                                value={_demande?.rau_user_role_name}
                              />
                              <Input
                                type="text"
                                blockClass="mb-3 me-2 col"
                                id="agent_mail"
                                labelClass="text-muted"
                                label="E-mail"
                                inputClass="custom-input-colors"
                                placeholder="E-mail"
                                isRequired={false}
                                isDisabled={true}
                                errorMessage={""}
                                value={_demande?.rau_email}
                              />
                              <Input
                                type="text"
                                blockClass="mb-3 me-2 col"
                                id="agent_lname"
                                labelClass="text-muted"
                                label="Nom"
                                inputClass="custom-input-colors"
                                placeholder="Nom"
                                isRequired={false}
                                isDisabled={true}
                                errorMessage={""}
                                value={_demande?.rau_lname}
                              />
                            </div>
                          ) : null}
                        </FormSection>
                      )}
                    <FormSection
                      formSectionData={{
                        type: "section",
                        title: "Général",
                        sectionClass: "border-bottom",
                      }}
                    >
                      <Input
                        type={"text"}
                        blockClass={"mb-3 me-2 col"}
                        id={"req_title"}
                        labelClass={""}
                        label={"Titre"}
                        inputClass=""
                        placeholder="Titre"
                        isRequired={false}
                        isDisabled={true}
                        errorMessage=""
                        value={_demande?.numero}
                        //onChange={set_req_title}
                      />
                      <div className="d-flex flex-row">
                        <SelectBoxSearch
                          type="select"
                          blockClass="mb-3 me-2 col"
                          id="req_client_id"
                          labelClass=""
                          label="Client"
                          selectClass="input-label-size "
                          isRequired={true}
                          defaultValue={req_client_id}
                          errorMessage={error_message["req_client_id"]}
                          selectOptions={client_list}
                          onChange={handleChangeClient}
                          cle="client_id"
                          value="client_company_name"
                          placeholder="Client"
                        />
                        <Input
                          type="text"
                          blockClass="mb-3 me-2 col"
                          id="reference_client"
                          labelClass=""
                          label="Référence client"
                          inputClass="custom-input-colors"
                          placeholder="Référence client"
                          isRequired={false}
                          isDisabled={false}
                          errorMessage={""}
                          value={reference_client}
                          onChange={set_reference_client}
                        />
                        <SelectBoxSearch
                          type="select"
                          blockClass="mb-3 me-2 col"
                          id="req_client_banch_id"
                          labelClass=""
                          label="Branche"
                          selectClass="input-label-size "
                          isRequired={true}
                          defaultValue={req_client_banch_id}
                          errorMessage={error_message["req_client_banch_id"]}
                          selectOptions={branch}
                          onChange={handleChangeBranch}
                          cle="cb_id"
                          value="cb_name"
                          placeholder="Branche"
                        />
                      </div>
                      <div className="d-flex flex-row">
                        <Input
                          type="date"
                          blockClass="mb-3 me-2 col"
                          id="req_date_request"
                          labelClass=""
                          label="Date de demande"
                          inputClass="custom-input-colors"
                          placeholder="Date de demande"
                          isRequired={true}
                          isDisabled={false}
                          errorMessage={error_message["req_date_request"]}
                          value={req_date_request}
                          onChange={set_req_date_request}
                        />
                        <Input
                          type="time"
                          blockClass="mb-3 me-2 col"
                          id="req_time_request"
                          labelClass=""
                          label="Heure"
                          inputClass="custom-input-colors"
                          placeholder="Heure"
                          isRequired={true}
                          isDisabled={false}
                          errorMessage={error_message["req_time_request"]}
                          value={req_time_request}
                          onChange={set_req_time_request}
                        />
                      </div>
                    </FormSection>
                    <FormSection
                      formSectionData={{
                        type: "section",
                        title: "Contact",
                        sectionClass: "border-bottom",
                      }}
                    >
                      <SelectBoxSearch
                        type="select"
                        blockClass="mb-3 me-2 col-4"
                        id="req_client_contact_id"
                        labelClass=""
                        label="Contact"
                        selectClass="input-label-size "
                        isRequired={true}
                        defaultValue={req_client_contact_id}
                        errorMessage={error_message["req_client_contact_id"]}
                        selectOptions={contact}
                        onChange={set_req_client_contact_id}
                        cle="cc_id"
                        value="cc_name"
                        placeholder="Choix du contact"
                      />
                      {req_client_contact_id > 0 ||
                      demande?.req_client_contact_id > 0 ? (
                        <div className="d-flex flex-row">
                          <Input
                            type="text"
                            blockClass="mb-3 me-2 col"
                            id="reference_client"
                            labelClass="text-muted"
                            label="Poste"
                            inputClass="custom-input-colors"
                            placeholder="Poste"
                            isRequired={false}
                            isDisabled={true}
                            errorMessage={""}
                            value={
                              contact?.filter(
                                (c) =>
                                  c?.cc_id ===
                                  parseInt(
                                    req_client_contact_id ||
                                      demande?.req_client_contact_id
                                  )
                              )[0]?.cc_post
                            }
                            // onChange={set_reference_client}
                          />
                          <Input
                            type="text"
                            blockClass="mb-3 me-2 col"
                            id="reference_client"
                            labelClass="text-muted"
                            label="E-mail"
                            inputClass="custom-input-colors"
                            placeholder="E-mail"
                            isRequired={false}
                            isDisabled={false}
                            errorMessage={""}
                            value={
                              req_client_email_contact?.length > 0
                                ? req_client_email_contact
                                : contact?.filter(
                                    (c) =>
                                      c?.cc_id ===
                                      parseInt(
                                        req_client_contact_id ||
                                          demande?.req_client_contact_id
                                      )
                                  )[0]?.cc_email
                            }
                            onChange={set_req_client_email_contact}
                          />
                          <Input
                            type="text"
                            blockClass="mb-3 me-2 col"
                            id="reference_client"
                            labelClass="text-muted"
                            label="Téléphone"
                            inputClass="custom-input-colors"
                            placeholder="Télephone"
                            isRequired={false}
                            isDisabled={true}
                            errorMessage={""}
                            value={
                              contact?.filter(
                                (c) =>
                                  c?.cc_id ===
                                  parseInt(
                                    req_client_contact_id ||
                                      demande?.req_client_contact_id
                                  )
                              )[0]?.cc_phone_1
                            }
                            // onChange={set_reference_client}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </FormSection>
                    <FormSection
                      formSectionData={{
                        type: "section",
                        title: "Origine",
                        sectionClass: "border-bottom",
                      }}
                    >
                      <Input
                        type={"text"}
                        blockClass={"mb-3 me-2 col"}
                        id={"req_origin_company"}
                        labelClass={""}
                        label={"Nom de la société"}
                        inputClass=""
                        placeholder="Nom de la société"
                        isRequired={false}
                        isDisabled={false}
                        errorMessage=""
                        value={req_origin_company}
                        onChange={set_req_origin_company}
                      />
                      <div className="d-flex flex-row">
                        <SelectBoxSearch
                          type="select"
                          blockClass="mb-3 me-2 col"
                          id="req_origin_country"
                          labelClass=""
                          label="Pays"
                          selectClass="input-label-size "
                          isRequired={true}
                          defaultValue={req_origin_country}
                          errorMessage={error_message["req_origin_country"]}
                          selectOptions={data_country_list}
                          onChange={(e) =>
                            search_city(
                              e,
                              set_req_origin_country,
                              e,
                              req_origin_zip_code,
                              "req_origin"
                            )
                          }
                          cle="country_id"
                          value="country_name"
                          placeholder="Pays"
                        />
                        <Input
                          type="text"
                          blockClass="mb-3 me-2 col"
                          id="req_origin_zip_code"
                          labelClass=""
                          label="Code postal"
                          inputClass="custom-input-colors"
                          placeholder="Code postal"
                          isRequired={true}
                          isDisabled={false}
                          errorMessage={error_message["req_origin_zip_code"]}
                          value={req_origin_zip_code}
                          onChange={(e) =>
                            search_city(
                              e,
                              set_req_origin_zip_code,
                              req_origin_country,
                              e,
                              "req_origin"
                            )
                          }
                        />
                        <Input
                          type="text"
                          blockClass="mb-3 me-2 col"
                          id="req_origin_city"
                          labelClass=""
                          label="Ville"
                          inputClass="custom-input-colors"
                          placeholder="Ville"
                          isRequired={true}
                          isDisabled={false}
                          errorMessage={error_message["req_origin_city"]}
                          value={req_origin_city}
                          onChange={set_req_origin_city}
                        />
                      </div>
                      <div className="d-flex flex-row">
                        <Input
                          type="text"
                          blockClass="mb-3 me-2 col"
                          id="req_origin_adress"
                          labelClass=""
                          label="Adresse"
                          inputClass="custom-input-colors"
                          placeholder="Adresse"
                          isRequired={false}
                          isDisabled={false}
                          errorMessage={""}
                          value={req_origin_adress}
                          onChange={set_req_origin_adress}
                        />
                      </div>
                    </FormSection>
                    <FormSection
                      formSectionData={{
                        type: "section",
                        title: "Destination",
                        sectionClass: "border-bottom",
                      }}
                    >
                      <Input
                        type={"text"}
                        blockClass={"mb-3 me-2 col"}
                        id={"req_dest_company"}
                        labelClass={""}
                        label={"Nom de la société"}
                        inputClass=""
                        placeholder="Nom de la société"
                        isRequired={false}
                        isDisabled={false}
                        errorMessage=""
                        value={req_dest_company}
                        onChange={set_req_dest_company}
                      />
                      <div className="d-flex flex-row">
                        <SelectBoxSearch
                          type="select"
                          blockClass="mb-3 me-2 col"
                          id="req_dest_country"
                          labelClass=""
                          label="Pays"
                          selectClass="input-label-size "
                          isRequired={true}
                          defaultValue={req_dest_country}
                          errorMessage={error_message["req_dest_country"]}
                          selectOptions={data_country_list}
                          onChange={(e) =>
                            search_city(
                              e,
                              set_req_dest_country,
                              e,
                              req_dest_zip_code,
                              "req_dest"
                            )
                          }
                          cle="country_id"
                          value="country_name"
                          placeholder="Pays"
                        />
                        <Input
                          type="text"
                          blockClass="mb-3 me-2 col"
                          id="req_dest_zip_code"
                          labelClass=""
                          label="Code postal"
                          inputClass="custom-input-colors"
                          placeholder="Code postal"
                          isRequired={true}
                          isDisabled={false}
                          errorMessage={error_message["req_dest_zip_code"]}
                          value={req_dest_zip_code}
                          onChange={(e) =>
                            search_city(
                              e,
                              set_req_dest_zip_code,
                              req_dest_country,
                              e,
                              "req_dest"
                            )
                          }
                        />

                        <Input
                          type="text"
                          blockClass="mb-3 me-2 col"
                          id="req_dest_city"
                          labelClass=""
                          label="Ville"
                          inputClass="custom-input-colors"
                          placeholder="Ville"
                          isRequired={true}
                          isDisabled={false}
                          errorMessage={error_message["req_dest_city"]}
                          value={req_dest_city}
                          onChange={set_req_dest_city}
                        />
                      </div>
                      <div className="d-flex flex-row">
                        <Input
                          type="text"
                          blockClass="mb-3 me-2 col"
                          id="req_dest_adress"
                          labelClass=""
                          label="Adresse"
                          inputClass="custom-input-colors"
                          placeholder="Adresse"
                          isRequired={false}
                          isDisabled={false}
                          errorMessage={""}
                          value={req_dest_adress}
                          onChange={set_req_dest_adress}
                        />
                      </div>
                    </FormSection>
                    <FormSection
                      formSectionData={{
                        type: "section",
                        title: "Marchandises",
                        sectionClass: "border-bottom",
                      }}
                    >
                      <div className="d-flex flex-row">
                        <Input
                          type={"number"}
                          blockClass={"mb-3 me-2 col"}
                          id={"req_goods_nb_colis"}
                          labelClass={""}
                          label={"Nombre de colis"}
                          inputClass=""
                          placeholder="Nombre de colis"
                          isRequired={true}
                          isDisabled={false}
                          errorMessage={error_message["req_goods_nb_colis"]}
                          value={req_goods_nb_colis}
                          onChange={set_req_goods_nb_colis}
                          suffix=""
                        />
                        <Input
                          type={"number"}
                          blockClass={"mb-3 me-2 col"}
                          id={"req_goods_gross_weight"}
                          labelClass={""}
                          label={"Poids brut"}
                          inputClass=""
                          placeholder="Poids brut"
                          isRequired={true}
                          isDisabled={false}
                          errorMessage={error_message["req_goods_gross_weight"]}
                          value={req_goods_gross_weight}
                          onChange={set_req_goods_gross_weight}
                          suffix="Kg"
                        />
                      </div>
                      <div className="d-flex flex-row">
                        <Input
                          type="number"
                          blockClass="mb-3 me-2 col"
                          id="req_goods_length"
                          labelClass=""
                          label="Longueur"
                          inputClass="custom-input-colors"
                          placeholder="Longueur"
                          isRequired={false}
                          isDisabled={false}
                          errorMessage={""}
                          value={req_goods_length}
                          onChange={set_req_goods_length}
                          suffix="cm"
                        />
                        <Input
                          type="number"
                          blockClass="mb-3 me-2 col"
                          id="req_goods_width"
                          labelClass=""
                          label="Largeur"
                          inputClass="custom-input-colors"
                          placeholder="Largeur"
                          isRequired={false}
                          isDisabled={false}
                          errorMessage={""}
                          value={req_goods_width}
                          onChange={set_req_goods_width}
                          suffix="cm"
                        />
                        <Input
                          type="number"
                          blockClass="mb-3 me-2 col"
                          id="req_goods_height"
                          labelClass=""
                          label="Hauteur"
                          inputClass="custom-input-colors"
                          placeholder="Hauteur"
                          isRequired={false}
                          isDisabled={false}
                          errorMessage={""}
                          value={req_goods_height}
                          onChange={set_req_goods_height}
                          suffix="cm"
                        />
                      </div>
                      <div className="d-flex flex-row">
                        <Input
                          type="text"
                          blockClass="mb-3 me-2 col"
                          id="req_goods_length"
                          labelClass=""
                          label="Poids Taxable Fret"
                          inputClass="custom-input-colors"
                          placeholder="Poids Taxable Fret"
                          isRequired={false}
                          isDisabled={true}
                          errorMessage={""}
                          value={req_fret_taxable_weight}
                          onChange={(e) =>
                            set_req_fret_taxable_weight(parseFloat(e))
                          }
                        />
                        <Input
                          type="text"
                          blockClass="mb-3 me-2 col"
                          id="req_goods_width"
                          labelClass=""
                          label="Poids Taxable Intégrateur"
                          inputClass="custom-input-colors"
                          placeholder="Poids Taxable Intégrateur"
                          isRequired={false}
                          isDisabled={true}
                          errorMessage={""}
                          value={req_weight_taxable_integrator}
                          onChange={(e) =>
                            set_req_weight_taxable_integrator(parseFloat(e))
                          }
                        />
                      </div>
                      <Input
                        type="text"
                        blockClass="mb-3 me-2 col"
                        id="req_goods_ref_dbl"
                        labelClass=""
                        label="INCOTERM"
                        inputClass="custom-input-colors"
                        placeholder="INCOTERM"
                        isRequired={false}
                        isDisabled={false}
                        errorMessage={""}
                        value={req_goods_ref_dbl}
                        onChange={set_req_goods_ref_dbl}
                      />
                      <TextArea
                        blockClass={"mb-3 me-2 col"}
                        id={"req_goods_description"}
                        labelClass={""}
                        label={"Descriptif"}
                        inputClass=""
                        placeholder="Descriptif"
                        isRequired={false}
                        value={req_goods_description}
                        onChange={set_req_goods_description}
                      />
                    </FormSection>
                    <FormSection
                      formSectionData={{
                        type: "section",
                        title: "Commentaire",
                        sectionClass: "border-bottom",
                      }}
                    >
                      <TextArea
                        blockClass={"mb-3 me-2 col"}
                        id={"req_comment"}
                        labelClass={""}
                        label={"Commentaire"}
                        inputClass=""
                        placeholder="Commentaire"
                        isRequired={false}
                        value={req_comment}
                        onChange={set_req_comment}
                      />
                    </FormSection>
                  </>
                )}
              </div>
              {/* ======================== second carousel ============================= */}
              <div
                className={
                  "carousel-item" +
                  (carouselItems[1].isActive === true ? " active " : "")
                }
              >
                {/* pour les fichier email */}

                <InputFile
                  files={[
                    { fileType: "eml", icone: "fa fa-envelope" },
                    { fileType: "email", icone: "fa fa-file" },
                    { fileType: "oft", icone: "fa fa-envelope" },
                    { fileType: "msg", icone: "fa fa-envelope" },
                  ]}
                  name_input="file"
                  size_file={100}
                  set_document={handleChangeEmail}
                  data_file={req_file_email}
                  title="Mails ajoutés"
                  deleteFile={onDeleteEmail}
                  isUpdate={
                    demande && JSON.stringify(demande) !== "{}" && !isUpdate
                  }
                  onIsUpdate={() => setIsUpdate(true)}
                  showValidateBtn={false}
                />
              </div>
              {/* ======================== 3 carousel ============================= */}
              <div
                className={
                  "carousel-item" +
                  (carouselItems[2].isActive === true ? " active " : "")
                }
              >
                {/* pour les fichier pdf,doc */}
                <InputFile
                  files={[
                    { fileType: "pdf", icone: "fa fa-envelope" },
                    { fileType: "docx", icone: "fa fa-file" },
                    { fileType: "jpeg", icone: "fa fa-thin fa-image" },
                    { fileType: "jpg", icone: "fa fa-thin fa-image" },
                    { fileType: "png", icone: "fa fa-thin fa-image" },
                  ]}
                  name_input="file"
                  size_file={1}
                  set_document={handleChangeDocument}
                  title="Documents ajoutés"
                  data_file={req_file_image}
                  deleteFile={onDeleteDoc}
                  isUpdate={
                    demande && JSON.stringify(demande) !== "{}" && !isUpdate
                  }
                  onIsUpdate={() => setIsUpdate(true)}
                  showValidateBtn={false}
                />
              </div>
              {/* ======================== 4 historiques ============================= */}
              {can_see_request_log && (
                <div
                  className={
                    "carousel-item" +
                    (carouselItems[3].isActive === true ? " active " : "")
                  }
                >
                  <ShowLogDemande data_log={logData} />
                </div>
              )}
            </div>
          </div>
          {/* ==================== end of carousel */}
        </div>
        {/* ================ Modal footer : where submit buttons and other buttons are =============== */}
        <div
          className={"d-flex justify-content-end mt-3 mb-5 modal-footer p-2"}
          style={{ minHeight: "8%" }}
        >
          <div className="d-flex justify-content-end col">
            {switchNextSubmitButtons()}
          </div>
        </div>
      </ModalForAddNew>
    </Form>
  );
};

export default AddDevis;
