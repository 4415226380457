import React, { useEffect, useState } from "react";
import FilterRound from "../Form/FilterRound";
import DateRangeFilter from "../Form/DateRangeFilter";
import "./../../styles/stats/home.css";
const StatsFilterRound = ({
  data_menu = [],
  clickFilter = () => {},
  activeChartFilter = [],
  filtered = 0,
  onChangeFilter = () => {},
  filterTitle = "Tous les clients",
  showActiveChartFilter = true,
  filteredRole = 0,
  data_role = [],
  onChangeFilterRole = () => {},
}) => {
  const [valueFiltered, setValueFiltered] = useState(filtered.id);

  function changeFiltered(e) {
    let newValue = e.target.value;
    let newFilter = activeChartFilter.filter((d) => d.id == newValue)[0];
    onChangeFilter(newFilter);
    setValueFiltered(newValue);
  }

  useEffect(() => {
    setValueFiltered(filtered.id);
  }, [filtered]);

  return (
    <div className="card w-100 bsl-bar-chart-card col col-sm-12">
      <div className="card-header">
        <div className="w-100 ">
          <div className="table-tabs border-bottom-0 p-1 align-items-center row">
            <div className="tabs-rows col-sm-12 col-md-12 col-lg-3">
              {data_menu.map((v, i) => (
                <FilterRound
                  key={i}
                  text={v.label}
                  number={v.number}
                  is_active={v.selected}
                  onClick={() => clickFilter(v.key)}
                />
              ))}
            </div>
            <div className="text-left col-sm-12 col-md-12 col-lg">
              {showActiveChartFilter && (
                <select
                  disabled={false}
                  className={
                    "p-1 input-text-size text-secondary border-0 rounded ms-2 w-100 select-filter-home"
                  }
                  onChange={(e) => changeFiltered(e)}
                  value={valueFiltered}
                >
                  {activeChartFilter.map((cf_data, cf_index) => (
                    <option
                      className={"ps-2 pe-2 "}
                      key={"filtre-" + cf_index}
                      value={cf_data.id}
                    >
                      {cf_data.label}
                    </option>
                  ))}
                </select>
              )}
            </div>
            {filterTitle === "Tous les utilisateurs" && (
              <div className="text-left col-sm-12 col-md-12 col-lg">
                <select
                  disabled={false}
                  className={
                    "p-1 input-text-size text-secondary border-0 rounded ms-2 w-100 select-filter-home"
                  }
                  onChange={(e) =>
                    onChangeFilterRole(
                      data_role.filter((role) => role.id == e.target.value)[0]
                    )
                  }
                  value={filteredRole}
                >
                  {data_role.map((cf_data, cf_index) => (
                    <option
                      className={"ps-2 pe-2 "}
                      key={"filtre-" + cf_index}
                      value={cf_data.id}
                    >
                      {cf_data.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="col-sm-12 col-md-12 col-lg">
              <DateRangeFilter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsFilterRound;
