import { useEffect, useRef, useState } from "react";
import Form from "../../Form/Form";
import FormButton from "../../Form/FormButton";
import FormSection from "../../Form/FormSection";
import Input from "../../Form/Input";
import ModalForAddNew from "../ModalForAddNew";
import Validator from "validator";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { FetchData } from "../../../hooks/FetchData";
import TextArea from "../../Form/TextArea";
import ErrorList from "../../Form/ErrorList";
import FormCard from "../../Form/FormCard";
import ListGroup from "../../Form/ListGroup";
import ListItems from "../../Form/ListItems";
import ListItemContents from "../../Form/ListItemContents";
import SelectBoxSearch from "../../Form/SelectBoxSearch";
import { useAccess } from "../../../hooks/useAccess";
import { SearchCity } from "../../../hooks/SearchCity";

const AddSousTraitant = ({
  modalData = {},
  isShowModal = false,
  whyShown = 0,
  changeWhyShown = () => {},
  currentSt = null,
  stStatus = [],
  data_country = [],
  handleRefreshSt = () => {},
  handleCloseModal = function () {},
}) => {
  //Permissions
  const can_change_one_subcontractor_status = useAccess(21);
  const can_update_subcontractors = useAccess(23);

  //a reference to Sous traitant's form
  const formRef = useRef(null);

  //reference to city search controller api
  const city_controller = useRef();

  // all the form content's states(to control the inputs)
  const [st_id, setSt_id] = useState("");
  const [num_st, setNum_st] = useState("");
  const [st_company_name, setSt_company_name] = useState("");
  const [st_email, setSt_email] = useState("");
  const [st_country, setSt_country] = useState("");
  const [st_zipcode, setSt_zipcode] = useState("");
  const [st_city, setSt_city] = useState("");
  const [st_adress_1, setSt_adress_1] = useState("");
  const [st_adress_2, setSt_adress_2] = useState("");
  const [st_adress_3, setSt_adress_3] = useState("");
  const [st_phone_1, setSt_phone_1] = useState("");
  const [st_phone_2, setSt_phone_2] = useState("");
  const [st_fax, setSt_fax] = useState("");
  const [st_comment, setSt_comment] = useState("");
  // only for update form
  const [st_id_statut, setSt_id_statut] = useState(1);

  //check if sous traitant form is only for display or can be filled / updated
  const [isUpdateOn, setIsUpdateOn] = useState(false);

  //list that shows the list of the error fields label
  const [error_list, setError_list] = useState([]);

  //list that shows error message for each required inputs
  const [error_message, setError_message] = useState({
    st_company_name: "",
    st_email: "",
    st_country: "",
    st_zipcode: "",
    st_city: "",
    st_adress_1: "",
  });

  // list of required inputs
  const required_inputs = [
    "st_company_name",
    "st_country",
    "st_zipcode",
    "st_city",
    "st_adress_1",
  ];

  //handling scroll to top on errors
  const modalBodyAddSousTraitant = useRef(null);

  //handle form submission
  const [isSubmitting, setIsSubmitting] = useState(false);

  //function to check the input required to be filled
  function checkFormInputs() {
    let isValid = true;
    const form = new FormData(formRef.current);
    let newErrorList = [];
    let newErrorMessage = error_message;
    for (const key of form.keys()) {
      if (required_inputs.includes(key)) {
        if (form.get(key) == "" || !form.get(key)) {
          if (getInputLabel(key) !== "") {
            newErrorList.push(getInputLabel(key));
          }
          newErrorMessage[key] = "Champ vide";
          isValid = false;
        }
      }
    }
    if (!Validator.isEmail(st_email) && form.get("st_email").length > 0) {
      if (getInputLabel("st_email") !== null) {
        newErrorList.push(getInputLabel("st_email"));
      }
      newErrorMessage["st_email"] = "E-mail non valide";
      isValid = false;
    }
    setError_list((oldList) => {
      oldList = newErrorList;
      return oldList;
    });
    setError_message((oldMessages) => {
      oldMessages = newErrorMessage;
      return oldMessages;
    });
    return isValid;
  }

  //function to insert a new sous traitant into table
  const submitAddSousTraitant = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    if (checkFormInputs()) {
      let user = JSON.parse(Cookies.get("userData"));
      let newSousTraitant = {
        num_st: num_st,
        st_company_name: st_company_name,
        st_email: st_email,
        st_country: st_country,
        st_zipcode: st_zipcode,
        st_city: st_city,
        st_adress_1: st_adress_1,
        st_adress_2: st_adress_2,
        st_adress_3: st_adress_3,
        st_phone_1: st_phone_1,
        st_phone_2: st_phone_2,
        st_fax: st_fax,
        st_comment: st_comment,
        st_user_id_create: user.id,
        st_user_id_update: user.id,
      };
      try {
        const { loading, data, error } = await FetchData("/api/sous-traitant", {
          method: "POST",
          body: JSON.stringify(newSousTraitant),
        });
        if (data.status === 200) {
          Swal.fire({
            title: "Succès",
            text: "Le nouveau sous traitant a été ajouté avec succès.",
            icon: "success",
            position: "center",
            timer: 2000,
          });
          CloseAndResetFormErrors();
          handleRefreshSt();
        } else {
          Swal.fire({
            title: "Erreur!",
            text: data.error,
            icon: "error",
            showCloseButton: true,
            closeButtonAriaLabel: "Ok",
            timer: 2000,
          });
        }
      } catch (error) {}
    } else {
      modalBodyAddSousTraitant.current.scrollTop = 0;
    }
    setIsSubmitting(false);
  };

  //function to update a new sous traitant into table
  const submitUpdateSousTraitant = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    if (checkFormInputs()) {
      let user = JSON.parse(Cookies.get("userData"));
      let updatedSousTraitant = {
        num_st: num_st,
        st_company_name: st_company_name,
        st_email: st_email,
        st_country: st_country,
        st_zipcode: st_zipcode,
        st_city: st_city,
        st_adress_1: st_adress_1,
        st_adress_2: st_adress_2,
        st_adress_3: st_adress_3,
        st_phone_1: st_phone_1,
        st_phone_2: st_phone_2,
        st_fax: st_fax,
        st_comment: st_comment,
        st_user_id_create: currentSt.st_user_id_create,
        st_user_id_update: user.id,
        st_id_statut: st_id_statut,
      };
      try {
        const { loading, data, error } = await FetchData(
          `/api/sous-traitant/${currentSt.st_id}`,
          {
            method: "PUT",
            body: JSON.stringify(updatedSousTraitant),
          }
        );
        if (data.status === 200) {
          Swal.fire({
            title: "Succès",
            text: "Le sous traitant a été modifié avec succès.",
            icon: "success",
            position: "center",
            timer: 2000,
          });
          CloseAndResetFormErrors();
          handleRefreshSt();
          setIsUpdateOn(false);
        } else {
          Swal.fire({
            title: "Erreur",
            text: data.error,
            icon: "error",
            showCloseButton: true,
            closeButtonAriaLabel: "Ok",
            timer: 2000,
          });
        }
      } catch (error) {}
    } else {
      modalBodyAddSousTraitant.current.scrollTop = 0;
    }
    setIsSubmitting(false);
  };

  //function to get the label of an input
  function getInputLabel(inputKey) {
    const data = {
      st_company_name: "Nom de la société",
      st_email: "E-mail",
      st_country: "Pays",
      st_zipcode: "Code Postal",
      st_city: "Ville",
      st_adress_1: "Adresse 1",
    };
    return data[inputKey];
  }
  //============ function to close the modal and reset error messages(if there are) ==================
  function resetError() {
    setError_list([]);
    setError_message({
      st_company_name: "",
      st_email: "",
      st_country: "",
      st_zipcode: "",
      st_city: "",
      st_adress_1: "",
      cc_facturation_adress: "",
    });
  }

  // function to close the form and reset errors
  function CloseAndResetFormErrors() {
    handleCloseModal();
    resetError();
  }

  //handle email changes input specifically
  function handleEmailInput(e) {
    setSt_email(e);
    setError_message((current_em) => {
      current_em["st_email"] = "";
      return current_em;
    });
  }

  //function the form input integrally
  function resetFormIntegrally() {
    setSt_id("");
    setSt_company_name("");
    setSt_email("");
    setSt_country(undefined);
    setSt_zipcode("");
    setSt_city("");
    setSt_adress_1("");
    setSt_adress_2("");
    setSt_adress_3("");
    setSt_phone_1("");
    setSt_phone_2("");
    setSt_fax("");
    setSt_comment("");
    setNum_st("");
    resetError();
  }

  // function to set the sous traitant data into form inputs
  function setStIntoForm() {
    if (currentSt !== null) {
      setSt_id(currentSt.numero);
      setSt_company_name(currentSt.st_company_name);
      setSt_email(currentSt.st_email);
      setSt_country(currentSt.st_country);
      setSt_zipcode(currentSt.st_zipcode);
      setSt_city(currentSt.st_city);
      setSt_adress_1(currentSt.st_adress_1);
      setSt_adress_2(currentSt.st_adress_2);
      setSt_adress_3(currentSt.st_adress_3);
      setSt_phone_1(currentSt.st_phone_1);
      setSt_phone_2(currentSt.st_phone_2);
      setSt_fax(currentSt.st_fax);
      setSt_comment(currentSt.st_comment);
      setSt_id_statut(currentSt.st_id_statut);
      setNum_st(currentSt.num_st !== null ? currentSt.num_st : "");
    }
  }

  // function to handle status update on form sous traitant updating
  function handleStStatus(e) {
    setSt_id_statut((id_status) => {
      id_status = e.target.value;
      return id_status;
    });
  }

  // Functions for CSS class of the form
  function colorDropdown(stStatusId) {
    let color = "";
    switch (true) {
      case stStatusId == 1:
        color = "bg-success-subtle text-success ";
        break;
      case stStatusId == 2:
        color = "bg-danger-subtle text-danger ";
        break;
    }
    return color;
  }

  function colorItem(stStatusId) {
    let color = "";
    switch (true) {
      case stStatusId == 1:
        color =
          " text-success " +
          (currentSt?.st_id_statut === stStatusId
            ? "bg-sucess-subtle "
            : "bg-white ");
        break;
      case stStatusId == 2:
        color =
          " text-danger " +
          (currentSt?.st_id_statut === stStatusId
            ? "bg-danger-subtle "
            : "bg-white ");
        break;
      default:
        color = " bg-primary-subtle text-primary-emphasis";
    }
    return color;
  }

  //useEffect to handle if the form is for update or create Sous traitant
  useEffect(() => {
    switch (whyShown) {
      case 1:
        resetFormIntegrally();
        setIsUpdateOn(true);
        break;
      case 2:
        if (currentSt === null) {
          changeWhyShown(0);
        } else {
          setStIntoForm();
          setIsUpdateOn(false);
        }
      default:
    }
  }, [whyShown]);

  const search_city = (
    value,
    setNewValue = () => {},
    target_country = 0,
    target_zipcode = ""
  ) => {
    city_controller.current = SearchCity(
      target_country,
      target_zipcode,
      setSt_city,
      city_controller.current,
      data_country
    );

    setNewValue(value);
  };

  return (
    <ModalForAddNew modalData={modalData} isShowModale={isShowModal}>
      <div className="modal-header container-fluid">
        {whyShown === 1 && (
          <h1
            className="modal-title fs-5 ms-2"
            id={modalData?.modalId + "Label"}
          >
            Nouveau Sous traitant
            <span className="badge input-label-size bg-primary-subtle text-primary-emphasis fw-light ms-2">
              Nouveau
            </span>
          </h1>
        )}
        {whyShown === 2 && (
          <h1
            className="modal-title fs-5 ms-2"
            id={modalData?.modalId + "Label"}
          >
            {st_company_name}
            {can_change_one_subcontractor_status && isUpdateOn ? (
              <select
                className={
                  "badge input-label-size fw-light border-0 ms-2 " +
                  colorDropdown(st_id_statut)
                }
                onChange={(e) => handleStStatus(e)}
                value={st_id_statut}
                disabled={!isUpdateOn}
              >
                {stStatus.map((cs_data, cs_index) => (
                  <option
                    className={
                      "ps-2 pe-2 " +
                      colorItem(cs_data.client_statut_id) +
                      (currentSt?.st_id_statut === cs_data.client_statut_id
                        ? " fw-bold "
                        : " ")
                    }
                    key={"statut_" + cs_index}
                    value={cs_data.client_statut_id}
                  >
                    {cs_data.client_statut_name}
                  </option>
                ))}
              </select>
            ) : (
              <span
                className={
                  "badge input-label-size fw-light border-0 ms-2 " +
                  colorDropdown(st_id_statut)
                }
              >
                {currentSt?.statut?.label}
              </span>
            )}
          </h1>
        )}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={CloseAndResetFormErrors}
        ></button>
      </div>
      <Form
        formData={{
          type: "form",
          id: "addSousTraitantForm",
          formMethod: "post",
          formAction: "#",
          formClass: "",
        }}
        formRef={formRef}
        submitFunction={(e) => {
          whyShown === 1
            ? submitAddSousTraitant(e)
            : submitUpdateSousTraitant(e);
        }}
      >
        {isUpdateOn == true && (
          <>
            <div ref={modalBodyAddSousTraitant} className="modal-body p-0">
              <ErrorList errorList={error_list} />
              <FormSection
                formSectionData={{
                  type: "section",
                  title: "General",
                  sectionClass: "",
                }}
              >
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="st_id"
                  labelClass=""
                  label="N° Sous traitant"
                  placeholder="N° Sous traitant"
                  value={num_st}
                  onChange={setNum_st}
                  inputClass="custom-input-colors"
                  isRequired={false}
                  isDisabled={false}
                  errorMessage={""}
                />
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="st_company_name"
                  labelClass=""
                  label="Nom de la société"
                  placeholder="Nom de la société"
                  inputClass="custom-input-colors"
                  value={st_company_name}
                  onChange={setSt_company_name}
                  isRequired={true}
                  isDisabled={false}
                  errorMessage={error_message["st_company_name"]}
                />
              </FormSection>
              <FormSection
                formSectionData={{
                  type: "section",
                  title: "Contact",
                  sectionClass: "",
                }}
              >
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="st_email"
                  labelClass=""
                  label="E-mail"
                  placeholder="E-mail"
                  inputClass="custom-input-colors"
                  value={st_email}
                  onChange={handleEmailInput}
                  isRequired={false}
                  isDisabled={false}
                  errorMessage={error_message["st_email"]}
                />
                <div className="d-flex">
                  <SelectBoxSearch
                    type="select"
                    blockClass="mb-3 me-2 col"
                    id="st_country"
                    labelClass=""
                    label="Pays"
                    selectClass="input-label-size "
                    isRequired={true}
                    defaultValue={st_country}
                    errorMessage={error_message["st_country"]}
                    selectOptions={data_country}
                    onChange={(e) =>
                      search_city(e, setSt_country, e, st_zipcode)
                    }
                    cle="country_id"
                    value="country_name"
                    placeholder="Pays"
                  />
                  <Input
                    type="text"
                    blockClass="mb-3 me-2 col"
                    id="st_zipcode"
                    labelClass=""
                    label="Code Postal"
                    placeholder="Code Postal"
                    inputClass="custom-input-colors"
                    value={st_zipcode}
                    onChange={(e) =>
                      search_city(e, setSt_zipcode, st_country, e)
                    }
                    isRequired={true}
                    isDisabled={false}
                    errorMessage={error_message["st_zipcode"]}
                  />
                  <Input
                    type="text"
                    blockClass="mb-3 me-2 col"
                    id="st_city"
                    labelClass=""
                    label="Ville"
                    placeholder="Ville"
                    inputClass="custom-input-colors"
                    value={st_city}
                    onChange={setSt_city}
                    isRequired={true}
                    isDisabled={false}
                    errorMessage={error_message["st_city"]}
                  />
                </div>
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="st_adress_1"
                  labelClass=""
                  label="Adresse 1"
                  placeholder="Adresse 1"
                  inputClass="custom-input-colors"
                  value={st_adress_1}
                  onChange={setSt_adress_1}
                  isRequired={true}
                  isDisabled={false}
                  maxLength={40}
                  errorMessage={error_message["st_adress_1"]}
                />
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="st_adress_2"
                  labelClass=""
                  label="Adresse 2"
                  placeholder="Adresse 2"
                  inputClass="custom-input-colors"
                  value={st_adress_2}
                  onChange={setSt_adress_2}
                  maxLength={40}
                  isRequired={false}
                  isDisabled={false}
                  errorMessage={""}
                />
                <Input
                  type="text"
                  blockClass="mb-3 me-2 col"
                  id="st_adress_3"
                  labelClass=""
                  label="Adresse 3"
                  placeholder="Adresse 3"
                  value={st_adress_3}
                  onChange={setSt_adress_3}
                  maxLength={40}
                  inputClass="custom-input-colors"
                  isRequired={false}
                  isDisabled={false}
                  errorMessage={""}
                />
                <div className="d-flex">
                  <Input
                    type="text"
                    blockClass="mb-3 me-2 col"
                    id="st_phone_1"
                    labelClass=""
                    label="Téléphone 1"
                    placeholder="Téléphone 1"
                    value={st_phone_1}
                    onChange={setSt_phone_1}
                    inputClass="custom-input-colors"
                    isRequired={false}
                    isDisabled={false}
                    errorMessage={""}
                  />
                  <Input
                    type="text"
                    blockClass="mb-3 me-2 col"
                    id="st_phone_2"
                    labelClass=""
                    label="Téléphone 2"
                    placeholder="Téléphone 2"
                    value={st_phone_2}
                    onChange={setSt_phone_2}
                    inputClass="custom-input-colors"
                    isRequired={false}
                    isDisabled={false}
                    errorMessage={""}
                  />
                  <Input
                    type="text"
                    blockClass="mb-3 me-2 col"
                    id="st_fax"
                    labelClass=""
                    label="Fax"
                    placeholder="Fax"
                    value={st_fax}
                    onChange={setSt_fax}
                    inputClass="custom-input-colors"
                    isRequired={false}
                    isDisabled={false}
                    errorMessage={""}
                  />
                </div>
              </FormSection>
              <FormSection
                formSectionData={{
                  type: "section",
                  title: "Commentaire",
                  sectionClass: "",
                }}
              >
                <TextArea
                  blockClass="mb-3 me-2 col"
                  id="st_comment"
                  labelClass=""
                  label="Commentaire"
                  placeholder="Commentaire"
                  value={st_comment}
                  onChange={setSt_comment}
                  textareaClass="custom-input-colors text-wrap overflow-auto"
                  isRequired={false}
                  isDisabled={false}
                  errorMessage=""
                />
              </FormSection>
            </div>
            <div
              className={"d-flex justify-content-end mt-2 mb-- modal-footer"}
            >
              <FormButton
                buttonType="button"
                buttonValue="Annuler"
                buttonClass="btn text-secondary input-label-size me-2"
                hasIcon={true}
                iconClass="far fa-times-circle me-2 bg-light"
                buttonOnClick={CloseAndResetFormErrors}
              />
              {isSubmitting ? (
                <FormButton
                  buttonType="button"
                  buttonValue={
                    whyShown === 1
                      ? "Ajouter le sous traitant"
                      : "Valider les modifications"
                  }
                  buttonClass="btn green-button-bsl input-label-size me-2"
                  hasIcon={true}
                  iconClass="fas fa-check me-2"
                  closeModal={false}
                  disable={true}
                />
              ) : (
                whyShown === 1 && (
                  <FormButton
                    buttonType="submit"
                    buttonValue="Ajouter le sous traitant"
                    buttonClass="btn green-button-bsl input-label-size me-2"
                    hasIcon={true}
                    iconClass="fas fa-check me-2"
                    closeModal={false}
                  />
                )
              )}
              {whyShown === 2 && (
                <FormButton
                  buttonType="submit"
                  buttonValue="Valider les modifications"
                  buttonClass="btn green-button-bsl input-label-size me-2"
                  hasIcon={true}
                  iconClass="fas fa-check me-2"
                  closeModal={false}
                />
              )}
            </div>
          </>
        )}
        {isUpdateOn == false && (
          <>
            {/* ================== Shows just sous traitant data without form fields ====================== */}
            <div
              ref={modalBodyAddSousTraitant}
              className="modal-body p-0 ps-5 pe-5"
            >
              <div className=" container-fluid d-flex mt-2 mb-2  ps-4 pt-3 pb-3 justify-content-end ">
                {can_update_subcontractors && (
                  <FormButton
                    buttonType="button"
                    buttonValue="Modifier"
                    buttonClass="btn btn-outline-primary input-label-size me-2"
                    hasIcon={true}
                    iconClass="fas fa-pen me-2"
                    loseModal={false}
                    buttonOnClick={() => setIsUpdateOn(true)}
                  />
                )}
              </div>

              <FormCard title="Général">
                <ListGroup>
                  <ListItems>
                    <ListItemContents label="N° Sous Traitant" value={st_id} />
                  </ListItems>
                  <ListItems>
                    <ListItemContents
                      label="Nom de la société"
                      value={currentSt?.st_company_name}
                    />
                  </ListItems>
                </ListGroup>
              </FormCard>
              <FormCard title="Contact">
                <ListGroup>
                  <ListItems>
                    <ListItemContents label="E-mail" value={st_email} />
                  </ListItems>
                  <ListItems>
                    <div className="d-flex p-0 m-0">
                      <ListItemContents
                        label="Pays"
                        value={currentSt?.st_country_name}
                        contentClass="col "
                      />
                      <ListItemContents
                        label="Code Postal"
                        value={st_zipcode}
                        contentClass="col "
                      />
                      <ListItemContents
                        label="Ville"
                        value={currentSt?.st_city}
                        contentClass="col "
                      />
                    </div>
                  </ListItems>
                  <ListItems>
                    <ListItemContents
                      label="Adresse 1"
                      value={currentSt?.st_adress_1}
                    />
                  </ListItems>
                  <ListItems>
                    <ListItemContents
                      label="Adresse 2"
                      value={currentSt?.st_adress_2}
                    />
                  </ListItems>
                  <ListItems>
                    <ListItemContents
                      label="Adresse 3"
                      value={currentSt?.st_adress_3}
                    />
                  </ListItems>
                  <ListItems>
                    <div className="d-flex p-0 m-0">
                      <ListItemContents
                        label="Téléphone 1"
                        value={currentSt?.st_phone_1}
                        contentClass="col "
                      />
                      <ListItemContents
                        label="Téléphone 2"
                        value={currentSt?.st_phone_2}
                        contentClass="col "
                      />
                      <ListItemContents
                        label="Fax"
                        value={currentSt?.st_fax}
                        contentClass="col "
                      />
                    </div>
                  </ListItems>
                </ListGroup>
              </FormCard>
              <FormCard title="Commentaire">
                <ListGroup>
                  <ListItems>
                    <ListItemContents
                      label="Commentaire"
                      value={currentSt?.st_comment}
                    />
                  </ListItems>
                </ListGroup>
              </FormCard>
            </div>
          </>
        )}
      </Form>
    </ModalForAddNew>
  );
};

export default AddSousTraitant;
