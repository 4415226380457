import React, { useEffect, useState } from "react";
import "../../styles/Form/input.css";
import "../../styles/Pages/login.css";

const FormInput = ({
  type = "text",
  blockClass = "",
  id = "text",
  labelClass = "",
  label = "label",
  inputClass = "",
  placeholder = "placeholder",
  isRequired = false,
  isDisabled = false,
  value = "",
  errorMessage = "",
  maxLength = 524288,
  onChange = (e) => {},
}) => {
  const [inputError, setInputError] = useState("");
  useEffect(() => {
    setInputError(errorMessage);
  }, [errorMessage]);

  const handleChange = (e) => {
    const value = e.target.value;
    onChange(value);
    if (isRequired && value.length > 0) {
      setInputError("");
    } else {
      setInputError(errorMessage);
    }
  };

  return (
    <>
      <div className={"mb-3 " + blockClass}>
        <>
          <label
            htmlFor={id}
            className={
              "form-label input-label-size position-relative " + labelClass
            }
          >
            {label}
            {isRequired === true ? (
              <span className="position-absolute top-0 start-100 translate-middle m-2 bg-transparent text-danger rounded-circle">
                *
              </span>
            ) : null}
          </label>
          <input
            value={value}
            onChange={handleChange}
            type={type}
            className={
              "form-control form-control-lg input-text-size " +
              inputClass +
              (inputError?.length > 0 ? " invalid-input-colors " : " ")
            }
            id={id}
            name={id}
            placeholder={placeholder}
            maxLength={maxLength}
            disabled={isDisabled}
            autoComplete="off"
          />
          {maxLength < 524288 && (
            <span className="d-flex input-text-size text-end justify-content-end">
              {value == undefined
                ? 0 + "/" + maxLength
                : value?.length + "/" + maxLength}
            </span>
          )}
          {inputError?.length > 0 && (
            <span className="input-error-message">
              <i className="fa-solid fa-circle-exclamation text-danger me-2"></i>
              {inputError}
            </span>
          )}
        </>
      </div>
    </>
  );
};
// required={isRequired}
export default FormInput;
