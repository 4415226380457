import React, { useEffect } from "react";
import FormCard from "../../../Form/FormCard";
import ListGroup from "../../../Form/ListGroup";
import ListItemContents from "../../../Form/ListItemContents";
import ListItems from "../../../Form/ListItems";

const ShowDetailDemande = ({ _demande }) => {
  return (
    <div className="container-fluid container-sm">
      {/* pour les agents responsable */}
      {_demande?.req_id_status !== 7 && (
        <FormCard title="Responsable">
          <ListGroup>
            <ListItems>
              <ListItemContents
                label="Agent responsable"
                value={_demande?.rau_username}
              />
            </ListItems>
          </ListGroup>
        </FormCard>
      )}
      <FormCard title="Général">
        <ListGroup>
          <ListItems>
            <ListItemContents label="Titre" value={_demande?.numero} />
          </ListItems>
          <ListItems>
            <div className="d-flex p-0 m-0">
              <ListItemContents
                label="Client"
                value={_demande?.client_company_name}
                contentClass="col "
              />
              <ListItemContents
                label="Référence client"
                value={_demande?.req_client_ref}
                contentClass="col "
              />
            </div>
          </ListItems>
          <ListItems>
            <div className="d-flex p-0 m-0">
              <ListItemContents
                label="Date de demande"
                value={_demande?.req_date_request}
                contentClass="col "
              />
              <ListItemContents
                label="Heure"
                value={_demande?.req_time_request}
                contentClass="col "
              />
            </div>
          </ListItems>
        </ListGroup>
      </FormCard>
      <FormCard title="contact">
        <ListGroup>
          <ListItems>
            <ListItemContents
              label="Choix de contact"
              value={_demande?.cc_name}
            />
          </ListItems>
        </ListGroup>
      </FormCard>
      <FormCard title="Origine">
        <ListGroup>
          <ListItems>
            <ListItemContents
              label="Nom de sa société"
              value={_demande?.req_origin_company}
            />
          </ListItems>
          <ListItems>
            <div className="d-flex p-0 m-0">
              <ListItemContents
                label="Pays"
                value={_demande?.origin_country_name}
                contentClass="col "
              />
              <ListItemContents
                label="Code postal"
                value={_demande?.req_origin_zip_code}
                contentClass="col "
              />
              <ListItemContents
                label="Ville"
                value={_demande?.req_origin_city}
                contentClass="col "
              />
            </div>
          </ListItems>
          <ListItems>
            <ListItemContents
              label="Adresse"
              value={_demande?.req_origin_adress}
              contentClass="col "
            />
          </ListItems>
        </ListGroup>
      </FormCard>
      <FormCard title="Destination">
        <ListGroup>
          <ListItems>
            <ListItemContents
              label="Nom de sa société"
              value={_demande?.req_dest_company}
            />
          </ListItems>
          <ListItems>
            <div className="d-flex p-0 m-0">
              <ListItemContents
                label="Pays"
                value={_demande?.dest_country_name}
                contentClass="col "
              />
              <ListItemContents
                label="Code postal"
                value={_demande?.req_dest_zip_code}
                contentClass="col "
              />
              <ListItemContents
                label="Ville"
                value={_demande?.req_dest_city}
                contentClass="col "
              />
            </div>
          </ListItems>
          <ListItems>
            <ListItemContents
              label="Adresse"
              value={_demande?.req_dest_adress}
              contentClass="col "
            />
          </ListItems>
        </ListGroup>
      </FormCard>
      <FormCard title="Marchandises">
        <ListGroup>
          <ListItems>
            <div className="d-flex p-0 m-0">
              <ListItemContents
                label="Nombre de colis"
                value={_demande?.req_goods_nb_colis}
                contentClass="col-4"
              />
              <ListItemContents
                label="Poids brut"
                value={_demande?.req_goods_gross_weight}
                contentClass="col-4"
              />
            </div>
          </ListItems>

          <ListItems>
            <div className="d-flex p-0 m-0">
              <ListItemContents
                label="Longueur"
                value={`${_demande?.req_goods_length} ${
                  !_demande?.req_goods_length || _demande?.req_goods_length <= 0
                    ? ""
                    : "cm"
                }`}
                contentClass="col-4"
              />
              <ListItemContents
                label="Largeur"
                value={`${_demande?.req_goods_width} ${
                  !_demande?.req_goods_width || _demande?.req_goods_width <= 0
                    ? ""
                    : "cm"
                }`}
                contentClass="col-4"
              />
              <ListItemContents
                label="Hauteur"
                value={`${_demande?.req_goods_height} ${
                  !_demande?.req_goods_height || _demande?.req_goods_height <= 0
                    ? ""
                    : "cm"
                }`}
                contentClass="col-4"
              />
            </div>
          </ListItems>
          <ListItems>
            <div className="d-flex p-0 m-0">
              <ListItemContents
                label="Poids Taxable Fret"
                value={_demande?.req_fret_taxable_weight}
                contentClass="col-4"
              />
              <ListItemContents
                label="Poids Taxable Intégrateur"
                value={_demande?.req_weight_taxable_integrator}
                contentClass="col-4"
              />
            </div>
          </ListItems>
          <ListItems>
            <div className="d-flex p-0 m-0">
              <ListItemContents
                label="INCOTERM"
                value={_demande?.req_goods_ref_dbl}
                contentClass="col-8"
              />
            </div>
          </ListItems>
          <ListItems>
            <ListItemContents
              label="Descriptif"
              value={_demande?.req_goods_description}
              contentClass="col "
            />
          </ListItems>
        </ListGroup>
      </FormCard>
      <FormCard title="Commentaire">
        <ListGroup>
          <ListItems>
            <ListItemContents
              label="Commentaire"
              value={_demande?.req_comment}
              contentClass="col "
            />
          </ListItems>
        </ListGroup>
      </FormCard>
    </div>
  );
};

export default ShowDetailDemande;
