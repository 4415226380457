import React from "react";
import "../../styles/Form/input.css";
import "../../styles/Form/selectBox.css";
const Form = ({
  children,
  formData = {
    type: "form",
    id: "form",
    formClass: "",
  },
  formRef = null,
  submitFunction = function () {},
}) => {
  return (
    <>
      <form
        id={formData?.id}
        name={formData?.id}
        className={formData?.formClass}
        onSubmit={submitFunction}
        ref={formRef}
      >
        {children}
      </form>
    </>
  );
};

export default Form;
