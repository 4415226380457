import React from "react";
import ListGroup from "../../../Form/ListGroup";
import FormButton from "../../../Form/FormButton";
import FormCard from "../../../Form/FormCard";
import ListItems from "../../../Form/ListItems";
import ListItemContents from "../../../Form/ListItemContents";
import { useAccess } from "../../../../hooks/useAccess";

const DisplayCurrentClientGeneral = ({
  client_num = "",
  client_bsl_num = "",
  client_company_name = "",
  client_country = "",
  client_zipcode = "",
  client_city = "",
  client_adress_1 = "",
  client_adress_2 = "",
  client_adress_3 = "",
  client_tva_num = "",
  client_cost_center = "",
  client_comment = "",
  client_data_branch = [],
  client_data_contact = [],
  handleIsUpdateOn = () => {},
}) => {
  //Permissions
  const can_update_client = useAccess(11);

  return (
    <>
      <div className="d-flex justify-content-end mt-3">
        {can_update_client && (
          <FormButton
            buttonType="button"
            buttonValue="Modifier"
            buttonClass="btn btn-outline-primary input-label-size me-2
        mb-3"
            hasIcon={true}
            iconClass="fas fa-pen me-2"
            closeModal={false}
            buttonOnClick={() => handleIsUpdateOn()}
          />
        )}
      </div>

      <FormCard title="Général">
        <ListGroup>
          <ListItems>
            <div className="d-flex p-0 m-0">
              <ListItemContents
                label="N° client"
                value={client_num}
                contentClass={`col-md-6 col-sm-12`}
              />
              <ListItemContents
                label="N° client BSL"
                value={client_bsl_num}
                contentClass={`col-md-6 col-sm-12`}
              />
            </div>
          </ListItems>
          <ListItems>
            <ListItemContents
              label="Nom de la société"
              value={client_company_name}
            />
          </ListItems>
          <ListItems>
            <div className="d-flex p-0 m-0">
              <ListItemContents
                label="Pays"
                value={client_country}
                contentClass="col "
              />
              <ListItemContents
                label="Code Postal"
                value={client_zipcode}
                contentClass="col "
              />
              <ListItemContents
                label="Ville"
                value={client_city}
                contentClass="col "
              />
            </div>
          </ListItems>
          <ListItems>
            <ListItemContents label="Adresse 1" value={client_adress_1} />
          </ListItems>
          <ListItems>
            <ListItemContents label="Adresse 2" value={client_adress_2} />
          </ListItems>
          <ListItems>
            <ListItemContents label="Adresse 3" value={client_adress_3} />
          </ListItems>
          <ListItems>
            <ListItemContents
              label="N° TVA Intracommunautaire"
              value={client_tva_num}
            />
          </ListItems>
          <ListItems>
            <ListItemContents label="Cost Center" value={client_cost_center} />
          </ListItems>
        </ListGroup>
      </FormCard>
      <FormCard title="Commentaire">
        <ListGroup>
          <ListItems>
            <ListItemContents label="Commentaire" value={client_comment} />
          </ListItems>
        </ListGroup>
      </FormCard>
    </>
  );
};

export default DisplayCurrentClientGeneral;
