import React from "react";

const ListGroup = ({ children }) => {
  return (
    <>
      <ul className="list-group list-group-flush">{children}</ul>
    </>
  );
};
export default ListGroup;
