import React, { useEffect, useMemo, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./../../styles/stats/barChart.css";
import FilterRound from "../Form/FilterRound";
import { monthTranslations, monthColors } from "../../data/BarChartCustom";
function BarChartCustom({
  data_bar,
  setAnnee,
  annee,
  chartMenu = [],
  currentActiveTab = "nb_commande",
}) {
  let chart_key = 0;

  let all_data = useMemo(() => {
    chart_key = updateKey(chart_key + 1);
    return data_bar;
  }, [data_bar]);

  let hasData = true;
  const chartRef = useRef(null);

  all_data?.ca?.data?.forEach((data) => {
    data.y !== 0 && (hasData = false);
  });

  const [activeTab, setActiveTab] = useState(currentActiveTab);
  const [data_menu, setData_menu] = useState();

  const commandeStatutColor = {
    Annulé: "#544FC5",
    Dédouanement: "#2CAFFE",
    Encours: "#00E272",
    Encoursdelivraisonlastmile: "#6B8ABC",
    Entransit: "#FE6A35",
    Envoyéenfacturation: "2EE0CA",
    Expédié: "#FA4B42",
    Livré: "#D568FB",
  };

  const init_nb_commandes = (activeTab) => {
    const categories =
      all_data[activeTab]?.categorie[0] !== undefined
        ? all_data[activeTab]?.categorie[0]
        : [];
    const seriesData =
      all_data[activeTab]?.data.map((item) => ({
        data: item.data,
        name: item?.name,
        color: commandeStatutColor[item?.name.replace(/\s/g, "")] || "#dddddd",
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y > 0
              ? this.y + " " + all_data[activeTab]?.devis
              : null;
          },
        },
      })) ?? [];

    return {
      chart: {
        type: "column",
        height: 500,
      },
      title: {
        text: `${annee - 1}-${annee}`,
        align: "center",
        style: {
          fontSize: "15px",
          fontWeight: "normal",
        },
      },
      xAxis: {
        categories,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Nombre de commandes",
        },
        stackLabels: {
          enabled: true,
          formatter: function () {
            return this.total > 0
              ? this.total + " " + all_data[activeTab]?.devis
              : "";
          },
        },
      },
      legend: {
        align: "left",
        x: 10,
        verticalAlign: "top",
        y: 2,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || "white",
        borderColor: "#CCC",
        borderWidth: 1,
        shadow: false,
      },
      tooltip: {
        headerFormat: "<b>{point.x}</b><br/>",
        pointFormat:
          "{series.name}: {point.y}<br/>Total: {point.stackTotal} " +
          all_data[activeTab]?.devis,
      },
      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
            verticalAlign: "center",
          },
        },
      },
      series: [...seriesData],
    };
  };

  const init_ca_marges = (
    dataTabs = ["ca_realise", "marge_realise"],
    dataStacks = ["CA", "Marges"],
    yAxisTitle = ""
  ) => {
    const categories = all_data[dataTabs[1]]?.categorie?.map(
      (categorie) => categorie?.username
    );
    let yMax = 0;
    const ca_data =
      all_data[dataTabs[0]]?.cas_months?.map((ca_month) => {
        const data = ca_month?.data || [];
        return {
          name: monthTranslations[ca_month?.name.trim()],
          id: monthTranslations[ca_month?.name.trim()],
          data: data?.map((value) => {
            if (value?.ca > yMax) {
              yMax = value?.ca;
            }
            return value?.ca > 0 ? value?.ca : null;
          }),
          dataLabels: {
            enabled: true,
            verticalAlign: "middle",
            overflow: "none",
            crop: true,
            //y: 20,
            formatter() {
              return `${this.y} €`;
            },
          },
          stack: dataStacks[0],
          color: monthColors[ca_month?.name.trim()],
        };
      }) ?? [];

    const marges_data =
      all_data[dataTabs[1]]?.margins_months?.map((margin_month) => {
        const data = margin_month?.data || [];
        return {
          linkedTo: monthTranslations[margin_month?.name.trim()],
          data: data?.map((value) => {
            if (value?.margin > yMax) {
              yMax = value?.margin;
            }
            return value?.margin > 0 ? value?.margin : null;
          }),
          dataLabels: {
            enabled: true,
            verticalAlign: "middle",
            overflow: "none",
            crop: false,
            //y: 20,
            formatter() {
              return `${this.y} €`;
            },
          },
          stack: dataStacks[1],
          color: monthColors[margin_month?.name.trim()],
        };
      }) ?? [];

    const seriesData = [...ca_data, ...marges_data];
    return {
      chart: {
        type: "column",
        marginBottom: 50,
        scrollablePlotArea: {
          minWidth: categories?.length * 200 ?? 1600,
          scrollPositionX: 0,
        },
      },

      title: {
        text: `${annee - 1}-${annee}`,
        align: "center",
        style: {
          fontSize: "15px",
          fontWeight: "normal",
        },
      },

      xAxis: {
        categories: categories,
      },

      yAxis: {
        allowDecimals: true,
        min: 0,
        title: {
          text: yAxisTitle,
        },
        stackLabels: {
          enabled: true,
          verticalAlign: "top",
          overflow: "none",
          crop: false,
          formatter: function () {
            return `${this.stack} <br/>${
              this.total > 0 ? `${this.total} €` : ""
            }`;
          },
        },
      },
      legend: {
        align: "left",
        x: 10,
        verticalAlign: "top",
        y: 2,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || "white",
        borderColor: "#CCC",
        borderWidth: 1,
        shadow: false,
      },
      tooltip: {
        headerFormat: "<b>{point.x}</b></br>",
        pointFormat:
          "{series.name}: {point.y} €<br/>Total: {point.stackTotal} €",
      },

      plotOptions: {
        column: {
          stacking: "normal",
          pointMargin: 0.25,
        },
      },

      series: seriesData,
    };
  };

  const init_demandes_traites_valides = (
    dataTabs = ["nb_demande", "commandes_traites"],
    dataStacks = ["Traitées", "Validées"],
    yAxisTitle = ""
  ) => {
    const categories = all_data[dataTabs[1]]?.categorie?.map(
      (categorie) => categorie?.username
    );
    let yMax = 0;
    const req_traite_data =
      all_data[dataTabs[0]]?.req_traites_months?.map((req_month) => {
        const data = req_month?.data || [];
        return {
          name: monthTranslations[req_month?.name.trim()],
          id: monthTranslations[req_month?.name.trim()],
          data: data?.map((value) => {
            if (value?.req_traite > yMax) {
              yMax = value?.req_traite;
            }
            return value?.req_traite > 0 ? value?.req_traite : null;
          }),
          dataLabels: {
            enabled: true,
            verticalAlign: "middle",
            overflow: "none",
            crop: false,
            //y: 20,
            formatter() {
              return `${this.y}`;
            },
          },
          stack: dataStacks[0],
          color: monthColors[req_month?.name.trim()],
        };
      }) ?? [];

    const req_valide_data =
      all_data[dataTabs[1]]?.req_traites_months?.map((req_month) => {
        const data = req_month?.data || [];
        return {
          linkedTo: monthTranslations[req_month?.name.trim()],
          data: data?.map((value) => {
            if (value?.req_traite > yMax) {
              yMax = value?.req_traite;
            }
            return value?.req_traite > 0 ? value?.req_traite : null;
          }),
          dataLabels: {
            enabled: true,
            verticalAlign: "middle",
            overflow: "none",
            crop: false,
            //y: 20,
            formatter() {
              return `${this.y}`;
            },
          },
          stack: dataStacks[1],
          color: monthColors[req_month?.name.trim()],
        };
      }) ?? [];

    const seriesData = [...req_traite_data, ...req_valide_data];
    return {
      chart: {
        type: "column",
        height: 520,
        marginBottom: 50,
        scrollablePlotArea: {
          minWidth: categories?.length * 200 ?? 1600,
          scrollPositionX: 0,
        },
      },

      title: {
        text: `${annee - 1}-${annee}`,
        align: "center",
        style: {
          fontSize: "15px",
          fontWeight: "normal",
        },
      },

      xAxis: {
        categories: categories,
      },

      yAxis: {
        allowDecimals: true,
        min: 0,
        title: {
          text: yAxisTitle,
        },
        stackLabels: {
          enabled: true,
          verticalAlign: "top",
          align: "center",
          useHTML: true,
          style: {
            textAlign: "center",
          },
          overflow: "none",
          crop: false,
          formatter: function () {
            return `${this.stack} <br>${this.total > 0 ? `${this.total}` : ""}`;
          },
        },
      },

      legend: {
        align: "left",
        x: 70,
        verticalAlign: "top",
        y: 0,
        floating: false,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || "white",
        borderColor: "#CCC",
        borderWidth: 1,
        shadow: false,
      },

      tooltip: {
        headerFormat: "<b>{point.x}</b></br>",
        pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
      },

      plotOptions: {
        column: {
          stacking: "normal",
        },
      },

      series: seriesData,
    };
  };

  function updateKey(key_update) {
    return key_update;
  }

  const [data_option, setData_option] = useState({});

  useEffect(() => {
    setData_menu(chartMenu);
  }, [chartMenu]);

  useEffect(() => {
    switch (true) {
      case all_data[activeTab]?.data !== undefined: {
        setData_option(init_nb_commandes(activeTab));
        break;
      }
      case activeTab === "nb_demande": {
        setData_option(
          init_demandes_traites_valides(
            ["nb_demande", "commandes_traites"],
            ["Traitées", "Validées"],
            "Nombre demandes traitées / validées"
          )
        );
        break;
      }
      case activeTab === "ca_marge": {
        setData_option(
          init_ca_marges(
            ["ca_realise", "marge_realise"],
            ["CA", "Marges"],
            "Montant CA / Marges"
          )
        );
        break;
      }
      default: {
      }
    }
  }, [activeTab, chart_key]);

  const clickFilter = (key) => {
    setData_menu(data_menu?.map((v) => ({ ...v, selected: v.key === key })));
    setActiveTab(key);
  };

  const nextClick = () => {
    setAnnee(annee + 1);
    // execute setAll_data here
    chart_key = updateKey(chart_key + 1);
  };
  const prevClick = () => {
    setAnnee(annee - 1);
    // execute setAll_data here
    chart_key = updateKey(chart_key + 1);
  };

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        const chart = chartRef.current.chart;
        chart.reflow(); // Redimensionner le graphique
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let data = all_data;
    setActiveTab(Object.keys(data)[0]);
  }, [all_data]);

  return (
    <>
      <div className="card w-100 bsl-bar-chart-card">
        <div className="card-header">
          <div className="w-100 p-1">
            <div className="table-tabs border-bottom-0 p-1">
              <div className="tabs-rows">
                {data_menu?.map((v, i) => (
                  <FilterRound
                    key={i}
                    text={v.label}
                    number={v.number}
                    is_active={v.selected}
                    onClick={() => clickFilter(v.key)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="card-body w-100">
          <div className="row w-100 row-bar-chart">
            <div className="col ">
              <i
                className="float-left fas fa-angle-left cursor-pointer ms-4 mb-0"
                onClick={prevClick}
              ></i>
            </div>
            <div className="col">
              {annee < new Date().getFullYear() && (
                <i
                  className="float-right fas fa-angle-right cursor-pointer"
                  onClick={nextClick}
                ></i>
              )}
            </div>
          </div>
          <HighchartsReact
            key={chart_key}
            highcharts={Highcharts}
            options={data_option}
            ref={chartRef}
          />
        </div>
      </div>
    </>
  );
}

export default BarChartCustom;
