import React, { useMemo, useEffect, useState, useRef } from "react";
import Form from "../../../Form/Form";
import ModalForAddNew from "../../ModalForAddNew";
import FormSection from "../../../Form/FormSection";
import FormButton from "../../../Form/FormButton";
import SelectBox from "../../../Form/SelectBox";
import CheckBox from "../../../Form/CheckBox";
import Input from "../../../Form/Input";
import validator from "validator";
import "../../../../styles/Form/input.css";
// import Input2 from "../../Form/Input2";
import Swal from "sweetalert2";
import { FetchData } from "../../../../hooks/FetchData";
import ErrorList from "../../../Form/ErrorList";
import { useAccess } from "../../../../hooks/useAccess";

const UpdateRole = ({
  permissions = [],
  getUsers = () => {},
  getUserRole = () => {},
  connectedUser = {},
  role = {},
  modalData = {},
  isShowModal = false,
  handleCloseModal = function () {},
}) => {
  //User permissions
  const can_update_access = useAccess(49);
  const can_update_role = useAccess(52);

  const formRef = useRef(null);
  //state des inputs
  const [user_role_id, set_user_role_id] = useState(0);
  const [user_role_name, set_user_role_name] = useState("");

  const [accee_names, set_accee_names] = useState(permissions);

  //Stocker les erreur
  const [error_message, setError_message] = useState({
    user_role_name: "",
  });

  //les fields requierd
  const required_input = "user_role_name";

  useEffect(() => {
    if (!isEmptyObject(role)) {
      set_user_role_id(role.user_role_id);
      set_user_role_name(role.user_role_name);
      set_accee_names((v) =>
        permissions.map((ang) => {
          ang.features = ang?.features?.map((feature) => {
            feature.selected = role?.alr_accee_name_ids?.includes(
              feature.an_id
            );
            return feature;
          });
          return ang;
        })
      );
    } else {
      set_user_role_id(0);
      set_user_role_name("");
    }

    setError_message({
      user_role_name: "",
    });
    setError_list([]);
  }, [role]);

  const [error_list, setError_list] = useState([]);

  //handle scroll to top on errors
  const modalBodyUpdateRole = useRef(null);

  // handle update role form submitting
  const [isSubmittingUpdateRole, setIsSubmittingUpdateRole] = useState(false);

  //components functions

  const handleRoleUpdate = async (e, role) => {
    e.preventDefault();
    setIsSubmittingUpdateRole(true);

    try {
      const { loading, data, errors } = await FetchData(
        `/api/user/user-role/${user_role_id}`,
        {
          method: "PUT",
          body: JSON.stringify(role),
        }
      );
      if (data.status === 200) {
        Swal.fire({
          title: "Succès",
          text: "Modification du profil effectuée.",
          icon: "success",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
        getUsers();
        handleCloseModal();
        setError_message({
          user_role_name: "",
        });
      } else {
        Swal.fire({
          title: "Erreur!",
          text: data.error,
          icon: "error",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error(
        "\n\nErreur lors de la modification du profil:{\n",
        error,
        "\n}\n"
      );
    }
    setIsSubmittingUpdateRole(false);
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  function resetError() {
    handleCloseModal();
    setError_message({
      user_role_name: "",
    });
    setError_list([]);
  }

  function inputIsEmptyError(key) {
    const form = new FormData(formRef.current);
    let isEmpty = false;
    if (required_input.includes(key)) {
      if (form.get(key) == "" || !form.get(key)) {
        isEmpty = true;
      }
    }

    return isEmpty;
  }

  const testInputValueOnSubmit = (e) => {
    e.preventDefault();

    const inputs = new FormData(formRef.current);
    const errorListLabel = {
      user_role_name: "Nom du profil",
    };
    let newErrorList = [];
    let errors = error_message;
    let validForm = true;
    for (const key of inputs.keys()) {
      if (inputIsEmptyError(key)) {
        errors[key] = "Champ non-vide";
        errors = { ...errors, key: "Champ non-vide" };
        newErrorList.push(errorListLabel[key]);
        validForm = false;
      }
    }
    setError_list(newErrorList);
    setError_message(errors);
    if (validForm) {
      const updated_user_role = {
        user_role_name: user_role_name,
        user_role_active: role?.user_role_active,
        user_role_user_id_create: role?.user_role_user_id_create,
        user_role_user_id_update: connectedUser?.id,
      };
      handleRoleUpdate(e, updated_user_role);
    } else {
      modalBodyUpdateRole.current.scrollTop = 0;
    }
  };

  const handleCheckPermission = async (e, an_id, an_id_group_name) => {
    try {
      set_accee_names((ang) => {
        ang = ang.map((group) => {
          if (group?.an_id_group_name === an_id_group_name) {
            group.features = group?.features?.map((feature) => {
              if (feature?.an_id === an_id) {
                feature.selected = e;
              }
              return feature;
            });
          }

          return group;
        });

        return ang;
      });

      const accee_link_role = {
        alr_user_role_id: role?.user_role_id,
        alr_accee_name_id: an_id,
        alr_active: e === true ? 1 : 0,
        alr_user_id_create: connectedUser?.id,
        alr_user_id_update: connectedUser?.id,
      };
      const url = "api/user/accee-link-role/";
      const options = {
        method: "POST",
        body: JSON.stringify({ accee_link_role: accee_link_role }),
      };
      const { loading, data, errors } = await FetchData(url, options);
      if (data.status === 200) {
        getUserRole(role?.user_role_id);
      } else {
        console.error(
          `\n\nERREUR: Erreur lors de la modification de la permission : \n ${data.error}\n\n`
        );
      }
    } catch (error) {
      console.error(
        `\n\nERREUR: Erreur lors de la modification de la permission \n${error}\n\n`
      );
    }
  };

  return (
    <ModalForAddNew modalData={modalData} isShowModale={isShowModal}>
      <div className="modal-header container-fluid">
        <h1 className="modal-title fs-5 ms-2" id={modalData?.modalId + "Label"}>
          {role?.user_role_name}
        </h1>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={resetError}
        ></button>
      </div>
      <Form
        formData={{
          type: "form",
          id: "updateRole",
          formMethod: "post",
          formAction: "#",
          formClass: "",
        }}
        submitFunction={testInputValueOnSubmit}
        formRef={formRef}
      >
        <div ref={modalBodyUpdateRole} className="modal-body p-0">
          {error_list.length > 0 ? <ErrorList errorList={error_list} /> : null}
          <FormSection
            formSectionData={{
              type: "section",
              title: "Profil",
              sectionClass: "border-bottom",
            }}
          >
            <Input
              type={"text"}
              blockClass={"mb-3 me-2 col"}
              id={"user_role_id"}
              labelClass={""}
              label={"Numéro du profil"}
              inputClass=""
              placeholder="Numéro du profil"
              isRequired={false}
              isDisabled={true}
              errorMessage={error_message["user_role_id"]}
              value={user_role_id}
            />
            <div className="d-flex flex-row">
              <Input
                type="text"
                blockClass="mb-3 me-2 col"
                id="user_role_name"
                labelClass=""
                label="Nom du profil"
                inputClass="custom-input-colors"
                placeholder="Nom du profil"
                isRequired={true}
                isDisabled={
                  role?.user_role_name !== "Super admin"
                    ? !can_update_role
                    : !(
                        can_update_role &&
                        connectedUser?.user_role_name === "Super admin"
                      )
                }
                errorMessage={error_message["user_role_name"]}
                value={user_role_name}
                onChange={(e) => set_user_role_name(e)}
              />
            </div>
            {(role?.user_role_id !== 1
              ? can_update_role
              : can_update_role && connectedUser?.id_role === 1) && (
              <div className={"d-flex justify-content-end mt-2 border-top-0"}>
                <FormButton
                  buttonType="button"
                  buttonValue="Annuler"
                  buttonClass="btn text-secondary input-label-size me-2"
                  hasIcon={true}
                  iconClass="far fa-times-circle me-2 bg-light"
                  closeModal={true}
                  buttonOnClick={resetError}
                />
                <FormButton
                  buttonType="submit"
                  buttonValue="Modifier profil"
                  buttonClass="btn green-button-bsl input-label-size me-2"
                  hasIcon={true}
                  iconClass="fas fa-check me-2"
                  closeModal={false}
                  disable={isSubmittingUpdateRole}
                />
              </div>
            )}
          </FormSection>
          {(role?.user_role_id > 1
            ? can_update_access
            : can_update_access && connectedUser?.id_role === 1) && (
            <FormSection
              formSectionData={{
                type: "section",
                title: "Permissions",
                sectionClass: "border-bottom",
              }}
            >
              {accee_names.map((accee_name_group, ang_index) => (
                <div
                  key={`ang_${ang_index}`}
                  className=" pt-2 position-relative border border-2  rounded-2 mb-4 p-2 pt-3"
                >
                  <div
                    className={
                      "position-absolute top-0 start-10 translate-middle-y bg-white p-1 m-0 "
                    }
                  >
                    <p className="input-label-size mt-1 mb-1">
                      {accee_name_group?.ang_name}
                    </p>
                  </div>
                  <div className="d-flex flex-wrap">
                    {accee_name_group?.features?.map((feature, index_f) => (
                      <div key={`feature_${index_f}`} className="col-4">
                        <CheckBox
                          id={feature?.an_id}
                          className="form-check-input cursor-pointer me-3 input-text-size"
                          value={feature.selected}
                          label={`${feature?.an_id} - ${feature?.an_name}`}
                          labelClass={` text-wrap`}
                          onChange={(e) => {
                            handleCheckPermission(
                              e,
                              feature?.an_id,
                              accee_name_group?.an_id_group_name
                            );
                          }}
                          isDisale={false}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </FormSection>
          )}
        </div>
      </Form>
    </ModalForAddNew>
  );
};

export default UpdateRole;
