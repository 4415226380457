import React, { useEffect, useRef, useState } from "react";
import "./../../styles/Tables/tableHead.css";
import SearchDropdown from "../Form/SearchDropdown";
import DateRangeFilter from "../Form/DateRangeFilter";
import { useDispatch, useSelector } from "react-redux";
import { setTableHeadControl } from "../../store/table/tableSlice";
import { useAccess } from "../../hooks/useAccess";

/**
 * Control header for table : ther are the search bar, export butoon, delete and change status button
 *
 * @param {{onSearch: Function, searchValue: string, onClickExport: Function, onClickDelete: Function, onClickChangeStatus: Function, disableDelete: boolean, disableChangeStatus: boolean}} props
 * @returns component
 * @author Johns David
 */
function TableHeadControl({
  searchValue = "",
  onSearch,
  onClickExport,
  onClickDelete,
  onClickChangeStatus,
  disableDelete = false,
  disableChangeStatus = false,
  show_client_filter = false,
  show_date_filter = false,
  dropdownLabel = "Tous les clients",
  data_dropdown_list,
  label_delete = "",
  data_btn_control = {
    show_export_btn: true,
    show_delete_btn: true,
    show_change_status_btn: true,

    infosInvoice: {
      show_invoice_btn: false,
      count_order_invoice: 5,
    },
  },
  show_search_input = true,
  ref_name_page = "cle",
  data_ref = "id",
  onSendInvoice = () => {},
  branch_dropdown_list = [],
}) {
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const can_filter_branch = useAccess(86);
  const actual_key = useSelector((state) => state.filterTable.actual_key);
  const ref_identifiers = useSelector((state) => state.dropdown.ref_identifier);
  const selected_client = useSelector((state) => state.dropdown.value_search);
  const can_show_branch_dropdown =
    can_filter_branch &&
    ref_identifiers.includes("branche") &&
    selected_client?.filter((d) => d.name === ref_identifiers[0])[0]?.data
      ?.length > 0 &&
    actual_key === "client";

  useEffect(() => {
    let heigthDiv = elementRef?.current?.clientHeight;
    dispatch(setTableHeadControl(heigthDiv ? heigthDiv : 0));
  }, [elementRef]);
  const serch = (e) => {
    onSearch(e.target.value, data_ref);
  };
  return (
    <>
      <div className="row w-100 pt-4 ps-4 mb-2" ref={elementRef}>
        <div className="col-md-6 d-flex gap-1 align-items-center flex-wrap">
          <div className="input-group thc-search " style={{ width: 130 }}>
            {show_search_input && (
              <>
                <span className="input-group-text" id="basic-addon1">
                  <i className="fas fa-search"></i>
                </span>

                <input
                  type="text"
                  value={searchValue}
                  onChange={serch}
                  className="form-control normal-font-size bsl-serach-input ps-1 pe-0"
                  placeholder={"Rechercher"}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </>
            )}
          </div>
          {show_client_filter && (
            <SearchDropdown
              data_dropdown_list={data_dropdown_list}
              ref_name_page={ref_name_page}
            />
          )}

          {show_date_filter && <DateRangeFilter />}

          {can_show_branch_dropdown && (
            <SearchDropdown
              data_dropdown_list={branch_dropdown_list}
              ref_name_page={ref_name_page}
              ref_identifier="branche"
              custom_data_label={{
                title: "Toutes les branches",
                select_all: "Toutes les branches",
              }}
            />
          )}
        </div>
        <div className="col-md-6 ms-auto2 text-lg-end">
          {data_btn_control.show_export_btn == true && (
            <button
              onClick={onClickExport}
              type="button"
              className="btn btn-outline-primary mx-2 mb-2 normal-font-size "
            >
              <i className="fas fa-upload"></i> Tout exporter
            </button>
          )}
          {/* className="btn btn-outline-primary mx-2 mb-2 normal-font-size " */}
          {data_btn_control?.infosInvoice?.show_invoice_btn === true && (
            <button
              onClick={onSendInvoice}
              type="button"
              className={
                "btn mx-1 mb-2 normal-font-size " +
                (data_btn_control?.infosInvoice?.count_order_invoice > 0
                  ? "btn-outline-primary"
                  : "btn-outline-secondary")
              }
              disabled={
                data_btn_control?.infosInvoice?.count_order_invoice > 0
                  ? false
                  : true
              }
            >
              <i className="fa fa-briefcase me-2"></i> Envoyer en facture{" "}
              {data_btn_control?.infosInvoice?.count_order_invoice != 0 && (
                <span className="badge text-bg-secondary ms-2">
                  {data_btn_control?.infosInvoice?.count_order_invoice}
                </span>
              )}
            </button>
          )}
          {data_btn_control.show_delete_btn == true && (
            <button
              onClick={onClickDelete}
              type="button"
              className={
                "btn mx-1 mb-2 normal-font-size " +
                (disableDelete
                  ? "btn-outline-primary"
                  : "btn-outline-secondary")
              }
              disabled={disableDelete ? false : true}
            >
              <i className="fas fa-trash"></i> {label_delete}
            </button>
          )}
          {data_btn_control.show_change_status_btn == true && (
            <button
              onClick={onClickChangeStatus}
              type="button"
              className={
                "btn mx-1 mb-2 normal-font-size " +
                (disableChangeStatus
                  ? "btn-outline-primary"
                  : "btn-outline-secondary")
              }
              disabled={disableChangeStatus ? false : true}
            >
              <i className="fas fa-exchange"></i> Changer les statuts
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default TableHeadControl;
