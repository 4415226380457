import React, { useState } from "react";
import TableHeadControl from "./TableHeadControl";
import "./../../styles/Tables/table.css";
import TableHeadFilter from "./TableHeadFilter";
import TableContent from "./TableContent";
import useSearchTable from "../../hooks/table/useSearchTable";
import { useSelector } from "react-redux";

const Table = ({
  data_table = [],
  table_headers = [],
  table_key = [],
  filter = [],
  active_index_filter = 0,
  search_key = [],
  object_serach_key = ["statut"],
  list_not_normal = ["statut", "check"],
  show_filter_bar = true,
  show_client_filter = false,
  show_date_filter = false,
  data_dropdown_list,
  label_delete = "",
  data_btn_control = {
    show_export_btn: true,
    show_delete_btn: true,
    show_change_status_btn: true,
  },
  show_search_input = true,
  ref_name_page = "cle",
  goUrl = false,
  filter_ref = "statut",
  data_ref = "id",
  onChangeStatus = () => {
    console.log("Change status Clicked");
  },
  onDelete = () => {
    console.log("Delete Clicked");
  },
  onExport = () => {
    console.log("Export Clicked");
  },
  onSendInvoice = () => {
    console.log("Send Invoice Clicked");
  },
  branch_dropdown_list = [],
}) => {
  const createNew = () => {
    console.log("createNew");
  };

  // For search : input text on the control
  const { searchValue, searchChange } = useSearchTable("");

  // For Export button
  const onClickExport = () => {
    onExport();
  };

  // For delete
  const onClickDelete = () => {
    onDelete();
  };
  // For Change status
  const onClickChangeStatus = () => {
    onChangeStatus();
  };
  const onClickSendInvoice = () => {
    onSendInvoice();
  };

  const disablesControlButton = useSelector(
    (state) => state.table.disablesControlButton
  );
  const search_value = useSelector((state) => state.table.search_value);
  return (
    <>
      <div className="bsl-table">
        <TableHeadControl
          onSearch={searchChange}
          searchValue={searchValue}
          data_ref={data_ref}
          onClickExport={() => {
            onClickExport();
          }}
          onClickDelete={() => {
            onClickDelete();
          }}
          onClickChangeStatus={() => {
            onClickChangeStatus();
          }}
          disableDelete={disablesControlButton}
          disableChangeStatus={disablesControlButton}
          show_date_filter={show_date_filter}
          show_client_filter={show_client_filter}
          show_search_input={show_search_input}
          data_dropdown_list={data_dropdown_list}
          label_delete={label_delete}
          data_btn_control={data_btn_control}
          ref_name_page={ref_name_page}
          onSendInvoice={onClickSendInvoice}
          branch_dropdown_list={branch_dropdown_list}
        />
        {show_filter_bar == true && <TableHeadFilter goUrl={goUrl} />}
        <TableContent
          keys={table_key}
          headers={table_headers}
          contents={data_table}
          search_key={search_key}
          search_value={search_value}
          filter_data={filter}
          filter_ref={filter_ref}
          active_index_filter={active_index_filter}
          object_serach_key={object_serach_key}
          list_not_normal={list_not_normal}
        />
      </div>
    </>
  );
};

export default Table;
