import React, { useCallback, useEffect, useRef, useState } from "react";
import "./../../styles/Tables/tableHead.css";
import FilterRound from "../Form/FilterRound";
import { useDispatch, useSelector } from "react-redux";
// import updateFilter from "../../store/table/tableSlice";
import {
  updateFilter,
  updateIndexFilter,
  search,
  setTableHeadFilter,
} from "../../store/table/tableSlice";
import useSearchTable from "../../hooks/table/useSearchTable";
import { selectedFilter } from "../../store/table/filterTableSlice";
import { FetchData } from "../../hooks/FetchData";
import { useAccess } from "../../hooks/useAccess";

function TableHeadFilter({ goUrl = false }) {
  return <>{goUrl ? <UrlTableHeadFilter /> : <NormalTableHeadFilter />}</>;
}

function NormalTableHeadFilter() {
  const dispatch = useDispatch();
  const search_value = useSelector((state) => state.table.search_value);
  let data = useSelector((state) => state.table.filter_data);
  const { searchValue, searchChange } = useSearchTable(search_value);
  const heigthTableHeadControl = useSelector(
    (state) => state.table.heigthTableHeadControl
  );

  const elementRef = useRef(null);
  useEffect(() => {
    let heigthDiv =
      elementRef?.current?.clientHeight +
      (heigthTableHeadControl ? heigthTableHeadControl - 30 : 0);
    dispatch(setTableHeadFilter(heigthDiv ? heigthDiv : 0));
  }, [elementRef, data]);
  return (
    <>
      <div className="w-100 border-top" ref={elementRef}>
        <div className="table-tabs">
          <div className="tabs-rows">
            {data.map((v, i) => (
              <FilterRound
                key={i}
                text={v.label}
                number={v.number}
                is_active={v.selected}
                onClick={() => {
                  dispatch(updateFilter(i));
                  dispatch(updateIndexFilter(i));
                  searchChange(search_value);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

function UrlTableHeadFilter() {
  const dispatch = useDispatch();
  const can_see_ca = useAccess(87);
  const can_see_costs = useAccess(88);
  const actual_key = useSelector((state) => state.filterTable.actual_key);
  const data = useSelector((state) => state.filterTable.filter_state);
  const heigthTableHeadControl = useSelector(
    (state) => state.table.heigthTableHeadControl
  );
  const ref_identifiers = useSelector((state) => state.dropdown.ref_identifier);
  const clients_or_subcontractors = useSelector(
    (state) =>
      state.dropdown.value_search?.filter(
        (d) => d.name === ref_identifiers[0]
      )[0]?.data
  );
  const branchs_filtered = useSelector(
    (state) =>
      state.dropdown.value_search?.filter(
        (d) => d.name == ref_identifiers[1]
      )[0]?.data
  );

  const annees = useSelector((state) => state.dateRange.date_range);

  const [ca, setCa] = useState(0); //chiffre d'affaires commande
  const [costs, setCosts] = useState(0); //cout total sous-traitants

  const is_ca_and_costs_shown =
    ref_identifiers[0] === "commande_client_dropdown" &&
    clients_or_subcontractors.length > 0;

  const fetchSumCa = async (
    clients_or_subcontractors = [],
    branchs = [],
    annees = []
  ) => {
    const url =
      actual_key === "client"
        ? "/api/order/clients/branchs/ca"
        : "/api/order/subcontractors/ca/costs";
    const options = {
      method: "POST",
      body: JSON.stringify(
        actual_key === "client"
          ? {
              clients: clients_or_subcontractors,
              branchs: branchs,
              annees: annees,
            }
          : {
              subcontractors: clients_or_subcontractors,
              annees: annees,
            }
      ),
    };
    const { loading, data, errors } = await FetchData(url, options);
    if (data?.status === 200) {
      setCa(data?.ca);
      setCosts(data?.costs);
    } else {
      console.error(
        "Erreur lors de la récupération du chiffre d'affaire dans la page commande"
      );
    }
  };

  useEffect(() => {
    if (is_ca_and_costs_shown) {
      fetchSumCa(clients_or_subcontractors, branchs_filtered, [
        annees.startDate,
        annees.endDate,
      ]);
    }
  }, [clients_or_subcontractors, branchs_filtered, annees]);

  const clickFilter = useCallback((key) => {
    dispatch(selectedFilter(key));
  }, []);

  const elementRef = useRef(null);
  useEffect(() => {
    let heigthDiv =
      elementRef?.current?.clientHeight +
      (heigthTableHeadControl ? heigthTableHeadControl - 30 : 0);
    dispatch(setTableHeadFilter(heigthDiv ? heigthDiv : 0));
  }, [elementRef, data]);

  return (
    <>
      <div
        className="w-100 border-top d-flex align-items-center "
        ref={elementRef}
      >
        <div className="table-tabs w-100 d-flex justify-content-between align-items-center">
          <div className="tabs-rows">
            {data.map((v, i) => (
              <FilterRound
                key={i}
                text={v.label}
                number={v.number}
                is_active={v.selected}
                onClick={() => {
                  clickFilter(v.key);
                }}
              />
            ))}
          </div>
          {is_ca_and_costs_shown && (
            <div className="me-2 d-flex gap-2 p-2">
              {can_see_costs && actual_key !== "client" && (
                <div className="badge rounded-pill bg-primary-subtle text-primary p-2">
                  Coûts: {costs} €
                </div>
              )}
              {can_see_ca && (
                <div className="badge rounded-pill bg-primary-subtle bg-opacity-50 text-primary p-2">
                  Chiffres d'affaires: {ca} €
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default TableHeadFilter;
