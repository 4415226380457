import React from "react";
import NavBar from "../NavBar/NavBar";
// import Table from "../Tables/Table";
import "./../../styles/root/root.css";
import "./../../styles/globals.css";
/**
 * Globa content
 *
 * @param {{children: Any}} props
 * @returns component
 * @author Johns David
 */
function Admin({ children }) {
  return (
    <>
      <div className="root-content-page">
        <div className="prototype-nav">
          <NavBar />
        </div>
        <div className="content-content">{children}</div>
      </div>
    </>
  );
}

export default Admin;
