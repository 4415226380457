import React from "react";
import "./../../styles/Tables/TableLabelRound.css";
function TableLabelRound({ children, index_color = 0, otherClass = "" }) {
  const color = [
    "primary",
    "primary-subtle",
    "secondary",
    "secondary-subtle",
    "success",
    "success-subtle",
    "danger",
    "danger-subtle",
    "warning",
    "warning-subtle",
    "info",
    "info-subtle",
    "light",
    "light-subtle",
    "dark",
    "dark-subtle",
    "body-secondary",
    "body-tertiary",
    "body",
    "black",
    "white",
    "transparent",
  ];
  let index = index_color - 1 > color.length ? 0 : index_color - 1;
  return (
    <>
      {children && (
        <p
          className={
            "round-style rounded-circle bg-" + color[index] + " " + otherClass
          }
        >
          {children}
        </p>
      )}
    </>
  );
}

export default TableLabelRound;
