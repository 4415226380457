import React, { useCallback, useEffect, useState } from "react";
import Admin from "../root/Admin";
import Table from "../Tables/Table";
import TableTitle from "../Tables/TableTitle";
import { FetchData } from "../../hooks/FetchData";
import AddClient from "../Modals/Insert/Client/AddClient.js";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { initUpdate } from "../../store/table/btnIconSlice";
import { useAccess } from "../../hooks/useAccess.js";
import { RedirectUnallowed } from "../../hooks/RedirectUnallowed.js";
import {
  updateFilter,
  updateIndexFilter,
} from "../../store/table/tableSlice.js";

const ClientPage = () => {
  const dispatch = useDispatch();
  //Permissions
  const can_access_client_page = useAccess(1);
  const can_create_new_client = useAccess(8);
  const can_change_selected_clients_status = useAccess(4);
  const can_search_client = useAccess(2);
  const can_exports_clients = useAccess(3);
  const can_see_all = useAccess(5);
  const can_see_allowed = useAccess(6);
  const can_see_unallowed = useAccess(7);
  const can_see_client_info = useAccess(10);

  //Client page vars
  const [client, setClient] = useState(null);
  const [invoice, setInvoice] = useState(null);

  const [client_status, setClient_status] = useState([]);

  const [country_list, setCountryList] = useState([]);

  const data_store = useSelector((state) => state.table.data);

  const [dataTable, setDataTable] = useState([]);

  const data_ref = "client_id";

  const fetchData = async () => {
    try {
      const { loading, data, errors } = await FetchData("/api/client", {
        method: "GET",
      });
      setDataTable(data.data);
      setClient_status(data.client_status);
      setCountryList(data.country);
      return { loading, data, errors };
    } catch (error) {
      return [];
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const table_key = [
    "check",
    "client_company_name",
    "bsl_code",
    "statut",
    "client_adress_1",
    "client_created_at",
  ];
  const table_headers = {
    check: { value: "checkbox", width: 20 },
    client_company_name: { value: "Client", width: 150 },
    statut: { value: "Statut", width: 90 },
    bsl_code: { value: "Code client", width: 80 },
    client_adress_1: { value: "Adresse", width: 150 },
    client_created_at: { value: "Date d'ajout", width: 100 },
  };
  const search_key = [
    "client_company_name",
    "client_bsl_num",
    "statut",
    "cc_email",
    "cc_phone_1",
    "client_created_at",
  ];

  var all_menu = can_see_all
    ? [
        {
          label: "Tous",
          key: "All",
          selected: false,
          number: 0,
        },
      ]
    : [];
  var allowed_menu = can_see_allowed
    ? [
        {
          label: "Autorisé",
          key: "Autorisé",
          selected: false,
          number: 0,
        },
      ]
    : [];
  var unallowed_menu = can_see_unallowed
    ? [
        {
          label: "Suspendu",
          key: "Suspendu",
          selected: false,
          number: 0,
        },
      ]
    : [];
  var filter = [...all_menu, ...unallowed_menu, ...allowed_menu].map(
    (d, i) => ({
      ...d,
      selected: i === 0 ? true : false,
    })
  );

  const handleDeletes = () => {
    const clientIds = data_store
      .filter((client) => client.check === true)
      .map((client) => client.client_id);
    const pluriel = clientIds.length == 1 ? "" : "s";
    Swal.fire({
      title: "Confirmation?",
      text: `Êtes-vous certain de vouloir supprimer ce${pluriel} client${pluriel}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, supprimer!",
      confirmButtonColor: "#0e3feb",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed && clientIds) {
        const { data } = await FetchData(`/api/client/${clientIds}`, {
          method: "DELETE",
        });
        let message =
          clientIds.length == 1
            ? "Le client a été supprimé avec succès."
            : "Les clients ont été supprimés avec succès.";
        if (data.status != 200) {
          message = clientIds.length == 1 ? "du client" : "des clients";
          Swal.fire({
            title: "Erreur",
            text: `Une erreur s'est produite lors de la suppression ${message}.\n${data.error}`,
            icon: "error",
            confirmButtonColor: "#0e3feb",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Supprimé!",
            text: message,
            icon: "success",
            position: "center",
            showConfirmButton: false,
            timer: 3000,
          });
          fetchData();
        }
      }
    });
  };

  const handleChangeStatus = () => {
    const clientIds = data_store
      .filter((client) => client.check === true)
      .map((client) => client.client_id);
    const pluriel = clientIds.length == 1 ? "" : "s";
    Swal.fire({
      title: "Confirmation?",
      text: `Êtes-vous certain de vouloir changer le statut de ce${pluriel} client${pluriel}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: `Oui, changer ce${pluriel} status!`,
      confirmButtonColor: "#0e3feb",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed && clientIds) {
        const { data } = await FetchData(`/api/client/status/${clientIds}`, {
          method: "PUT",
        });
        let message = "";
        if (data.status != 200) {
          message = clientIds.length == 1 ? "du client" : "des clients";
          Swal.fire({
            title: "Erreur",
            text: `Une erreur s'est produite lors du changement de statut  ${message}.\n${data.error}`,
            icon: "error",
            confirmButtonColor: "#0e3feb",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Modifié!",
            text: "Le statut des clients a été modifié avec succès.",
            icon: "success",
            position: "center",
            showConfirmButton: false,
            timer: 3000,
          });
          fetchData();
        }
      }
    });
  };

  const list_not_normal = ["check", "statut"];
  const object_serach_key = ["statut"];
  const active_index_filter = 0;

  const client_data_btn_controls = {
    show_export_btn: can_exports_clients,
    show_delete_btn: false,
    show_change_status_btn: can_change_selected_clients_status,
  };

  const modal_data = {
    type: "modal",
    modalId: "addClientModal",
    modalClass: "modal-xl",
    title: "Nouveau client",
  };

  const data_action_td = useSelector((state) => state.btnIconSlice.data);

  // handles if modal is shown or not
  const [isShowModal, setIsShowModal] = useState(false);

  // handles why the modal is shown (1-insert; 2-update; 0-Not shown)
  const [whyShown, setWhyShown] = useState(0);

  //get client for uptade
  const getClient = async (clientId) => {
    const { loading, data, errors } = await FetchData(
      `/api/client/${clientId}`,
      {
        method: "GET",
      }
    );
    setClient(data?.data);
    setInvoice(data?.invoice);
    return { loading, data, errors };
  };

  const closeModal = useCallback(() => {
    setIsShowModal(false);
    setWhyShown(0);
  }, []);

  const showModal = useCallback((reason) => {
    setIsShowModal(true);
    setWhyShown(reason);
  }, []);

  useEffect(() => {
    // Action update here
    if (
      data_action_td.action_name === "update_client" &&
      data_action_td.action_value > 0 &&
      can_see_client_info
    ) {
      getClient(data_action_td.action_value).then(() => {
        showModal(2);
      });
      dispatch(
        initUpdate({
          action_value: -1,
        })
      );
    }
  }, [data_action_td]);

  return can_access_client_page ? (
    <Admin>
      <TableTitle
        title="Clients"
        button_name="Nouveau client"
        show_button={can_create_new_client}
        modalId={modal_data?.modalId}
        onClick={() => showModal(1)}
      />
      {(can_see_all || can_see_allowed || can_see_unallowed) && (
        <Table
          data_table={dataTable}
          table_headers={table_headers}
          table_key={table_key}
          filter={filter}
          active_index_filter={active_index_filter}
          search_key={search_key}
          list_not_normal={list_not_normal}
          object_serach_key={object_serach_key}
          onDelete={handleDeletes}
          onChangeStatus={handleChangeStatus}
          data_btn_control={client_data_btn_controls}
          data_ref={data_ref}
          show_search_input={can_search_client}
        />
      )}

      <AddClient
        modalData={modal_data}
        isShowModal={isShowModal}
        closeModal={closeModal}
        handleFetchData={fetchData}
        currentClient={client}
        data_country={country_list}
        handleCurrentClient={setClient}
        clientStatus={client_status}
        whyShown={whyShown}
        invoice={invoice}
      />
      {isShowModal == true ? (
        <div className="modal-backdrop fade show"></div>
      ) : null}
    </Admin>
  ) : (
    RedirectUnallowed("/accueil")
  );
};

export default ClientPage;
