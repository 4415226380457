import React, { useEffect, useState } from "react";
import FormButton from "../../../Form/FormButton";
import Input from "../../../Form/Input";
import { FetchData } from "../../../../hooks/FetchData";
import Swal from "sweetalert2";
import SelectBoxSearch from "../../../Form/SelectBoxSearch";
import CheckBox from "../../../Form/CheckBox";
import { useAccess } from "../../../../hooks/useAccess";

const ActionAMene = ({
  action = [],
  all_action,
  get_all_action,
  can_add_action = true,
  can_delete_action = true,
  can_update_action = true,
  idUser,
  _demande,
  update_action,
  set_update_action,
  getData,
  set_show_notif,
  handleCloseModal,
  getDemande = () => {},
}) => {
  //Permissions
  const can_validate_action_from_valid_request = useAccess(72);

  const [_action, setAction] = useState([]);
  const [new_action, setNewAction] = useState("");
  const [isAddAction, setIsAddAction] = useState(true);
  const [updatedAction, setUpdatedAction] = useState({});

  useEffect(() => {
    setAction(action);
  }, []);

  useEffect(() => {
    if (update_action === "annuler") {
      Swal.fire({
        customClass: {
          confirmButton: "btn btn-danger ms-2",
          cancelButton: "btn btn-light ",
        },
        buttonsStyling: false,
        html: `<p class="text-muted fs-6 text-start"><strong class="text-start">Annulation de la demande</strong>
        <br/> <br/>Êtes-vous sûr de vouloir annuler la demande ? <br/>Veuillez laisser un commentaire expliquant la raison de l'annulation de la demande.<br/></p>
          <div class="mb-3">
          <br/>
            <textarea class="form-control input-label-size" id="comments" rows="4" placeholder="Commentaire"></textarea>
          </div>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Annuler la demande",
        cancelButtonText: "Retour",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const commentaire = document.getElementById("comments").value;
          annuleEtRefuserDemande(commentaire);
        }
      });
    } else if (update_action === "refuser") {
      Swal.fire({
        customClass: {
          confirmButton: "btn btn-danger ms-2",
          cancelButton: "btn btn-light ",
        },
        buttonsStyling: false,
        title: "",
        html: `<p class="text-muted fs-6 text-start"><strong class="text-start">Refus de la demande</strong><br><br>
        Êtes-vous sûr de vouloir refuser la demande ? <br/>
        Veuillez laisser un commentaire expliquant la raison du refus de la demande.<br/></p>
          <div class="mb-3">
          <br/>
            <textarea class="form-control input-label-size" id="comments" rows="4" placeholder="Commentaire"></textarea>
          </div>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Refuser la demande",
        cancelButtonText: "Retour",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const commentaire = document.getElementById("comments").value;
          annuleEtRefuserDemande(commentaire);
        }
      });
    } else if (update_action === "valider") {
      Swal.fire({
        customClass: {
          confirmButton: "btn btn-success ms-2",
          cancelButton: "btn btn-light",
        },
        buttonsStyling: false,
        title: "",
        html: `<p class="fs-6 text-start"><strong class="text-start">Validation de la demande</strong><br><br>
        La demande sera validée et transmise aux logisticiens.<br/>
        Vérifiez de bien avoir ajouté toutes les actions à mener avant de valider la demande.<br/></p>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Valider la demande",
        cancelButtonText: "Annuler",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          validerDemande();
        }
      });
    }
    set_update_action("");
  }, [update_action]);

  const addAction = async () => {
    try {
      let notif;
      const { loading, data, errors } = await FetchData("/api/request/action", {
        method: "POST",
        body: JSON.stringify({ ra_name: new_action }),
      });
      if (data?.status === 200) {
        notif = {
          title: "Succès",
          text: "Action bien enregistrée.",
          icon: "success",
        };
        get_all_action();
        setNewAction("");
        setIsAddAction(!isAddAction);
      } else {
        const { erreur } = data;
        notif = {
          title: "Erreur!",
          text: data.error,
          icon: "error",
        };
      }
      Swal.fire({
        title: notif.title,
        text: notif.text,
        icon: notif.icon,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
      });
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  const addActionToRequest = async (action) => {
    try {
      let notif;
      const { loading, data, errors } = await FetchData(
        "/api/request/action-link",
        {
          method: "POST",
          body: JSON.stringify({
            rla_req_id: _demande?.req_id,
            rla_req_action_id: action,
            rla_user_id_create: idUser,
            rla_user_id_update: idUser,
          }),
        }
      );
      if (data?.status === 200) {
        setAction(data.data);
        set_show_notif(false);
      } else {
        const { erreur } = data;
        notif = {
          title: "Erreur!",
          text: data.error,
          icon: "error",
        };
        Swal.fire({
          title: notif.title,
          text: notif.text,
          icon: notif.icon,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  const updateActionOfRequest = async (action) => {
    await addActionToRequest(action);
    await deleteAction(updatedAction?.rla_id);
    setUpdatedAction({});
  };

  const annuleEtRefuserDemande = async (commentaire) => {
    let notif;
    try {
      const { loading, data, errors } = await FetchData(
        `/api/request/invalid`,
        {
          method: "POST",
          body: JSON.stringify({
            ri_req_id: _demande?.req_id,
            ri_comment: commentaire,
            ri_user_id_create: idUser,
            ri_user_id_update: idUser,
            req_id_status: update_action === "annuler" ? 4 : 5,
          }),
        }
      );
      if (data?.status === 200) {
        notif = {
          title: "Succès",
          text:
            update_action === "annuler"
              ? "Demande annulée."
              : "Demande refusée",
          icon: "success",
        };
        getData();
        handleCloseModal();
      } else {
        const { erreur } = data;
        notif = {
          title: "Erreur!",
          text: data.error,
          icon: "error",
        };
      }

      Swal.fire({
        title: notif.title,
        text: notif.text,
        icon: notif.icon,
        position: "center",
        showConfirmButton: false,
        timer: 1000,
      });
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };
  const validerDemande = async () => {
    let notif;
    try {
      const { loading, data, errors } = await FetchData(
        `/api/request/validate/${_demande?.req_id}`,
        {
          method: "PUT",
          body: JSON.stringify({
            req_user_id_update: idUser,
          }),
        }
      );
      if (data?.status === 200) {
        notif = {
          title: "Succès",
          text: "Demande validée",
          icon: "success",
        };
        getData();
        handleCloseModal();
      } else {
        const { erreur } = data;
        notif = {
          title: "Erreur!",
          text: data.error,
          icon: "error",
        };
      }

      Swal.fire({
        title: notif.title,
        text: notif.text,
        icon: notif.icon,
        position: "center",
        showConfirmButton: false,
        timer: 1000,
      });
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };
  const deleteAction = async (index) => {
    let notif;
    try {
      const { loading, data, errors } = await FetchData(
        `/api/request/action-link/${index}`,
        {
          method: "DELETE",
        }
      );
      if (data?.status === 200) {
        _action.length === 1 && set_show_notif(true);
        getData();
        setAction(data.data);
        getDemande(_demande.req_id);
      } else {
        const { erreur } = data;
        notif = {
          title: "Erreur!",
          text: data.error,
          icon: "error",
        };
        Swal.fire({
          title: notif.title,
          text: notif.text,
          icon: notif.icon,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  const onDeleteAction = (index) => {
    Swal.fire({
      title: "Êtes-vous sûr?",
      text: "Voulez-vous supprimer cette action ?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui, supprimer!",
      confirmButtonColor: "#0e3feb",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAction(index);
      }
    });
  };

  const onUpdateAction = (index) => {
    setUpdatedAction(action?.filter((action) => action.rla_id == index)[0]);
  };

  const onChangeSelecteBox = async (e, id) => {
    try {
      const { loading, data, errors } = await FetchData(
        `/api/request/action-link/${id}`,
        {
          method: "PUT",
          body: JSON.stringify({
            rla_req_id: _demande.req_id,
            rla_req_action_id: id,
            rla_done: e,
            rla_user_id_update: idUser,
          }),
        }
      );
      if (data.status === 200) {
        setAction(data.data);
        getData();
        getDemande(_demande.req_id);
      } else {
        const { erreur } = data;
        Swal.fire({
          title: "Erreur!",
          text: data.error,
          icon: "error",
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  return (
    <div className="container-fluid">
      {_demande?.req_id_status === 3 || !can_add_action ? (
        <></>
      ) : (
        <div
          className="d-flex align-items-center ps-3"
          style={{ minHeight: "70px" }}
        >
          <SelectBoxSearch
            type="select"
            blockClass="col-md-5 me-2 col col-4"
            id="agent_responsable"
            labelClass={
              JSON.stringify(updatedAction) !== "{}" ? "mt-1 mb-0" : "d-none"
            }
            label="Action à modifier"
            selectClass="input-label-size "
            isRequired={false}
            selectOptions={all_action}
            onChange={
              JSON.stringify(updatedAction) !== "{}"
                ? updateActionOfRequest
                : addActionToRequest
            }
            cle="ra_id"
            value="ra_name"
            placeholder={`Choisir une action`}
            defaultValue={
              JSON.stringify(updatedAction) !== "{}"
                ? updatedAction?.rla_req_action_id
                : undefined
            }
          />

          {!isAddAction ? (
            <div
              className={`d-flex flex-row align-items-center ${
                JSON.stringify(updatedAction) !== "{}" ? "mt-4" : ""
              }`}
            >
              <Input
                type="text"
                blockClass="mb-3 me-2 col mt-3"
                // id={`req_ast_ref_id_${index}`}
                labelClass="d-none"
                label="Action"
                inputClass="custom-input-colors fs-12"
                placeholder="Action"
                value={new_action}
                onChange={setNewAction}
              />
              <div className="d-flex gap-2">
                <span
                  className="btn input-label-size mt-1 p-0 text-danger"
                  onClick={() => setIsAddAction((prevState) => !prevState)}
                >
                  <i className="far fa-times-circle fs-5"></i>
                </span>
                {new_action !== "" && (
                  <span
                    className={`btn input-label-size mt-1 p-0 text-success`}
                    onClick={addAction}
                  >
                    <i className="fa-solid fa-check fs-5"></i>
                  </span>
                )}
              </div>
            </div>
          ) : (
            <FormButton
              buttonValue={
                JSON.stringify(updatedAction) !== "{}"
                  ? "Ajouter une action à la liste déroulante"
                  : "Ajouter l'action"
              }
              buttonClass={`btn btn-outline-secondary input-label-size ${
                JSON.stringify(updatedAction) !== "{}" ? "mt-4" : ""
              }`}
              hasIcon={true}
              iconClass="fas fa-plus me-2"
              closeModal={false}
              buttonOnClick={() => setIsAddAction((prevState) => !prevState)}
            />
          )}
        </div>
      )}
      <div className="row">
        <div className="col-12">
          <div className="p-3 d-flex align-items-center justify-content-between">
            <p className="m-0 fw-bold">Actions ajoutées</p>
          </div>
        </div>
        <div className="col-12">
          <div className="p-3">
            {_action?.map((a, index) => (
              <div
                key={index}
                className={`d-flex align-items-center mb-1 p-3 bg-${
                  a.rla_done ? "dark-subtle" : "light text-muted"
                }`}
                style={{
                  color: `${a.rla_done && "#0b2fb0"}`,
                  transition: "color 0.3s, background-color 0.3s, opacity 0.3s",
                  opacity: `${a.rla_done ? 1 : 0.9}`,
                }}
              >
                {_demande?.req_id_status === 3 &&
                  can_validate_action_from_valid_request && (
                    <CheckBox
                      id={a.rla_id}
                      value={a.rla_done}
                      onChange={(e) => onChangeSelecteBox(e, a.rla_id)}
                      isDisale={_demande?.order_num_title !== null}
                    />
                  )}
                <div className="flex-grow-1">
                  {index + 1 + ". "}
                  {a?.ra_name}
                </div>
                {/* {_demande?.req_id_status !== 3 && can_update_action && (
                  <div onClick={() => onUpdateAction(a?.rla_id)}>
                    <i className="fas fa-pen text-primary me-3"></i>
                  </div>
                )} */}
                {_demande?.req_id_status !== 3 && can_delete_action && (
                  <div onClick={() => onDeleteAction(a?.rla_id)}>
                    <i className="fas fa-trash-alt text-danger"></i>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionAMene;
