import React, { useEffect } from "react";
import SelectBoxSearch from "../../../Form/SelectBoxSearch";
import FormButton from "../../../Form/FormButton";
import Swal from "sweetalert2";
import { FetchData } from "../../../../hooks/FetchData";
import { useAccess } from "../../../../hooks/useAccess";

const HeaderModal = ({
  getData = () => {},
  idUser,
  _isOnResponsDemand,
  btnClick = () => {},
  agent_change = () => {},
  data_list = [],
  modalData,
  demande,
  handleCloseModal,
  set_update_action,
  show_bouton_valide,
  can_abort_request = true,
  can_refuse_request = true,
}) => {
  const color_statut = (item) => {
    let color = null;
    if (item?.color > 0) {
      let ref = [
        "warning",
        "primary",
        "success",
        "danger",
        "secondary",
        "info",
        "light",
        "dark",
      ];
      color = ref[item.color - 1];
    }
    color = color ? color : item.disabled ? "danger" : "primary";
    return color;
  };

  return (
    <>
      <h1
        className="modal-title fs-5 ms-2 w-75 d-flex align-items-center"
        id={modalData?.modalId + "Label"}
      >
        {demande?.numero ?? "Demande"}
        <span
          className={`badge input-label-size text-${
            demande?.statut && color_statut(demande?.statut)
          } bg-light fw-light ms-2`}
        >
          {demande?.statut?.label ?? "En cours"}
        </span>
        {demande?.req_id_status === 3 && (
          <>
            <div
              className="progress ms-3"
              style={{ width: "15%", height: "7px" }}
            >
              <div
                className={`progress-bar bg-${demande?.action?.color}`}
                role="progressbar"
                style={{ width: `${demande?.action?.value}%`, height: "7px" }}
              ></div>
            </div>
            <span className="text-muted ms-3" style={{ fontSize: "15px" }}>
              {demande?.action?.text}
            </span>
          </>
        )}
      </h1>
      <TitleModal
        _isOnResponsDemand={_isOnResponsDemand}
        statut={demande?.statut?.label}
        liste={data_list}
        id_status={demande?.req_id_status}
        agent_change={agent_change}
        btnClick={btnClick}
        set_update_action={set_update_action}
        show_bouton_valide={show_bouton_valide}
        can_abort_request={can_abort_request}
        can_refuse_request={can_refuse_request}
        demande={demande}
        idUser={idUser}
        handleCloseModal={handleCloseModal}
        getData={getData}
      />
      <div className="d-flex justify-content-end ms-3">
        <button
          type="button"
          className="btn-close m-0"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleCloseModal}
        ></button>
      </div>
    </>
  );
};

const TitleModal = ({
  getData = () => {},
  _isOnResponsDemand,
  liste = [],
  agent_change = () => {},
  btnClick = () => {},
  set_update_action,
  id_status,
  show_bouton_valide,
  can_abort_request = true,
  can_refuse_request = true,
  demande,
  idUser,
  handleCloseModal = () => {},
}) => {
  //Permissions
  const can_assign_agent_responsable_to_request = useAccess(60);
  const can_answer_in_progress_request = useAccess(63);
  const can_launch_command_from_valid_request = useAccess(73);

  let entete;

  const lancerCommande = async () => {
    let notif;
    try {
      const { loading, data, errors } = await FetchData(
        `/api/request/run-order`,
        {
          method: "POST",
          body: JSON.stringify({
            req_id: demande?.req_id,
            req_user_id_update: idUser,
            client_id: demande?.req_client_id,
          }),
        }
      );
      if (data?.status === 200) {
        notif = {
          title: "Lancer",
          text: "Commande lancer",
          icon: "success",
        };
      } else {
        const { erreur } = data;
        notif = {
          title: "Erreur!",
          text: data.error,
          icon: "error",
        };
      }
      getData();
      handleCloseModal();
      Swal.fire({
        title: notif.title,
        text: notif.text,
        icon: notif.icon,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
      });
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
    }
  };

  switch (id_status) {
    case 7:
      entete = (
        <span className="w-50 d-flex justify-content-end">
          {can_assign_agent_responsable_to_request && (
            <SelectBoxSearch
              type="select"
              blockClass="mt-3 min-width-60"
              id="agent"
              labelClass="d-none"
              selectClass="input-label-size border border-primary rounded"
              isRequired={true}
              selectOptions={liste}
              onChange={agent_change}
              key="id"
              value="username"
              placeholder="Assigné un responsable"
            />
          )}
        </span>
      );
      break;
    case 1:
      entete = (
        <span className="w-50 d-flex justify-content-end align-items-center">
          {!_isOnResponsDemand && can_answer_in_progress_request && (
            <button
              type="button"
              className="btn btn-primary p-2"
              onClick={() => btnClick(true)}
            >
              <i className="tt-mingcute-add-line fa fa-file-circle-plus me-1"></i>
              Répondre à la demande
            </button>
          )}
        </span>
      );
      break;
    case 2:
      entete = (
        <span className="w-50 d-flex justify-content-end align-items-center">
          {!_isOnResponsDemand && (
            <div className="d-flex gap-2">
              <FormButton
                buttonValue="Demande annulée"
                buttonClass="btn btn-light input-label-size bg-light text-secondary"
                hasIcon={true}
                iconClass="fas fa-arrow-rotate-left me-1"
                disable={!can_abort_request}
                closeModal={false}
                buttonOnClick={() => set_update_action("annuler")}
              />
              <FormButton
                buttonValue="Demande refusée"
                buttonClass="btn btn-light input-label-size bg-light text-secondary"
                hasIcon={true}
                iconClass="fas fa-ban me-1"
                closeModal={false}
                disable={!can_refuse_request}
                buttonOnClick={() => set_update_action("refuser")}
              />
              <FormButton
                buttonValue="Demande validée"
                buttonClass="btn  btn-primary input-label-size"
                hasIcon={true}
                iconClass="fas fa-circle-check me-1"
                closeModal={false}
                disable={!show_bouton_valide}
                buttonOnClick={() => set_update_action("valider")}
              />
            </div>
          )}
        </span>
      );
      break;
    case 3:
      entete = (
        <span className="w-50 d-flex justify-content-end align-items-center">
          {demande?.req_is_command === 1 ? (
            <span
              className={`badge input-label-size text-success
              } bg-light fw-light ms-2`}
            >
              <i className="fas fa-check-double text-success me-1"></i>
              Commande :<b className="fs-6"> {demande?.order_num_title}</b>
            </span>
          ) : (
            !_isOnResponsDemand &&
            can_launch_command_from_valid_request && (
              <FormButton
                buttonValue="Lancer la commande"
                buttonClass="btn btn-primary input-label-size"
                hasIcon={true}
                iconClass="fas fa-check-double me-1"
                closeModal={false}
                disable={demande?.action?.value !== 100}
                buttonOnClick={() => {
                  Swal.fire({
                    customClass: {
                      confirmButton: "btn btn-success ms-2",
                      cancelButton: "btn btn-light",
                    },
                    buttonsStyling: false,
                    title: "",
                    html: `<p class="fs-6 text-start"><strong class="text-start">Lancer la commande</strong><br> <br>
                    Êtes-vous sûr de vouloir lancer la commande ?</p>`,
                    showCloseButton: true,
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: "Lancer la commande",
                    cancelButtonText: "Annuler",
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      lancerCommande();
                    }
                  });
                }}
              />
            )
          )}
        </span>
      );
      break;
    case 4:
      entete = <span className="w-50 d-flex justify-content-end"></span>;
      break;
    case 5:
      entete = <span className="w-50 d-flex justify-content-end"></span>;
      break;
    default:
      entete = <span className="w-50 d-flex justify-content-end"></span>;
  }
  return <>{entete}</>;
};

export default HeaderModal;
