import React from "react";
import "./../../styles/Tables/tableButtonStatus.css";
/**
 * Button component with color alert in Bootstrap
 *
 * @param {{children: component, color: string, disabled: boolean, onClick: function}} props
 * @returns component
 * @author Johns David
 */
function TableButtonStatus({
  children,
  color = "primary",
  disabled = false,
  onClick,
}) {
  const color_value =
    [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ].indexOf(color) != -1
      ? color
      : "primary";
  return (
    <>
      <button
        onClick={onClick}
        type="button"
        className={"alert tbs-alert alert-" + color_value}
        disabled={disabled}
      >
        {children}
      </button>
    </>
  );
}

export default TableButtonStatus;
