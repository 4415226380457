import React from "react";

const FormSection = ({ children, formSectionData = {} }) => {
  return (
    <>
      <div
        className={
          "container-fluid mb-2 ps-4 pt-3 pb-3 " +
            formSectionData?.sectionClass ?? ""
        }
      >
        <p className="section-label-size fw-bold mt-2 mb-3">
          {formSectionData?.title ?? ""}
        </p>
        {children}
      </div>
    </>
  );
};

export default FormSection;
