import Cookies from "js-cookie";
export async function FetchData(url, options = {}, file = false) {
  const token = Cookies.get("token");
  let loading = true;
  let data = null;
  let errors = null;
  let headers = {};
  if (!file) headers["Content-Type"] = "application/json";

  await fetch(url, {
    ...options,
    headers: {
      Accept: "application/json;charset=UTF-8",
      // "Content-Type": "application/json",
      ...headers,
      Authorization: `Bearer ${token}`,
      ...options.headers,
    },
  })
    .then((r) => r.json())
    .then((data_) => {
      data = data_;
    })
    .catch((e) => {
      errors = e.toString();
    })
    .finally(() => {
      loading = false;
    });

  if (data?.status === 403 && data?.error == "Invalid token") {
    Cookies.remove("userData");
    Cookies.remove("token");
    localStorage.clear();
    window.location.href = "/login";
    //Mettre à jour le token pour que data ne soit pas utilisé par le composant appelant si la réponse est une erreur
    token = Cookies.get("token");
  }

  return {
    loading,
    data,
    errors,
  };
}
