import React, { useEffect, useState } from "react";
import Widget from "../stats/Widget";
import { FetchData } from "../../hooks/FetchData";
import "./../../styles/Tables/table.css";
import StatsFilterRound from "../stats/StatsFilterRound";
import BarChartCustom from "../stats/BarChartCustom";

import { useSelector } from "react-redux";

const DashBoard = ({}) => {
  const [stats, setStats] = useState({});
  const [annee, setAnnee] = useState(new Date().getFullYear());
  const [activeChart, setActiveChart] = useState("clients");
  const [stats_data, setStats_data] = useState([]);
  const [filtered, setFiltered] = useState({
    id: 0,
    label: "Tous les clients",
  });
  const [filterTitle, setFilterTitle] = useState("Tous les clients");

  //pour filtrer les profils
  const [filteredRole, setFilteredRole] = useState({
    id: 0,
    label: "Tous les profils",
  });

  const [showActiveChartFilter, setShowActiveChartFilter] = useState(true);

  /**************** START code Date filter *******************/
  const date_range = useSelector((state) => state.dateRange.date_range);
  /**************** END code Date filter *******************/

  const [dataRole, setDataRole] = useState([]);

  useEffect(() => {
    if (activeChart === "clients") {
      setFiltered({ id: 0, label: "Tous les clients" });
      setFilterTitle("Tous les clients");
    } else if (activeChart === "commande_per_user") {
      setFiltered({ id: 0, label: "Tous les utilisateurs" });
      setFilterTitle("Tous les utilisateurs");
    } else {
      setFiltered({ id: 0, label: "Tous les sous-traitants" });
      setFilterTitle("Tous les sous-traitants");
    }
    getAllNeededData(activeChart);
    getStats([], annee, activeChart);
    getTotalsStats([], activeChart);
    changeChartMenu(activeChart);
  }, [annee, activeChart, date_range]);

  const getStats = async (
    idList = [],
    annee = "",
    activeChart = "clients",
    idRole = 0
  ) => {
    let url = "";
    let option = {};
    try {
      if (activeChart === "clients") {
        url = "/api/order/stats";
        option = {
          method: "POST",
          body: JSON.stringify({
            idUser: idList,
            annee: annee,
            ...date_range,
          }),
        };
      } else if (activeChart === "commande_per_user") {
        url = `/api/request/stats/request`;
        option = {
          method: "POST",
          body: JSON.stringify({
            idUser: idList.length > 0 ? idList[0] : 0,
            annee: annee,
            idRole: idRole > 0 ? idRole : 0,
            ...date_range,
          }),
        };
      } else {
        url = "/api/order/stats-order-st/";
        option = {
          method: "POST",
          body: JSON.stringify({
            idUser: idList,
            annee: annee,
            ...date_range,
          }),
        };
      }
      const { data } = await FetchData(url, option);
      if (data.status === 200) {
        setStats(activeChart === "commande_per_user" ? data?.data : data.stats);
      } else {
        console.error("Erreur de back-end : \n", data.error);
      }
    } catch (error) {
      console.error("erreur dans getStats : \n", error);
    }
  };

  const getTotalsStats = async (
    idList = [],
    activeChart = "clients",
    idRole = 0
  ) => {
    try {
      let url = "";
      let options = {};
      if (activeChart === "sous_traitants") {
        url = "/api/order/total-stats-order-st/";
        options = {
          method: "POST",
          body: JSON.stringify({ idsToFilter: idList, ...date_range }),
        };
      } else if (activeChart === "clients") {
        url = "/api/order/total-stats/";
        options = {
          method: "POST",
          body: JSON.stringify({ idsToFilter: idList, ...date_range }),
        };
      } else if (activeChart === "commande_per_user") {
        url = "/api/request/stats/total";
        options = {
          method: "POST",
          body: JSON.stringify({
            idsToFilter: idList,
            idRole: idRole > 0 ? idRole : 0,
            ...date_range,
          }),
        };
      }
      const { data } = await FetchData(url, options);

      if (data?.status === 200) {
        if (activeChart === "sous_traitants") {
          setStats_data([
            {
              text: "Nombre total commandes",
              value: data?.totalStats?.datastats?.total_order_st,
            },
            {
              text: "Chiffre d'affaires total",
              value: data?.totalStats?.datastats?.total_order_st_cost,
            },
          ]);
        } else if (activeChart === "clients") {
          setStats_data([
            {
              text: "Nombre total de commandes",
              value: data?.totalStats?.datastats?.total_orders,
            },
            {
              text: "Chiffre d'affaires total",
              value: data?.totalStats?.datastats?.ca,
            },
            {
              text: "Marge totale",
              value: data?.totalStats?.datastats?.marge,
            },
            {
              text: "Coût total ",
              value: data?.totalStats?.datastats?.total_costs,
              icon_start: "fas fa-square-o",
            },
          ]);
        } else if (activeChart === "commande_per_user") {
          setStats_data([
            {
              text: "Nombre total de demandes traitées",
              value: data?.data?.totalStats?.total_req_traite,
            },
            {
              text: "Chiffre d'affaires total réalisé",
              value: data?.data?.totalStats?.total_req_ca_realise,
            },
            {
              text: "Marge totale réalisée",
              value: data?.data?.totalStats?.total_req_margin,
            },
            {
              text: "Nombre total de demandes validées",
              value: data?.data?.totalStats?.commandes_traites_total,
            },
          ]);
        }
        return data;
      } else {
        console.error(data.error);
        return "error";
      }
    } catch (error) {
      console.error("erreur dans getTotalStats : ", error);
      return "error";
    }
  };

  // const [data, setData] = useState(stats_data[activeChart]);
  const [data_menu, setData_menu] = useState([
    {
      label: "Clients",
      key: "clients",
      selected: true,
      number: null,
    },
    {
      label: "Sous-traitants",
      key: "sous_traitants",
      selected: false,
      number: null,
    },
    {
      label: "Utilisateurs",
      key: "commande_per_user",
      selected: false,
      number: null,
    },
  ]);

  const [chartMenu, setChartMenu] = useState([]);

  const [activeChartFilter, setActiveChartFilter] = useState([]);

  function clickFilter(key) {
    let new_data_menu = data_menu;
    new_data_menu = new_data_menu.map((d) => {
      if (d.key === key) {
        d.selected = true;
      } else {
        d.selected = false;
      }
      return d;
    });
    setData_menu(new_data_menu);
    setActiveChart(key);
    setAnnee(new Date().getFullYear());
  }

  function changeChartMenu(activeChart) {
    if (activeChart === "clients") {
      setChartMenu([
        {
          label: "Nombre de commandes",
          key: "nb_commande",
          selected: true,
          number: null,
        },
        {
          label: "Chiffres d'affaires",
          key: "ca",
          selected: false,
          number: null,
        },
        { label: "Coûts", key: "cout", selected: false, number: null },
      ]);
    } else if (activeChart === "sous_traitants") {
      setChartMenu([
        {
          label: "Nombre de commandes",
          key: "nb_commande",
          selected: true,
          number: null,
        },
        {
          label: "Chiffres d'affaires",
          key: "ca",
          selected: false,
          number: null,
        },
      ]);
    } else if (activeChart === "commande_per_user") {
      setChartMenu([
        {
          label: "Nb demandes traitées / validées",
          key: "nb_demande",
          selected: true,
          number: null,
        },
        {
          label: "CA / Marges réalisés",
          key: "ca_marge",
          selected: false,
          number: null,
        },
      ]);
    }
  }

  function updateTotalAndChart(option) {
    setFiltered(option);
    let idList = [];
    if (option.id > 0) {
      idList.push(option.id);
    }
    getTotalsStats(idList, activeChart);
    getStats(idList, annee, activeChart);
    getAllNeededData(activeChart, option.id);
  }

  useEffect(() => {
    updateTotalAndChart(filtered);
    changeChartMenu(activeChart);
  }, [filtered]);

  useEffect(() => {
    setFilteredRole({ id: 0, label: "Tous les profils" });
  }, [dataRole]);

  useEffect(() => {
    getStats(
      [...(filtered.id > 0 ? [filtered.id] : [])],
      annee,
      activeChart,
      filteredRole.id > 0 ? filteredRole.id : 0
    );
    getTotalsStats(
      [...(filtered.id > 0 ? [filtered.id] : [])],
      activeChart,
      filteredRole?.id > 0 ? filteredRole.id : 0
    );
    changeChartMenu(activeChart);
  }, [filteredRole]);

  const getAllNeededData = async (activeChart, id = 0) => {
    try {
      let url = "";
      let option = {};
      if (activeChart === "clients" || activeChart === "commande_per_user") {
        url = "/api/order/data-new";
        option = {
          method: "POST",
          body: JSON.stringify({
            clientId: id,
          }),
        };
      } else if (activeChart === "sous_traitants") {
        url = "/api/order/data-new";
        option = {
          method: "POST",
          body: JSON.stringify({
            stId: id,
          }),
        };
      }
      const { loading, data, errors } = await FetchData(url, option);
      if (activeChart === "clients") {
        let oldChart = [{ id: 0, label: "Tous les clients" }];
        setShowActiveChartFilter(true);
        setActiveChartFilter([...oldChart, ...data.clients]);
      } else if (activeChart === "sous_traitants") {
        let oldChart = [{ id: 0, label: "Tous les sous-traitants" }];
        setShowActiveChartFilter(true);
        setActiveChartFilter([...oldChart, ...data.subcontractors]);
      } else if (activeChart === "commande_per_user") {
        let oldChart = [{ id: 0, label: "Tous les utilisateurs" }];
        setShowActiveChartFilter(true);
        setActiveChartFilter([...oldChart, ...data.user_agent]);
      }

      const initialRole = [{ id: 0, label: "Tous les profils" }];
      setDataRole([
        ...initialRole,
        ...(data?.roles?.length > 0 ? data?.roles : []),
      ]);
      return { loading, data, errors };
    } catch (error) {
      return {};
    }
  };

  return (
    <>
      <div className={"row w-100 ps-0 pe-0 ms-0 me-0"}>
        {stats_data?.map((d, index) => (
          <div
            className={
              `col col-md-6 col-sm-12 pt-1` +
              (index % 2 === 0 ? " ps-0" : " pe-0")
            }
            key={index}
          >
            <Widget data={d} />
          </div>
        ))}
      </div>
      <StatsFilterRound
        data_menu={data_menu}
        clickFilter={clickFilter}
        activeChartFilter={activeChartFilter}
        filtered={filtered}
        onChangeFilter={(e) => setFiltered(e)}
        filterTitle={filterTitle}
        showActiveChartFilter={showActiveChartFilter}
        filteredRole={filteredRole.id}
        onChangeFilterRole={setFilteredRole}
        data_role={dataRole}
      />
      <BarChartCustom
        data_bar={stats}
        setAnnee={setAnnee}
        annee={annee}
        chartMenu={chartMenu}
        currentActiveTab={chartMenu?.filter((menu) => menu?.selected)[0]?.key}
      />
    </>
  );
};

export default DashBoard;
