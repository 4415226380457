import Swal from "sweetalert2";

//return a text with é, à, ',... and remove unused or unformatted string
function formatText(text = "") {
  text = text.replaceAll("=C3=A0", "à");
  text = text.replaceAll("=C3=A9", "é");
  text = text.replaceAll("=E2=80=99", "'");
  text = text.replaceAll("=C2=B0", "°");
  text = text.replaceAll("=?UTF-8?Q?", "");
  text = text.replaceAll("?=", "");
  text = text.replaceAll(" =", " ");
  return text;
}

//return a substring between 2 strings of a main string
function extractSubString(str = "", startString = "", endString = "") {
  const startIndex = str.indexOf(startString);
  if (startIndex === -1) {
    return "";
  }

  const endIndex = str.indexOf(endString, startIndex + startString.length);
  if (endIndex === -1) {
    return "";
  }

  return str.substring(
    startIndex + startString.length,
    endIndex + endString.length
  );
}

function showFile(file_name) {
  const url_doc = "https://www.pdf995.com/samples/pdf.pdf";
  const extention_image = ["jpg", "jpeg", "gif", "png"];
  const extention = file_name.split(".").pop().toLowerCase();
  // eslint-disable-next-line default-case
  switch (true) {
    case extention_image.includes(extention):
      Swal.fire({
        html: `<div class="overflow-hidden" style="width:100%; height:90vh;"><iframe src="${file_name}" width="100%" height="100%"></iframe></div>`,
        showCloseButton: true,
        showConfirmButton: false,
        width: "100%",
        height: "100%",
      });
      break;
    case ["pdf"].includes(extention):
      Swal.fire({
        html: `<div class="overflow-hidden" style="width:100%; height:90vh;"><iframe src="${file_name}" width="100%" height="100%"></iframe></div>`,
        showCloseButton: true,
        showConfirmButton: false,
        width: "100%",
        height: "100%",
      });
      break;
    case ["eml", "email"].includes(extention):
      fetch(file_name.replaceAll(`.${extention}`, ".html"))
        .then((response) => response.text())
        .then((emlContent) => {
          const headers = {};
          let body = "";
          let inBody = false;
          let contentType = "";
          let imageData = "";

          const imageDataStartDelimiter =
            "Content-Disposition: inline; filename=bsl_stockage_dispo.png";
          const imageDataEndDelimiter = "RK5CYII=";
          imageData = extractSubString(
            emlContent,
            imageDataStartDelimiter,
            imageDataEndDelimiter
          );

          const lines = emlContent.split("\n");
          let num_line = 1;
          lines.forEach((line) => {
            if (inBody) {
              if (num_line == 63) {
                contentType += line;
                contentType = contentType?.split(":")[1]?.split("; name")[0];
              }
              if (num_line >= 49 && num_line <= 61) {
                body += line;
              }
            } else if (line.trim() === "") {
              inBody = true;
            } else {
              const parts = line.split(":");
              const key = parts[0].trim();
              const value = parts.slice(1).join(":").trim();

              headers[key] = value;
            }
            num_line += 1;
          });
          const base64Image = `data:${contentType};base64,${imageData}`;

          const date = new Date(headers["Date"]);
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          };

          let formattedContent =
            "<div style='font-family: Arial, sans-serif; text-align: left;'>";
          // formattedContent +=
          //   "<div><b>From:</b> " +
          //   headers["From"] +
          //   " " +
          //   headers["Reply-To"] +
          //   "</div>";
          // formattedContent += "<div><b>To:</b> " + headers["To"] + "</div>";
          // formattedContent +=
          //   "<div><b>Cc:</b> " + (headers["Cc"] || "") + "</div>";
          // formattedContent +=
          //   "<div><b>Objet:</b> " + formatText(headers["Subject"]) + "</div>";
          // formattedContent +=
          //   "<div><b>Date:</b> " +
          //   date.toLocaleString("fr-FR", options) +
          //   "</div>";
          // formattedContent += "<hr/>";
          // formattedContent += "<br/>";
          // formattedContent += "<br/>";
          formattedContent +=
            "<div style='text-align: left;'>" +
            emlContent.replaceAll("undefined", "");
          // if (imageData.length > 0) {
          //   formattedContent += `<img src="${base64Image}" width="240px" height="180px" alt="bsl_stockage_dispo"/>`;
          // }
          formattedContent += "</div>";
          formattedContent += "</div>";
          Swal.fire({
            html: formattedContent,
            showCloseButton: true,
            showConfirmButton: false,
            width: "100%",
            height: "100%",
            scrollbarPadding: 10,
          });
        })
        .catch((error) => {
          console.error("Erreur lors du chargement du fichier EML:", error);
        });
      break;
    case ["msg", "oft"].includes(extention):
      try {
        const url = "/api/request/read-msg";
        const options = {
          method: "POST",
          body: JSON.stringify({ file: file_name ? file_name : "" }),
        };
        let headers = {};
        headers["Content-Type"] = "application/json";
        fetch(url, {
          ...options,
          headers: {
            Accept: "application/json;charset=UTF-8",
            ...headers,
          },
        })
          .then((r) => r.json())
          .then((data) => {
            Swal.fire({
              html:
                '<div style="text-align:left;">' +
                (JSON.stringify(data?.data) !== "{}" ? data?.data : "") +
                "</div>",
              showCloseButton: true,
              showConfirmButton: false,
              width: "100%",
              height: "100%",
              scrollbarPadding: 10,
            });
          });
      } catch (error) {
        console.error(error.message);
      }
      break;
  }
}

export default showFile;
