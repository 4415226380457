import { combineReducers, createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: [],
  disablesControlButton: false,
  init_data: [],
  search_key: [],
  search_value: "",
  filter_data: [],
  filter_ref: "statut",
  currentPage: 0,
  active_index_filter: 0,
  temp_result_search: [],
  object_serach_key: [],
  heigthTableHeadControl: 0,
  heigthTableHeadFilter: 0,
  heigthTableContent: 0,
};
const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setTableHeadControl: (state, action) => {
      state.heigthTableHeadControl = action.payload;
    },
    setTableHeadFilter: (state, action) => {
      state.heigthTableHeadFilter = action.payload;
    },
    setTableContent: (state, action) => {
      state.heigthTableContent = action.payload;
    },
    selectCheck: (state, action) => {
      const index = action.payload;
      state.data[index].check = !state.data[index].check;
      state.disablesControlButton = state.data.some((item) => item.check);
    },
    initData: (state, action) => {
      const {
        contents,
        search_key,
        search_value,
        filter_data,
        filter_ref,
        active_index_filter,
        object_serach_key,
        data_id,
      } = action.payload;
      Object.assign(state, {
        init_data: contents ? contents : [],
        search_key,
        search_value,
        data: contents ? contents : [],
        temp_result_search: contents ? contents : [],
        filter_data,
        filter_ref,
        active_index_filter,
        data_id,
        object_serach_key: object_serach_key || [],
      });
      state.disablesControlButton = false;
    },
    selectCheckAll: (state, action) => {
      const { isChecked, start, end } = action.payload;
      // state.data.slice(start, end).forEach((item) => {
      //   item.check = isChecked;
      // });
      state.data.forEach((item) => {
        item.check = isChecked;
      });
      state.disablesControlButton =
        isChecked || state.data.some((item) => item.check);
    },

    search: (state, action) => {
      state.search_value = action.payload?.value?.toLowerCase();
      state.data_id = action.payload.dataId;

      // Conserver l'état de sélection avant la recherche
      const previousSelection = state.data.map((item) => ({
        id: item[state.data_id],
        check: item.check,
      }));

      state.data = state.init_data;
      if (state.search_value.length > 0) {
        state.data = state.init_data.filter((v) => {
          let res_search = 0;
          for (let i = 0; i < state.search_key.length; i++) {
            if (state.object_serach_key.indexOf(state.search_key[i]) != -1) {
              const text = v[state.search_key[i]].hasOwnProperty("label")
                ? "label"
                : "value";
              if (
                v[state.search_key[i]][text]
                  ?.toLowerCase()
                  .indexOf(state.search_value) != -1
              ) {
                res_search++;
                break;
              }
            } else {
              if (
                (v[state.search_key[i]] + "")
                  ?.toLowerCase()
                  .indexOf(state.search_value) != -1
              ) {
                res_search++;
                break;
              }
            }
          }
          return res_search > 0;
        });
      }
      if (state.filter_ref) {
        state.temp_result_search = state.data;
        state.data = state.data.filter((v, i) => {
          return state.filter_data[state.active_index_filter].key == "All"
            ? true
            : v[state.filter_ref].key ==
                state.filter_data[state.active_index_filter].key;
        });
      }

      //Rétablir l'état de sélection après la recherche
      previousSelection.forEach((prevItem) => {
        const currentItem = state.init_data.find(
          (item) => item[state.data_id] === prevItem.id
        );
        if (currentItem) {
          currentItem.check = prevItem.check;
        }
      });
      state.disablesControlButton = state.data.some((item) => item.check);
    },
    handle_filter: (state, action) => {
      const checkedItemIds = state.data
        .filter((item) => item.check)
        .map((item) => item.id);

      // Réinitialiser les données filtrées aux données initiales
      state.data = state.init_data.map((item) => ({ ...item }));

      // Filtrer les données en fonction de la valeur du filtre
      if (action.payload.dropdown_value_search?.length > 0) {
        if (action.payload.dropdown_value_search[0] !== "All") {
          state.data = state.data.filter((d) =>
            action.payload.dropdown_value_search.includes(
              d[action.payload.colone_name_filter]
            )
          );
        }
      }

      //Filtrer les données en fonction de la branche si branches sélectionnés
      if (action.payload.branch_value_search?.length > 0) {
        if (action.payload.branch_value_search[0] !== "All") {
          state.data = state.data.filter((d) =>
            action.payload.branch_value_search.includes(
              d[action.payload.branch_colone_name_filter]
            )
          );
        }
      }

      // Mettre à jour les cases cochées dans les données filtrées
      state.data.forEach((item) => {
        if (checkedItemIds.includes(item.id)) {
          item.check = true;
        }
      });
    },
    updateNumberFilter: (state) => {
      state.filter_data = state.filter_data.map((v) => {
        let count = 0;
        if (v.key == "All") {
          count = state.temp_result_search.length;
        } else {
          count = state.temp_result_search.filter(
            (d) => d[state.filter_ref].key == v.key
          ).length;
        }
        return { ...v, number: count };
      });
    },
    updateFilter: (state, action) => {
      state.filter_data = state.filter_data.map((v, i) => {
        return { ...v, selected: i == action.payload ? true : false };
      });
      state.disablesControlButton = false;
    },
    updateIndexFilter: (state, action) => {
      state.active_index_filter = action.payload;
      state.currentPage = 0;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

export const {
  handle_filter,
  selectCheck,
  initData,
  selectCheckAll,
  search,
  updateNumberFilter,
  updateFilter,
  updateIndexFilter,
  setCurrentPage,
  setTableHeadControl,
  setTableHeadFilter,
  setTableContent,
} = tableSlice.actions;
export default tableSlice.reducer;
