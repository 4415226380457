import React from "react";
import ListGroup from "../../../Form/ListGroup";
import FormButton from "../../../Form/FormButton";
import FormSection from "../../../Form/FormSection";
import FormCard from "../../../Form/FormCard";
import ListItems from "../../../Form/ListItems";
import ListItemContents from "../../../Form/ListItemContents";
import { useAccess } from "../../../../hooks/useAccess";

const DisplayCurrentClientDevisCommande = ({
  client_data_branch = [],
  handleIsUpdateOn = () => {},
}) => {
  //Permissions
  const can_update_client = useAccess(11);

  const cc_table_keys = [
    "cc_name",
    "cc_post",
    "cc_email",
    "cc_phone",
    "cc_created_at",
  ];

  const cc_table_headers = {
    cc_name: "Nom prénom",
    cc_post: "Poste",
    cc_email: "Email",
    cc_phone: "Téléphone",
    cc_created_at: "Date d'ajout",
  };

  let devis_commande_contacts = [];
  for (let i = 0; i < client_data_branch.length; i++) {
    devis_commande_contacts.push([]);
    devis_commande_contacts[i] = client_data_branch[i]?.contact?.filter(
      (contact) => contact?.cc_is_devis_commande == true
    );
  }

  let general_contacts = [];
  for (let i = 0; i < client_data_branch.length; i++) {
    general_contacts.push([]);
    general_contacts[i] = client_data_branch[i]?.contact?.filter(
      (contact) => contact?.cc_is_devis_commande != true
    );
  }

  return (
    <div className="d-flex justify-content-center mt-3">
      <div className="accordion h-100 w-100" id="accordionContacts">
        <div className="accordion-item border-0">
          <div id="contactOne" className={"accordion-collapse collapse show "}>
            {/*  =============================== boutons ajouter nouveau contact ============================== */}
            <div className="d-flex me-3 mt-3 mb-3 justify-content-end">
              {can_update_client && (
                <FormButton
                  buttonType="button"
                  buttonValue="Modifier"
                  buttonClass="btn btn-outline-primary input-label-size me-2"
                  hasIcon={true}
                  iconClass="fas fa-pen me-2"
                  closeModal={false}
                  buttonOnClick={() => handleIsUpdateOn()}
                />
              )}
            </div>
            <FormCard title="Branche">
              <div className="accordion w-100" id="accordionBranches">
                <ListGroup>
                  {client_data_branch?.map((b_data, b_index) => (
                    <ListItems key={"branch_" + b_index}>
                      <div
                        className="accordion-item border-0 show"
                        key={b_index}
                      >
                        <div className="accordion-header input-group">
                          <div className="d-flex flex-column col">
                            <ListItemContents
                              label={
                                b_index + 1 == 1
                                  ? "Nom de la branche"
                                  : `Nom de la branche ${b_index + 1}`
                              }
                              value={b_data?.cb_name ?? "-"}
                            />
                          </div>
                          <div className="d-flex flex-column col">
                            <ListItemContents
                              label={"Numéro de la société"}
                              value={
                                b_data?.num_company?.length > 0
                                  ? b_data?.num_company
                                  : "-"
                              }
                            />
                          </div>
                          <button
                            className="accordion-button bg-white col shadow-none p-0 accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse_${b_index + 1}`}
                            aria-expanded="false"
                            aria-controls={`collapse_${b_index + 1}`}
                          ></button>
                        </div>
                        <div
                          id={`collapse_${b_index + 1}`}
                          className="accordion-collapse collapse "
                          data-bs-parent="#accordionBranches"
                        >
                          <div className="accordion-body ps-0 pt-0 d-flex flex-column justify-content-end mt-4 pb-0">
                            {general_contacts[b_index] == false ? null : (
                              <>
                                <FormCard title="Contacts simples">
                                  <ListGroup>
                                    <ListItems>
                                      <table className="table bsl-table-global border mb-0">
                                        <thead>
                                          <tr
                                            className="table-active"
                                            key={"headers"}
                                          >
                                            {cc_table_keys?.map(
                                              (key, index) => (
                                                <th
                                                  key={"index_" + index}
                                                  className={
                                                    " input-text-size fw-bold " +
                                                    (cc_table_headers[key] ===
                                                    ""
                                                      ? "w-delete-70"
                                                      : "")
                                                  }
                                                >
                                                  {cc_table_headers[key]}
                                                </th>
                                              )
                                            )}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {general_contacts[b_index]?.map(
                                            (item, i_c) => {
                                              return (
                                                <tr className=" " key={i_c}>
                                                  {cc_table_keys.map(
                                                    (c_k, i_k) => (
                                                      <td
                                                        key={i_k}
                                                        className=" align-middle text-nowrap "
                                                        style={{
                                                          overflow: "hidden",
                                                          textOverflow:
                                                            "ellipsis",
                                                        }}
                                                      >
                                                        {c_k === "cc_phone"
                                                          ? item[
                                                              "cc_phone_1"
                                                            ] != ""
                                                            ? item["cc_phone_1"]
                                                            : item[
                                                                "cc_phone_2"
                                                              ] != ""
                                                            ? item["cc_phone_2"]
                                                            : "-"
                                                          : item[c_k] != ""
                                                          ? item[c_k]
                                                          : "-"}
                                                      </td>
                                                    )
                                                  )}
                                                </tr>
                                              );
                                            }
                                          ) ?? null}
                                        </tbody>
                                      </table>
                                    </ListItems>
                                  </ListGroup>
                                </FormCard>
                              </>
                            )}
                            {devis_commande_contacts[b_index] ==
                            false ? null : (
                              <>
                                <FormCard title="Contacts devis/commandes">
                                  <ListGroup>
                                    <ListItems>
                                      <table className="table bsl-table-global  border mb-0">
                                        <thead>
                                          <tr
                                            className="table-active "
                                            key={"headers"}
                                          >
                                            {cc_table_keys?.map(
                                              (key, index) => (
                                                <th
                                                  key={"index_" + index}
                                                  className={
                                                    " input-text-size fw-bold " +
                                                    (cc_table_headers[key] ===
                                                    ""
                                                      ? "w-delete-70"
                                                      : "")
                                                  }
                                                >
                                                  {cc_table_headers[key]}
                                                </th>
                                              )
                                            )}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {devis_commande_contacts[
                                            b_index
                                          ]?.map((item, i_c) => {
                                            return (
                                              <tr className=" " key={i_c}>
                                                {cc_table_keys.map(
                                                  (c_k, i_k) => (
                                                    <td
                                                      key={i_k}
                                                      className="align-middle text-nowrap "
                                                      style={{
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                      }}
                                                    >
                                                      {c_k === "cc_phone"
                                                        ? item["cc_phone_1"] !=
                                                          ""
                                                          ? item["cc_phone_1"]
                                                          : item[
                                                              "cc_phone_2"
                                                            ] != ""
                                                          ? item["cc_phone_2"]
                                                          : "-"
                                                        : item[c_k] != ""
                                                        ? item[c_k]
                                                        : "-"}
                                                    </td>
                                                  )
                                                )}
                                              </tr>
                                            );
                                          }) ?? null}
                                        </tbody>
                                      </table>
                                    </ListItems>
                                  </ListGroup>
                                </FormCard>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </ListItems>
                  )) ?? null}
                </ListGroup>
              </div>
            </FormCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayCurrentClientDevisCommande;

// To add between : and item[c_k] if we need to display form buttons
// ) : c_k === "cc_update" ? (
//   <FormButton
//     buttonType="button"
//     buttonValue=""
//     buttonClass="btn text-secondary input-text-size rounded rounded-1"
//     hasIcon={true}
//     iconClass="fas fa-pen "
//     buttonOnClick={() =>
//       handleUpdateContact(i_c)
//     }
//   />
// ) : c_k === "cc_delete" ? (
//   <FormButton
//     buttonType="button"
//     buttonValue=""
//     buttonClass="btn text-secondary input-text-size rounded rounded-1"
//     hasIcon={true}
//     iconClass="fas fa-trash "
//     buttonOnClick={() =>
//       handleDeleteContact(i_c)
//     }
// />
